import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_BASEURL;

export const getDivision = async (Suspend) => {
    const token = Cookies.get('accessToken');
    const isSuspend = Suspend ? `?isSuspend=${Suspend}` : ''
    const response = await axios.get(`${baseUrl}/get/division${isSuspend}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getDepartment = async (DivisionCode, DepartmentCode, Suspend) => {
    const token = Cookies.get('accessToken');
    const isSuspend = Suspend ? `isSuspend=${Suspend}` : ''
    const response = await axios.get(`${baseUrl}/get/department${DivisionCode ? `?DivisionCode=${DivisionCode}&` : '?'}${DepartmentCode ? `DepCode=${DepartmentCode}&` : ''}${isSuspend}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getSection = async (DivisionCode, DepCode, SectionCode, Suspend) => {
    const token = Cookies.get('accessToken');
    const isSuspend = Suspend ? `isSuspend=${Suspend}` : ''
    const response = await axios.get(`${baseUrl}/get/section${DivisionCode ? `?DivisionCode=${DivisionCode}&` : '?'}${DepCode ? `DepCode=${DepCode}&` : ''}${SectionCode ? `SectionCode=${SectionCode}&` : ''}${isSuspend}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getEmployee = async (Suspend) => {
    const token = Cookies.get('accessToken');
    const isSuspend = Suspend ? `?isSuspend=${Suspend}` : ''
    const response = await axios.get(`${baseUrl}/get/employee${isSuspend}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}