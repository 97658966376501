import React, { useState, useEffect, useCallback } from "react";
import Header from "../../../components/Header";
import { Box, Tooltip, Typography } from "@mui/material";
import EditLocation from "./edit";
import DeleteLocation from "./delete";
import { Image, Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import DetailGrid from "../../../components/Global/Grid/DetailGrid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { getLocation } from "../../../Api/Master/GetData";
import {
  AddAuthorityIdx,
  DeleteAuthorityIdx,
  EditAuthorityIdx,
  FacilityAuthority,
  PrintAuthorityIdx
} from "../../../components/Global/Authority";

const Location = () => {

  const myAddAuthIdx = AddAuthorityIdx();
  const myEditAuthIdx = EditAuthorityIdx();
  const myDeleteAuthIdx = DeleteAuthorityIdx();
  const myPrintAuthIdx = PrintAuthorityIdx();
  const myFacilityAuth = FacilityAuthority();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const response = await getLocation(myFacilityAuth);
      setData(response);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setData([]);
      }
    }
  }, [myFacilityAuth]);
  
  useEffect(() => {
    fetchData();
    setIsLoading(false);
  }, [fetchData]);

  const columns = [
    {
      key: 'key',
      title: 'No',
      dataIndex: 'key',
      width: 80,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'Location Code',
      dataIndex: ['Location', 'LocationCode'],
      fixed: 'left',
      sorter: (a, b) => a.Location.LocationCode.localeCompare(b.Location.LocationCode),
    },
    {
      title: 'Location Name',
      dataIndex: ['Location', 'LocationName'],
      sorter: (a, b) => a.Location.LocationName.localeCompare(b.Location.LocationName),
    },
    {
      title: 'Facility Name',
      dataIndex: 'FacilityName',
      sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
      title: 'Building Name',
      dataIndex: 'BuildingName',
      sorter: (a, b) => a.BuildingName.localeCompare(b.BuildingName),
    },
    {
      title: 'Floor Level Name',
      dataIndex: 'FloorLevelName',
      sorter: (a, b) => a.FloorLevelName.localeCompare(b.FloorLevelName),
    },
    {
      title: 'Map Name',
      dataIndex: 'MapName',
      sorter: (a, b) => a.MapName.localeCompare(b.MapName),
    },
    {
      title: 'Icon Name',
      dataIndex: 'IconName',
      sorter: (a, b) => a.IconName.localeCompare(b.IconName),
    },
    {
      title: 'Icon Image',
      dataIndex: 'Icon Image',
      render: (value, record) =>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Image
            style={{ maxWidth: 40, maxHeight: "100%", objectFit: "contain" }}
            src={`${record.Location.url}`}
          />
        </div>
    },
    // {
    //   title: 'Icon Location Y',
    //   dataIndex: ['Location', 'IconLocationY'],
    //   sorter: (a, b) => a.Location.IconLocationY.localeCompare(b.Location.IconLocationY),
    // },
    // {
    //   title: 'Icon Location X',
    //   dataIndex: ['Location', 'IconLocationX'],
    //   sorter: (a, b) => a.Location.IconLocationX.localeCompare(b.Location.IconLocationX),
    // },
    {
      title: 'Active',
      dataIndex: ['Location', 'IsSuspend'],
      width: 120,
      render: (isSuspend) => (
        <Box display="flex" justifyContent="center" >
          {
            isSuspend ? (
              <Tooltip title="Inactive">
                <CancelIcon color="error" />
              </Tooltip>
            ) : (
              <Tooltip title="Active" >
                <CheckCircleIcon color="success" />
              </Tooltip >
            )}
        </Box>
      )
    },
  ];

  if (myEditAuthIdx || myDeleteAuthIdx) {
    columns.push({
      title: 'Action',
      key: 'Action',
      width: 120,
      fixed: 'right',
      render: (_, record) => (
        <Box display="flex" justifyContent="center">
          {myEditAuthIdx && (
            <EditLocation data={record} onEdit={fetchData} />
          )}

          {myDeleteAuthIdx && !record.Location.IsSuspend && (
            <DeleteLocation data={record} onDelete={fetchData} />
          )}
        </Box>
      ),
    })
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some(
      (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      // console.log(selectedRows);
    },
  };

  const columnsExport = columns
    .filter((item) => item.key !== "Action" && item.key !== "key")
    .map((item) => {

      if (item.title === "Active") {
        return {
          title: item.title,
          key: item.dataIndex,
          render: (record) => (record.Location.IsSuspend ? "Inactive" : "Active"),
        };
      }

      return {
        title: item.title,
        key: item.dataIndex,
      };
    });

  const processExportData = (data) =>
    data.map((item) => ({
      ...item,
      Location: {
        ...item.Location,
        IsSuspend: item.Location.IsSuspend ? "Inactive" : "Active",
      }
    }));

  return (
    <StyleContent>
      <StyleGridHeader>
        <Header title="LOCATION" subtitle="All Location Data" />

        <Box display="flex" justifyContent="end" gap="8px">
          {myPrintAuthIdx && (
            <ExportData
              data={processExportData(selectedRows.length > 0 ? selectedRows : data)}
              columns={columnsExport}
              name="Location"
            />
          )}

          {myAddAuthIdx && (
            <ButtonAddNew
              link="form"
            />
          )}
        </Box>

      </StyleGridHeader>

      <SearchData
        filterValue={searchText}
        handleFilterChange={handleSearch}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filteredData}
        rowSelection={data.length === 0 ? null : rowSelection}
        expandable={data.length === 0 ? null : {
          expandedRowRender: (record) => (
            <DetailGrid>
              <Typography variant="h5" fontWeight={600}>Description : </Typography>
              <Typography>{record.Location.Description}</Typography>
            </DetailGrid>
          ),
          rowExpandable: (record) => record.Location.Description !== '',
        }}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: 'max-content' }}
      />

    </StyleContent>
  );
};

export default Location;
