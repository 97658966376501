import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import {
  Box,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import { ButtonUpdate } from "../../global/components/ButtonAction";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { Modal } from "antd";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import SwitchComponent from "../../../components/Global/SwitchSuspend";
import { getEquipmentVehicle, getIcon, getType } from "../../../Api/Master/GetData";
import { updateSensor } from "../../../Api/Master/UpdateData";
import InputModal from "../../../components/Global/InputModal";
import { columnsAssetObject, columnsSensorType, columnsWarningIcon } from "./ColumnsSensor";

const EditSensor = ({ data, onEdit }) => {
  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [isSuspend, setIsSuspend] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [dataSensorType, setDataSensorType] = useState([]);
  const [selectSensorType, setSelectSensorType] = useState("");
  const SensorTypeCode = selectSensorType ? selectSensorType.TypeCode : '';
  const SensorTypeName = selectSensorType ? selectSensorType.TypeName : '';

  const [dataWarningIcon, setDataWarningIcon] = useState([]);
  const [selectWarningIcon, setSelectWarningIcon] = useState("");
  const WarningIconId = selectWarningIcon ? selectWarningIcon.IconId : '';
  const WarningIconName = selectWarningIcon ? selectWarningIcon.IconName : '';

  const [dataAssetObject, setDataAssetObject] = useState([]);
  const [selectAssetObject, setSelectAssetObject] = useState("");
  const AssetObjectCode = selectAssetObject ? selectAssetObject.SensorCode : '';
  const AssetObjectName = selectAssetObject ? selectAssetObject.SensorName : '';

  const handleSwitchChange = (checked) => {
    setIsSuspend(checked);
    formRef.current.resetForm();
  };


  // TYPE
  useEffect(() => {
    const fetchDataType = async () => {
      try {
        const response = await getType("Sensor");
        const filterRes = response.filter((item) => item.IsSuspend !== true);
        setDataSensorType(filterRes);

        const selected = response.filter(item => item.TypeCode === data.SensorTypeCode)
        setSelectSensorType(selected[0]);

      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    if (open) {
      fetchDataType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);


  // ICON
  useEffect(() => {
    const fetchIcon = async () => {
      try {
        const res = await getIcon();
        setDataWarningIcon(res);

        const selected = res.filter(item => item.IconId === data.WarningIconId)
        setSelectWarningIcon(selected[0]);

      } catch (error) {
        console.log(error);
      }
    }

    if (open) {
      fetchIcon();
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);


  // ASSET OBJECT
  useEffect(() => {
    const fetchAssetObject = async () => {
      try {
        const res = await getEquipmentVehicle();
        setDataAssetObject(res);

        const selected = res.filter(item => item.SensorCode === data.ObjectCode)
        setSelectAssetObject(selected[0]);

      } catch (error) {
        console.log(error);
      }
    }

    if (open) {
      fetchAssetObject();
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);



  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("SensorType", SensorTypeName);
      formRef.current.setFieldValue("WarningIcon", WarningIconName);
      formRef.current.setFieldValue("AssetObject", AssetObjectName);
    }
  }, [AssetObjectName, SensorTypeName, WarningIconName])


  const handleOpen = () => {
    setOpen(true);
    setIsSuspend(data.IsSuspend);
  };

  const handleClose = () => {
    formRef.current.resetForm();
    setIsSuspend(data.IsSuspend)
    setOpen(false);
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const modifiedValues = {
        ...values,
        IsSuspend: isSuspend,
        SensorTypeCode: SensorTypeCode,
        WarningIconId: WarningIconId,
        ObjectCode: AssetObjectCode,
      };
      const response = await updateSensor(data.SensorCode, modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      onEdit(data.SensorCode);
      setOpen(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to update.", { variant: "error" });
      }
      console.error("error code", error.response);
      formRef.current.resetForm();
      setOpen(false);
    }
    setLoading(false);
  };

  return (
    <Box>
      <Tooltip title="Edit" fontSize="large">
        <IconButton onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Box>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={data}
          validationSchema={checkoutSchema}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Modal
              title={
                <StyleEditHeader>
                  <Header
                    title="SENSOR"
                    subtitle="Edit Data a Sensor"
                  />
                  <SwitchComponent
                    isSuspend={isSuspend}
                    handleSwitchChange={handleSwitchChange}
                  />
                </StyleEditHeader>
              }
              centered
              open={open}
              closable={false}
              style={{
                maxWidth: "90%",
                minWidth: "80%",
              }}
              bodyStyle={{
                maxHeight: "65vh",
                overflow: "auto",
              }}
              footer={() => (
                <ButtonUpdate
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  loading={loading}
                />
              )}
            >
              <StyleForm>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Sensor Code*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.SensorCode}
                  name="SensorCode"
                  error={!!touched.SensorCode && !!errors.SensorCode}
                  helperText={touched.SensorCode && errors.SensorCode}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  autoFocus
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Sensor Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.SensorName}
                  name="SensorName"
                  error={!!touched.SensorName && !!errors.SensorName}
                  helperText={touched.SensorName && errors.SensorName}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <InputModal
                  title="TYPE"
                  label="Sensor Type"
                  name="SensorType"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.SensorType}
                  error={!!touched.SensorType && !!errors.SensorType}
                  helperText={touched.SensorType && errors.SensorType}
                  dataSource={dataSensorType}
                  columns={columnsSensorType}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectSensorType(values)}
                  // propsOpenModal={(values) => setOpenModalSensorType(values)}
                  LinktoCreate={`/master/type/form`}
                />

                <InputModal
                  title="ICON"
                  label="Warning Icon"
                  name="WarningIcon"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.WarningIcon}
                  error={!!touched.WarningIcon && !!errors.WarningIcon}
                  helperText={touched.WarningIcon && errors.WarningIcon}
                  dataSource={dataWarningIcon}
                  columns={columnsWarningIcon}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectWarningIcon(values)}
                  // propsOpenModal={(values) => setOpenModalWarningIcon(values)}
                  LinktoCreate={`/master/icon/form`}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Lower Limit*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.LLimit}
                  name="LLimit"
                  error={!!touched.LLimit && !!errors.LLimit}
                  helperText={touched.LLimit && errors.LLimit}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Upper Limit*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.ULimit}
                  name="ULimit"
                  error={!!touched.ULimit && !!errors.ULimit}
                  helperText={touched.ULimit && errors.ULimit}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <InputModal
                  title="ASSET"
                  label="Asset Object"
                  name="AssetObject"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.AssetObject}
                  error={!!touched.AssetObject && !!errors.AssetObject}
                  helperText={touched.AssetObject && errors.AssetObject}
                  dataSource={dataAssetObject}
                  columns={columnsAssetObject}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectAssetObject(values)}
                // propsOpenModal={(values) => setOpenModalAssetObject(values)}
                // LinktoCreate={`/master/icon/form`}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 2" }}
                  multiline
                  rows={4}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />
              </StyleForm>
            </Modal>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  SensorName: yup
    .string()
    .max(20, "Maximum of 20 characters allowed")
    .required("Please complete this Sensor Name."),
  SensorType: yup
    .string()
    .required("Please complete this Sensor Type."),
  WarningIcon: yup
    .string()
    .required("Please complete this Icon."),
  LLimit: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this LLimit."),
  ULimit: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this ULimit."),
  AssetObject: yup
    .string()
    .required("Please complete this Object."),
});

export default EditSensor;
