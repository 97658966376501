import React, { useEffect, useState } from 'react'
import Chart from 'react-google-charts';
import { getResponseTime } from '../API';

const ResponseTime = () => {

    const [dataSource, setDataSource] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getResponseTime();
                setDataSource(res[0])
            } catch (error) {
                console.log(error);
            }
        }

        fetchData();

    }, []);


    const data = dataSource ? [
        ["Bulan", "AvgTime"],
        [dataSource.Months, dataSource.AvgTime]
    ] : [
        ["Bulan", "AvgTime"],
        ["No Data", 0],
    ];

    const options = {
        // title: "Response Time per Month",
        legend: {
            position: "top",
            textStyle: {
                color: "#233238",
                fontSize: 12,
            },
        },
        hAxis: {
            title: 'Month',
        },
        vAxis: {
            title: 'Response Time (in Day)',
        },
        colors: ['#4285F4'],
    };

    return (
        <Chart
            chartType="ColumnChart"
            width="100%"
            height="400px"
            data={data}
            options={options}
        />
    );
}

export default ResponseTime;
