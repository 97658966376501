import React, { useEffect, useState, useCallback, useRef } from "react";
import { Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ButtonForm } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { getDepartment, getDivision, getSection } from "../../../Api/Employee/GetData";
import { postSection } from "../../../Api/Employee/PostData";
import InputModal from "../../../components/Global/InputModal";

const FormSection = () => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramsDivCode = searchParams.get('division');
  const paramsDepCode = searchParams.get('department');

  useEffect(() => {
    if (paramsDivCode && paramsDepCode) {
      setOpenModalDivision(true);
      setOpenModalDepartment(true);
    }
  }, [paramsDepCode, paramsDivCode]);


  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [sectionCode, setSectionCode] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const [dataDivision, setDataDivision] = useState([]);
  const [selectDivision, setSelectDivision] = useState("");
  const [openModalDivision, setOpenModalDivision] = useState("");
  const DivisionCode = selectDivision ? selectDivision.DivisionCode : '';
  const DivisionName = selectDivision ? selectDivision.DivisionName : '';

  const [dataDepartment, setDataDepartment] = useState([]);
  const [selectDepartment, setSelectDepartment] = useState("");
  const [openModalDepartment, setOpenModalDepartment] = useState("");
  const DepartmentCode = selectDepartment ? selectDepartment.Department.DepCode : '';
  const DepartmentName = selectDepartment ? selectDepartment.Department.DepName : '';


  // DIVISION
  useEffect(() => {
    const fetchDivision = async () => {
      try {
        const res = await getDivision('false');
        setDataDivision(res);

        if (paramsDivCode) {
          const selected = res.filter((item) => item.DivisionCode === paramsDivCode);
          setSelectDivision(selected[0]);
        }

      } catch (error) {
        console.log(error);
      }
    }

    if (openModalDivision) {
      fetchDivision();
      setIsLoading(false);
      setOpenModalDivision(false);
      setDataDepartment([]);
      setSelectDepartment("");
    }

  }, [openModalDivision, paramsDivCode]);


  // DEPARTMENT
  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const res = await getDepartment(DivisionCode, null, 'false');
        setDataDepartment(res);

        if (paramsDepCode) {
          const selected = res.filter((item) => item.Department.DepCode === paramsDepCode);
          setSelectDepartment(selected[0])
        }

      } catch (error) {
        console.log(error);
      }
    }

    if (DivisionCode && openModalDepartment) {
      fetchDepartment();
      setIsLoading(false);
      setOpenModalDepartment(false);
    }

  }, [DivisionCode, openModalDepartment, paramsDepCode]);


  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("DivisionName", DivisionName);
      formRef.current.setFieldValue("DepartmentName", DepartmentName);
    }
  }, [DepartmentName, DivisionName]);



  const FetchSectionCode = useCallback(async () => {
    try {
      const response = await getSection(DivisionCode, DepartmentCode);
      if (response.length > 0) {
        const SEData = response.filter(
          (item) =>
            item.Section.SectionCode.startsWith("SE")
        );
        if (SEData.length > 0) {
          const lastCode = SEData[SEData.length - 1].Section.SectionCode;
          const nextNumber = parseInt(lastCode.substr(2)) + 1;
          setSectionCode(`SE${nextNumber.toString().padStart(4, "0")}`);
        } else {
          setSectionCode("SE0001");
        }
      } else {
        setSectionCode("SE0001");
      }
    } catch (error) {
      setSectionCode("SE0001");
      console.log(error);
    }
  }, [DivisionCode, DepartmentCode]);

  useEffect(() => {
    if (DivisionCode && DepartmentCode) {
      FetchSectionCode();
    }
  }, [DivisionCode, DepartmentCode, FetchSectionCode]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("SectionCode", sectionCode);
    }
  }, [sectionCode]);


  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const modifiedValues = {
        ...values,
        DivisionCode: DivisionCode,
        DepCode: DepartmentCode,
        SectionCode: sectionCode,
      };

      const response = await postSection(modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/employee/section");

      // console.log(modifiedValues);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response);
    }
    setLoading(false);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Box>
          <StyleContent>
            <Header title="SECTION" subtitle="Create a Section" />
            <StyleForm>
              <InputModal
                title="DIVISION"
                label="Division Name"
                name="DivisionName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.DivisionName}
                error={!!touched.DivisionName && !!errors.DivisionName}
                helperText={touched.DivisionName && errors.DivisionName}
                dataSource={dataDivision}
                columns={columnsDivision}
                loading={isLoading}
                propsSelecteRow={(values) => setSelectDivision(values)}
                propsOpenModal={(values) => setOpenModalDivision(values)}
                LinktoCreate={`/employee/division/form`}
              />

              <InputModal
                title="DEPARTMENT"
                label="Department Name"
                name="DepartmentName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.DepartmentName}
                error={!!touched.DepartmentName && !!errors.DepartmentName}
                helperText={touched.DepartmentName && errors.DepartmentName}
                dataSource={dataDepartment}
                columns={columnsDepartment}
                loading={isLoading}
                propsSelecteRow={(values) => setSelectDepartment(values)}
                propsOpenModal={(values) => setOpenModalDepartment(values)}
                LinktoCreate={`/employee/department/form`}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Section Code*"
                onBlur={handleBlur}
                value={sectionCode}
                onChange={(e) => {
                  handleChange(e);
                  setSectionCode(e.target.value);
                }}
                name="SectionCode"
                error={!!touched.SectionCode && !!errors.SectionCode}
                helperText={touched.SectionCode && errors.SectionCode}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 10 }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Section Name*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.SectionName}
                name="SectionName"
                error={!!touched.SectionName && !!errors.SectionName}
                helperText={touched.SectionName && errors.SectionName}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Description}
                name="Description"
                sx={{ gridColumn: "span 4" }}
                multiline
                rows={4}
              />
            </StyleForm>
          </StyleContent>

          <ButtonForm
            handleSubmit={handleSubmit}
            loading={loading}
          />
        </Box>
      )}
    </Formik>
  );
};

const checkoutSchema = yup.object().shape({
  DivisionName: yup.string().required("Please complete this Division Name."),
  DepartmentName: yup.string().required("Please complete this Division Name."),
  SectionCode: yup
    .string()
    .max(10, "Maximum of 10 characters allowed")
    .required("Please complete this Section Code."),
  SectionName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Section Name."),
});
const initialValues = {
  DivisionName: "",
  DepartmentName: "",
  SectionCode: "",
  SectionName: "",
  Description: "",
};

export default FormSection;


const columnsDivision = [
  {
    key: 'key',
    title: 'No',
    dataIndex: 'key',
    width: 80,
    fixed: 'left',
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: 'Division Code',
    dataIndex: 'DivisionCode',
    sorter: (a, b) => a.DivisionCode.localeCompare(b.DivisionCode),
  },
  {
    title: 'Division Name',
    dataIndex: 'DivisionName',
    sorter: (a, b) => a.DivisionName.localeCompare(b.DivisionName),
  },
]


const columnsDepartment = [
  {
    key: "key",
    title: "No",
    dataIndex: "key",
    width: 80,
    fixed: "left",
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: "Departement Code",
    dataIndex: ["Department", "DepCode"],
    fixed: "left",
    sorter: (a, b) =>
      a.Department.DepCode.localeCompare(b.Department.DepCode),
  },
  {
    title: "Department Name",
    dataIndex: ["Department", "DepName"],
    sorter: (a, b) =>
      a.Department.DepName.localeCompare(b.Department.DepName),
  },
  {
    title: "Division Name",
    dataIndex: "DivisionName",
    sorter: (a, b) => a.DivisionName.localeCompare(b.DivisionName),
  },
];
