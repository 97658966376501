import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import EditUserGroup from "./edit";
import DeleteUserGroup from "./delete";
import { Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import DetailGrid from "../../../components/Global/Grid/DetailGrid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { getUserGroup } from "../../../Api/Configuration/GetData";
import {
  AddAuthorityIdx,
  DeleteAuthorityIdx,
  EditAuthorityIdx,
  PrintAuthorityIdx
} from "../../../components/Global/Authority";

const UserGroup = () => {

  const myAddAuthIdx = AddAuthorityIdx();
  const myEditAuthIdx = EditAuthorityIdx();
  const myDeleteAuthIdx = DeleteAuthorityIdx();
  const myPrintAuthIdx = PrintAuthorityIdx();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const fetchData = async () => {
    try {
      const response = await getUserGroup();
      setData(response);
    } catch (error) {
      //console.log(error);
      if (error.response && error.response.status === 404) {
        setData([]);
      }
    }
  };
  
  useEffect(() => {
    fetchData();
    setIsLoading(false);
  }, []);

  const handleFetchData = () => {
    try {
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const [showGroupTran, setShowGroupTran] = useState(false);
  const [showAddAut, setShowAddAut] = useState(false);
  const [showEditAut, setShowEditAut] = useState(false);
  const [showDeleteAuth, setShowDeleteAuth] = useState(false);
  const [showPrintAuth, setShowPrintAuth] = useState(false);
  const [showProcessAuth, setShowProcessAuth] = useState(false);

  const toggleShow = (key, type) => {
    if (type === 'GroupTran') {
      setShowGroupTran(prevState => ({
        ...prevState,
        [key]: !prevState[key] || false,
      }));
    } else if (type === 'AddAut') {
      setShowAddAut(prevState => ({
        ...prevState,
        [key]: !prevState[key] || false,
      }));
    } else if (type === 'EditAut') {
      setShowEditAut(prevState => ({
        ...prevState,
        [key]: !prevState[key] || false,
      }));
    } else if (type === 'DeleteAuth') {
      setShowDeleteAuth(prevState => ({
        ...prevState,
        [key]: !prevState[key] || false,
      }));
    } else if (type === 'PrintAuth') {
      setShowPrintAuth(prevState => ({
        ...prevState,
        [key]: !prevState[key] || false,
      }));
    } else if (type === 'ProcessAuth') {
      setShowProcessAuth(prevState => ({
        ...prevState,
        [key]: !prevState[key] || false,
      }));
    }
  };


  const renderItems = (text, record, items, type) => {
    const itemNames = text.split(',');
    const showAll = items[record.key];

    const visibleItems = showAll ? itemNames : itemNames.slice(0, 3);

    return (
      <div>
        {visibleItems.map((item, index) => (
          <p key={index}>{`- ${item.trim()}`}</p>
        ))}
        {itemNames.length > 3 && (
          <div>
            {!showAll ? (
              <Link onClick={() => toggleShow(record.key, type)}>show all..</Link>
            ) : (
              <Link onClick={() => toggleShow(record.key, type)}>hide..</Link>
            )}
          </div>
        )}
      </div>
    );
  };

  const GroupTran = (text, record) => renderItems(text, record, showGroupTran, 'GroupTran');
  const AddAut = (text, record) => renderItems(text, record, showAddAut, 'AddAut');
  const EditAut = (text, record) => renderItems(text, record, showEditAut, 'EditAut');
  const DeleteAuth = (text, record) => renderItems(text, record, showDeleteAuth, 'DeleteAuth');
  const PrintAuth = (text, record) => renderItems(text, record, showPrintAuth, 'PrintAuth');
  const ProcessAuth = (text, record) => renderItems(text, record, showProcessAuth, 'ProcessAuth');

  const columns = [
    {
      key: 'key',
      title: 'No',
      dataIndex: 'key',
      width: 80,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'Kode Cabang',
      dataIndex: 'KodeCabang',
      sorter: (a, b) => a.KodeCabang.localeCompare(b.KodeCabang),
    },
    {
      title: 'Group Code',
      dataIndex: 'GroupCode',
      sorter: (a, b) => a.GroupCode.localeCompare(b.GroupCode),
    },
    {
      title: 'Group Name',
      dataIndex: 'GroupName',
      sorter: (a, b) => a.GroupName.localeCompare(b.GroupName),
    },
    {
      title: 'Group Tran',
      dataIndex: 'GroupTranNames',
      sorter: (a, b) => a.GroupTranNames.localeCompare(b.GroupTranNames),
      render: GroupTran,
    },
    {
      title: 'Add Authority',
      dataIndex: 'AddAuthorityNames',
      sorter: (a, b) => a.AddAuthorityNames.localeCompare(b.AddAuthorityNames),
      render: AddAut,
    },
    {
      title: 'Edit Authority',
      dataIndex: 'EditAuthorityNames',
      sorter: (a, b) => a.EditAuthorityNames.localeCompare(b.EditAuthorityNames),
      render: EditAut,
    },
    {
      title: 'Del Authority',
      dataIndex: 'DelAuthorityNames',
      sorter: (a, b) => a.DelAuthorityNames.localeCompare(b.DelAuthorityNames),
      render: DeleteAuth,
    },
    {
      title: 'Prn Authority',
      dataIndex: 'PrnAuthorityNames',
      sorter: (a, b) => a.PrnAuthorityNames.localeCompare(b.PrnAuthorityNames),
      render: PrintAuth,
    },
    {
      title: 'Prs Authority',
      dataIndex: 'PrsAuthorityNames',
      sorter: (a, b) => a.PrsAuthorityNames.localeCompare(b.PrsAuthorityNames),
      render: ProcessAuth,
    },
    {
      key: 'IsSuspend',
      title: 'Active',
      dataIndex: 'IsSuspend',
      width: 120,
      render: (isSuspend) => (
        <Box display="flex" justifyContent="center" >
          {
            isSuspend ? (
              <Tooltip title="Inactive">
                <CancelIcon color="error" />
              </Tooltip>
            ) : (
              <Tooltip title="Active" >
                <CheckCircleIcon color="success" />
              </Tooltip >
            )}
        </Box>
      )
    },
  ];

  if (myEditAuthIdx || myDeleteAuthIdx) {
    columns.push({
      title: 'Action',
      key: 'Action',
      fixed: 'right',
      width: 120,
      render: (_, record) => (
        <Box display="flex" justifyContent="center" >
          {myEditAuthIdx && (
            <EditUserGroup data={record} onEdit={handleFetchData} />
          )}

          {myDeleteAuthIdx && !record.IsSuspend && (
            <DeleteUserGroup data={record} onDelete={handleFetchData} />
          )}
        </Box>
      ),
    })
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some(
      (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      // console.log(selectedRows);
    },
  };

  const columnsExport = columns
    .filter((item) => item.key !== "Action" && item.key !== "key")
    .map((item) => {

      if (item.title === "Active") {
        return {
          title: item.title,
          key: item.dataIndex,
          render: (record) => (record.IsSuspend ? "Inactive" : "Active"),
        };
      }

      return {
        title: item.title,
        key: item.dataIndex,
      };
    });

  const processExportData = (data) =>
    data.map((item) => ({
      ...item,
      IsSuspend: item.IsSuspend ? "Inactive" : "Active",
    }));

  return (
    <StyleContent>
      <StyleGridHeader>
        <Header title="USER GROUP" subtitle="All Users Group Data" />

        <Box display="flex" justifyContent="end" gap="8px">
          {myPrintAuthIdx && (
            <ExportData
              data={processExportData(selectedRows.length > 0 ? selectedRows : data)}
              columns={columnsExport}
              name="User Group"
            />
          )}

          {myAddAuthIdx && (
            <ButtonAddNew
              link="/configuration/usergroup/form"
            />
          )}
        </Box>

      </StyleGridHeader>

      <SearchData
        filterValue={searchText}
        handleFilterChange={handleSearch}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filteredData}
        rowSelection={data.length === 0 ? null : rowSelection}
        expandable={data.length === 0 ? null : {
          expandedRowRender: (record) => (
            <DetailGrid>
              <Typography variant="h5" fontWeight={600}>Description : </Typography>
              <Typography>{record.Description}</Typography>
            </DetailGrid>
          ),
          rowExpandable: (record) => record.Description !== '',
        }}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: 'max-content' }}
      />

    </StyleContent>
  );
};

export default UserGroup;
