import React, { useEffect, useRef, useState } from "react";
import { Box, TextField, InputAdornment, Autocomplete } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { onlyNumber } from "../../global/Components";
import { ButtonForm } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { postEmployee } from "../../../Api/Employee/PostData";
import { getDepartment, getDivision, getEmployee, getSection } from "../../../Api/Employee/GetData";
import InputModal from "../../../components/Global/InputModal";

const FormEmployee = () => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramsTechType = searchParams.get('techtype');

  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [empCode, setEmpCode] = useState("");


  const [dataDivision, setDataDivision] = useState([]);
  const [selectDivision, setSelectDivision] = useState("");
  const [openModalDivision, setOpenModalDivision] = useState("");
  const DivisionCode = selectDivision ? selectDivision.DivisionCode : '';
  const DivisionName = selectDivision ? selectDivision.DivisionName : '';

  const [dataDepartment, setDataDepartment] = useState([]);
  const [selectDepartment, setSelectDepartment] = useState("");
  const [openModalDepartment, setOpenModalDepartment] = useState("");
  const DepartmentCode = selectDepartment ? selectDepartment.Department.DepCode : '';
  const DepartmentName = selectDepartment ? selectDepartment.Department.DepName : '';

  const [dataSection, setDataSection] = useState([]);
  const [selectSection, setSelectSection] = useState("");
  const [openModalSection, setOpenModalSection] = useState("");
  const SectionCode = selectSection ? selectSection.Section.SectionCode : '';
  const SectionName = selectSection ? selectSection.Section.SectionName : '';

  const [selectedTechnician, setSelectedTechnician] = useState("");

  useEffect(() => {
    if (paramsTechType) {
      setSelectedTechnician(true);
    }
  }, [paramsTechType]);

  // DIVISION
  useEffect(() => {
    const fetchDivision = async () => {
      try {
        const res = await getDivision('false');
        setDataDivision(res);
      } catch (error) {
        console.log(error);
      }
    }

    if (openModalDivision) {
      fetchDivision();
      setIsLoading(false);
      setOpenModalDivision(false);
      setDataDepartment([]);
      setSelectDepartment("");
      setDataSection([]);
      setSelectSection("");
    }

  }, [openModalDivision]);


  // DEPARTMENT
  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const res = await getDepartment(DivisionCode, null, 'false');
        setDataDepartment(res);
      } catch (error) {
        console.log(error);
      }
    }

    if (DivisionCode && openModalDepartment) {
      fetchDepartment();
      setIsLoading(false);
      setOpenModalDepartment(false);
      setDataSection([]);
      setSelectSection("");
    }

  }, [DivisionCode, openModalDepartment]);


  // SECTION
  useEffect(() => {
    const fetchSection = async () => {
      try {
        const res = await getSection(DivisionCode, DepartmentCode, null, 'false');
        setDataSection(res);
      } catch (error) {
        console.log(error);
      }
    }

    if (DivisionCode && DepartmentCode && openModalSection) {
      fetchSection();
      setIsLoading(false);
      setOpenModalSection(false);
    }

  }, [DepartmentCode, DivisionCode, openModalSection]);



  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("DivisionName", DivisionName);
      formRef.current.setFieldValue("DepartmentName", DepartmentName);
      formRef.current.setFieldValue("SectionName", SectionName);
    }
  }, [DepartmentName, DivisionName, SectionName]);


  const FetchEmpCode = async () => {
    try {
      const response = await getEmployee();
      if (response.length > 0) {
        const EPData = response.filter(
          (item) =>
            item.Employee.EmpCode && item.Employee.EmpCode.startsWith("EP")
        );
        if (EPData.length > 0) {
          const lastCode = EPData[EPData.length - 1].Employee.EmpCode;
          const nextNumber = parseInt(lastCode.substr(2)) + 1;
          setEmpCode(`EP${nextNumber.toString().padStart(4, "0")}`);
        } else {
          setEmpCode("EP0001");
        }
      } else {
        setEmpCode("EP0001");
      }
    } catch (error) {
      setEmpCode("EP0001");
      console.log(error);
    }
  };

  useEffect(() => {
    FetchEmpCode();
  }, []);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("EmpCode", empCode);
      formRef.current.setFieldValue("TechnicianType", true);
    }
  }, [empCode]);

  const handleTechnicionSelection = (event, value) => {
    if (value) {
      setSelectedTechnician(value.CodeTechnician);
    } else {
      setSelectedTechnician("");
    }
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const modifiedValues = {
        ...values,
        EmpCode: empCode,
        Phone1: "0" + values.Phone1,
        Phone2: "0" + values.Phone2,
        DivisionCode: DivisionCode,
        DepCode: DepartmentCode,
        SectionCode: SectionCode,
        IsTechnician: selectedTechnician,
      };

      const response = await postEmployee(modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/employee/employee");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response);
    }
    setLoading(false);
  };

  const handleKeyPress = (e) => {
    if (!onlyNumber.test(e.key) || (e.target.value === "" && e.key === "0")) {
      e.preventDefault();
    }
  };

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={{ ...initialValues, EmpCode: empCode }}
        validationSchema={checkoutSchema}
        innerRef={formRef}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Box>
            <StyleContent>
              <Header title="EMPLOYEE" subtitle="Create a Employee" />
              <StyleForm>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Employee Code*"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setEmpCode(e.target.value);
                  }}
                  value={empCode}
                  name="EmpCode"
                  inputProps={{ maxLength: 6 }}
                  error={!!touched.EmpCode && !!errors.EmpCode}
                  helperText={touched.EmpCode && errors.EmpCode}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  autoFocus
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Employee Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.EmpName}
                  name="EmpName"
                  error={!!touched.EmpName && !!errors.EmpName}
                  helperText={touched.EmpName && errors.EmpName}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Address*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Address}
                  name="Address"
                  error={!!touched.Address && !!errors.Address}
                  helperText={touched.Address && errors.Address}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Phone Number 1*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Phone1}
                  name="Phone1"
                  error={!!touched.Phone1 && !!errors.Phone1}
                  helperText={touched.Phone1 && errors.Phone1}
                  sx={{ gridColumn: "span 2" }}
                  placeholder="Example: 81234567890"
                  onKeyPress={handleKeyPress}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+62</InputAdornment>
                    ),
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Phone Number 2 (optional)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Phone2}
                  name="Phone2"
                  error={!!touched.Phone2 && !!errors.Phone2}
                  helperText={touched.Phone2 && errors.Phone2}
                  sx={{ gridColumn: "span 2" }}
                  placeholder="Example: 81234567890"
                  onKeyPress={handleKeyPress}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+62</InputAdornment>
                    ),
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="NIK*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.NIK}
                  name="NIK"
                  error={!!touched.NIK && !!errors.NIK}
                  helperText={touched.NIK && errors.NIK}
                  sx={{ gridColumn: "span 2" }}
                  onKeyPress={handleKeyPress}
                />

                <InputModal
                  title="DIVISION"
                  label="Division Name"
                  name="DivisionName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.DivisionName}
                  error={!!touched.DivisionName && !!errors.DivisionName}
                  helperText={touched.DivisionName && errors.DivisionName}
                  dataSource={dataDivision}
                  columns={columnsDivision}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectDivision(values)}
                  propsOpenModal={(values) => setOpenModalDivision(values)}
                  LinktoCreate={`/employee/division/form`}
                />

                <InputModal
                  title="DEPARTMENT"
                  label="Department Name"
                  name="DepartmentName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.DepartmentName}
                  error={!!touched.DepartmentName && !!errors.DepartmentName}
                  helperText={touched.DepartmentName && errors.DepartmentName}
                  dataSource={dataDepartment}
                  columns={columnsDepartment}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectDepartment(values)}
                  propsOpenModal={(values) => setOpenModalDepartment(values)}
                  LinktoCreate={`/employee/department/form?division=${DivisionCode}`}
                />

                <InputModal
                  title="SECTION"
                  label="Section Name"
                  name="SectionName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.SectionName}
                  error={!!touched.SectionName && !!errors.SectionName}
                  helperText={touched.SectionName && errors.SectionName}
                  dataSource={dataSection}
                  columns={columnsSection}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectSection(values)}
                  propsOpenModal={(values) => setOpenModalSection(values)}
                  LinktoCreate={`/employee/section/form?division=${DivisionCode}&department=${DepartmentCode}`}
                />

                <Autocomplete
                  fullWidth
                  disablePortal
                  options={TechnicianType}
                  getOptionLabel={(option) => option.NameTechnician}
                  sx={{ gridColumn: "span 2" }}
                  onChange={(event, value) => {
                    handleTechnicionSelection(event, value);
                    setFieldValue(
                      "TechnicianType",
                      value ? value.NameTechnician : ""
                    );
                  }}
                  value={
                    TechnicianType.find(
                      (option) => option.CodeTechnician === selectedTechnician
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Technician Type*"
                      onBlur={handleBlur}
                      error={
                        !!touched.TechnicianType && !!errors.TechnicianType
                      }
                      helperText={
                        touched.TechnicianType && errors.TechnicianType
                      }
                    />
                  )}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 4" }}
                  multiline
                  rows={4}
                />
              </StyleForm>
            </StyleContent>

            <ButtonForm
              handleSubmit={handleSubmit}
              loading={loading}
            />
          </Box>
        )}
      </Formik>
    </>
  );
};

const checkoutSchema = yup.object().shape({
  EmpCode: yup
    .string()
    .max(10, "Maximum of 10 characters allowed")
    .required("Please complete this Employee Code."),
  EmpName: yup
    .string()
    .max(20, "Maximum of 20 characters allowed")
    .required("Please complete this Employee Name."),
  Address: yup
    .string()
    .max(200, "Maximum of 200 characters allowed")
    .required("Please complete this Address."),
  Phone1: yup
    .string()
    .max(14, "Maximum of 14 characters allowed")
    .required("Please complete this Phone Number 1."),
  Phone2: yup
    .string()
    .max(14, "Maximum of 14 characters allowed")
    // .required("Please complete this Phone Number 2.")
    .test(
      "phone-not-same",
      "Phone Number 1 and Phone Number 2 should not be the same",
      function (value) {
        return value !== this.parent.Phone1;
      }
    ),
  NIK: yup
    .string()
    .max(30, "Maximum of 30 characters allowed")
    .required("Please complete this NIK."),
  DivisionName: yup.string().required("Please complete this Division Name."),
  DepartmentName: yup.string().required("Please complete this Department Name."),
  SectionName: yup.string().required("Please complete this Section Name."),
  TechnicianType: yup
    .string()
    .required("Please complete this Technician Type."),
});
const initialValues = {
  Description: "",
  EmpCode: "",
  EmpName: "",
  Address: "",
  Phone1: "",
  Phone2: "",
  NIK: "",
  DivisionName: "",
  DepartmentName: "",
  SectionName: "",
  TechnicianType: "",
};

const TechnicianType = [
  {
    CodeTechnician: true,
    NameTechnician: "Technician",
  },
  {
    CodeTechnician: false,
    NameTechnician: "Not a Technician",
  },
];

export default FormEmployee;



const columnsDivision = [
  {
    key: 'key',
    title: 'No',
    dataIndex: 'key',
    width: 80,
    fixed: 'left',
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: 'Division Code',
    dataIndex: 'DivisionCode',
    sorter: (a, b) => a.DivisionCode.localeCompare(b.DivisionCode),
  },
  {
    title: 'Division Name',
    dataIndex: 'DivisionName',
    sorter: (a, b) => a.DivisionName.localeCompare(b.DivisionName),
  },
]


const columnsDepartment = [
  {
    key: "key",
    title: "No",
    dataIndex: "key",
    width: 80,
    fixed: "left",
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: "Departement Code",
    dataIndex: ["Department", "DepCode"],
    fixed: "left",
    sorter: (a, b) =>
      a.Department.DepCode.localeCompare(b.Department.DepCode),
  },
  {
    title: "Department Name",
    dataIndex: ["Department", "DepName"],
    sorter: (a, b) =>
      a.Department.DepName.localeCompare(b.Department.DepName),
  },
  {
    title: "Division Name",
    dataIndex: "DivisionName",
    sorter: (a, b) => a.DivisionName.localeCompare(b.DivisionName),
  },
];


const columnsSection = [
  {
    key: "key",
    title: "No",
    dataIndex: "key",
    width: 80,
    fixed: "left",
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: "Section Code",
    dataIndex: ["Section", "SectionCode"],
    sorter: (a, b) =>
      a.Section.SectionCode.localeCompare(b.Section.SectionCode),
  },
  {
    title: "Section Name",
    dataIndex: ["Section", "SectionName"],
    sorter: (a, b) =>
      a.Section.SectionName.localeCompare(b.Section.SectionName),
  },
  {
    title: "Division Name",
    dataIndex: "DivisionName",
    sorter: (a, b) => a.DivisionName.localeCompare(b.DivisionName),
  },
  {
    title: "Department Name",
    dataIndex: "DepartmentName",
    sorter: (a, b) => a.DepartmentName.localeCompare(b.DepartmentName),
  },
]
