import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import { ButtonUpdate } from "../../global/components/ButtonAction";
import TableTran from "../UserGroup/tableTran";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { Modal } from "antd";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import SwitchComponent from "../../../components/Global/SwitchSuspend";
import { getBuilding, getFacility } from "../../../Api/Master/GetData";
import { updateUser } from "../../../Api/Configuration/UpdateData";
import InputModal from "../../../components/Global/InputModal";
import { columnsBuilding, columnsFacility, columnsUserGroup } from "./ColumnsUser";
import { getUserGroup } from "../../../Api/Configuration/GetData";
import InputSelect from "./InputSelect";

const EditUser = ({ data, onEdit }) => {

  const formRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isSuspend, setIsSuspend] = useState(false);

  const [tranData, setTranData] = useState([]);

  const [dataUserGroup, setDataUserGroup] = useState([]);
  const [selectUserGroup, setSelectUserGroup] = useState("");
  const UserGroupCode = selectUserGroup ? selectUserGroup.GroupCode : '';
  const UserGroupName = selectUserGroup ? selectUserGroup.GroupName : '';


  const [dataFacility, setDataFacility] = useState([]);
  const [selectFacility, setSelectFacility] = useState("");
  const FacilityCode = selectFacility ? selectFacility.map((item) => item.FacilityCode).join(", ") : '';
  const FacilityName = selectFacility ? selectFacility.map((item) => item.FacilityName).join(", ") : '';


  const [dataBuilding, setDataBuilding] = useState([]);
  const [selectBuilding, setSelectBuilding] = useState("");
  const BuildingCode = selectBuilding ? selectBuilding.map((item) => item.Building.BuildingCode).join(", ") : '';
  const BuildingName = selectBuilding ? selectBuilding.map((item) => item.Building.BuildingName).join(", ") : '';


  const [isLoading, setIsLoading] = useState(false);


  // USER GROUP
  useEffect(() => {
    const fetchUserGroup = async () => {
      try {
        const res = await getUserGroup();
        setDataUserGroup(res);

        const selected = res.filter((item) => item.GroupCode === data.UserGroup);
        setSelectUserGroup(selected[0]);

      } catch (error) {
        console.log(error);
      }
    }

    if (open) {
      fetchUserGroup();
      setIsLoading(false);
    }

  }, [data.UserGroup, open]);


  // FACILITY
  useEffect(() => {
    const fetchFacility = async () => {
      try {
        const res = await getFacility();
        setDataFacility(res);

        const facCode = data.FacilityAuthority
        const facCodeArray = facCode.split(', ');
        const selected = res.filter(item => facCodeArray.includes(item.FacilityCode));
        setSelectFacility(selected);
        // console.log('Selected Facilities:', selected);

      } catch (error) {
        console.log(error);
      }
    }

    if (open) {
      fetchFacility();
      setIsLoading(false);

      setDataBuilding([]);
      setSelectBuilding([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);


  // BUILDING
  useEffect(() => {
    const fetchBuilding = async () => {
      try {
        const res = await getBuilding();
        const filterBuilding = res.filter((item) =>
          FacilityCode.includes(item.Building.FacilityCode)
        );
        setDataBuilding(filterBuilding);

        const buildCode = data.BuildingAuthority;
        const buildCodeArray = buildCode.split(', ');
        const selected = res.filter(item => buildCodeArray.includes(item.Building.BuildingCode));
        setSelectBuilding(selected);

      } catch (error) {
        console.log(error);
      }
    }

    if (FacilityCode && open) {
      fetchBuilding();
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, FacilityCode]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("UserGroupName", UserGroupName);
      formRef.current.setFieldValue("FacilityAuthority", FacilityName);
      formRef.current.setFieldValue("BuildingAuthority", BuildingName);
    }
  }, [BuildingName, FacilityName, UserGroupName]);


  const handleSwitchChange = (checked) => {
    setIsSuspend(checked);
    formRef.current.resetForm();
  };


  const handleSaveData = (selectedData) => {
    setTranData(selectedData);
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      if (tranData.length === 0) {
        enqueueSnackbar("Tran Authority cannot be empty!", {
          variant: "warning",
        });
        setLoading(false);
        return;
      }

      const TranIdx = tranData.map((item) => item.TranIdx).join(",");

      const TranAdd = tranData
        .filter((item) => item.Add)
        .map((item) => item.TranIdx)
        .join(",");
      const TranEdit = tranData
        .filter((item) => item.Edit)
        .map((item) => item.TranIdx)
        .join(",");
      const TranDelete = tranData
        .filter((item) => item.Delete)
        .map((item) => item.TranIdx)
        .join(",");
      const TranPrint = tranData
        .filter((item) => item.Print)
        .map((item) => item.TranIdx)
        .join(",");
      const TranProcess = tranData
        .filter((item) => item.Process)
        .map((item) => item.TranIdx)
        .join(",");

      const modifiedValues = {
        ...values,
        GroupTran: TranIdx,
        AddAuthority: TranAdd,
        EditAuthority: TranEdit,
        DelAuthority: TranDelete,
        PrnAuthority: TranPrint,
        PrsAuthority: TranProcess,
        FacilityAuthority: FacilityCode,
        BuildingAuthority: BuildingCode,
        UserGroup: UserGroupCode,
        IsSuspend: isSuspend,
      };

      const response = await updateUser(
        data.RefAccountId,
        data.UserName,
        data.Email,
        modifiedValues
      );
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      onEdit(values.RefAccountId, data.UserName, data.Email);
      setOpen(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to update.", { variant: "error" });
      }
      console.error("error code", error.response);
      formRef.current.resetForm();
      setOpen(false);
    }
    setLoading(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setIsSuspend(data.IsSuspend);
  };

  const handleClose = () => {
    formRef.current.resetForm();
    setIsSuspend(data.IsSuspend)
    setOpen(false);
  };

  return (
    <Box>
      <Tooltip title="Edit" fontSize="large">
        <IconButton onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Box>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={data}
          validationSchema={checkoutSchema}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Modal
              title={
                <StyleEditHeader>
                  <Header
                    title="USER"
                    subtitle="Edit Data a User"
                  />
                  <SwitchComponent
                    isSuspend={isSuspend}
                    handleSwitchChange={handleSwitchChange}
                  />
                </StyleEditHeader>
              }
              centered
              open={open}
              closable={false}
              style={{
                maxWidth: "90%",
                minWidth: "80%",
              }}
              bodyStyle={{
                maxHeight: "65vh",
                overflow: "auto",
              }}
              footer={() => (
                <ButtonUpdate
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  loading={loading}
                />
              )}
            >
              <StyleForm>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="RefAccount Id"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.RefAccountId}
                  name="RefAccountId"
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.UserName}
                  name="UserName"
                  error={!!touched.UserName && !!errors.UserName}
                  helperText={touched.UserName && errors.UserName}
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Email}
                  name="Email"
                  error={!!touched.Email && !!errors.Email}
                  helperText={touched.Email && errors.Email}
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  label="User Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  // value={values.UserPwd}
                  name="UserPwd"
                  error={!!touched.UserPwd && !!errors.UserPwd}
                  helperText={touched.UserPwd && errors.UserPwd}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword((prev) => !prev)}
                        >
                          {showPassword ? (
                            <Icon icon="eva:eye-fill" />
                          ) : (
                            <Icon icon="eva:eye-off-fill" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <InputModal
                  title="USER GROUP"
                  label="User Group Name"
                  name="UserGroupName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.UserGroupName}
                  error={!!touched.UserGroupName && !!errors.UserGroupName}
                  helperText={touched.UserGroupName && errors.UserGroupName}
                  dataSource={dataUserGroup}
                  columns={columnsUserGroup}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectUserGroup(values)}
                  // propsOpenModal={(values) => setOpenModalUserGroup(values)}
                  LinktoCreate={`/configuration/usergroup/form`}
                />

                <InputSelect
                  title="FACILITY"
                  label="Facility Authority"
                  name="FacilityAuthority"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.FacilityAuthority}
                  error={!!touched.FacilityAuthority && !!errors.FacilityAuthority}
                  helperText={touched.FacilityAuthority && errors.FacilityAuthority}
                  dataSource={dataFacility}
                  columns={columnsFacility}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectFacility(values)}
                  LinktoCreate={`/master/facility/form`}
                />

                <InputSelect
                  title="FACILITY"
                  label="Building Authority"
                  name="BuildingAuthority"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.BuildingAuthority}
                  error={!!touched.BuildingAuthority && !!errors.BuildingAuthority}
                  helperText={touched.BuildingAuthority && errors.BuildingAuthority}
                  dataSource={dataBuilding}
                  columns={columnsBuilding}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectBuilding(values)}
                  LinktoCreate={`/master/building/form`}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 2" }}
                  multiline
                  rows={4}
                  inputProps={{
                    readOnly: isSuspend,
                  }}
                />

                {open ? (
                  <Box sx={{ gridColumn: "span 4" }}>
                    <TableTran
                      onSaveData={handleSaveData}
                      groupTran={dataUserGroup}
                      selectUserGroup={UserGroupCode}
                    />
                  </Box>
                ) : null}
              </StyleForm>
            </Modal>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  UserName: yup
    .string()
    .max(20, "Maximum of 20 characters allowed")
    .required("Please complete this User Name."),
  Email: yup
    .string()
    .email()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Email."),
  UserPwd: yup
    .string()
    .min(8, "Minimal of 8 characters allowed")
    .max(255, "Maximum of 255 characters allowed")
    .required("Please complete this User Password."),
  UserGroup: yup
    .string()
    .required("Please complete this User Group."),
  FacilityAuthority: yup
    .string()
    .required("Please complete this Facility Authority."),
  BuildingAuthority: yup
    .string()
    .required("Please complete this Building Authority."),
});

export default EditUser;
