import React, { useRef, useState } from "react";
import * as yup from "yup";
import { Box, IconButton, TextField, Tooltip } from "@mui/material";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import { ButtonUpdate } from "../../global/components/ButtonAction";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { Modal } from "antd";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import { onlyNumber } from "../../global/Components";
import SwitchComponent from "../../../components/Global/SwitchSuspend";
import { updateVendor } from "../../../Api/Master/UpdateData";

const EditVendor = ({ data, onEdit }) => {

  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [isSuspend, setIsSuspend] = useState(false);

  const handleSwitchChange = (checked) => {
    setIsSuspend(checked);
    formRef.current.resetForm();
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const modifiedValues = {
        ...values,
        IsSuspend: isSuspend,
      };
      const response = await updateVendor(data.VendorCode, modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      onEdit(data.VendorCode);
      setOpen(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to update.", { variant: "error" });
      }
      console.error("error code", error.response);
      formRef.current.resetForm();
      setOpen(false);
    }
    setLoading(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setIsSuspend(data.IsSuspend);
  };
  
  const handleClose = () => {
    formRef.current.resetForm();
    setIsSuspend(data.IsSuspend);
    setOpen(false);
  };

  const handleKeyPress = (e) => {
    if (!onlyNumber.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Box>
      <Tooltip title="Edit" fontSize="large">
        <IconButton onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Box>

        <Formik
          onSubmit={handleFormSubmit}
          initialValues={data}
          validationSchema={checkoutSchema}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <Modal
              title={
                <StyleEditHeader>
                  <Header
                    title="VENDOR"
                    subtitle="Edit Data a Vehicle"
                  />
                  <SwitchComponent
                    isSuspend={isSuspend}
                    handleSwitchChange={handleSwitchChange}
                  />
                </StyleEditHeader>
              }
              centered
              open={open}
              closable={false}
              style={{
                maxWidth: "90%",
                minWidth: "80%",
              }}
              bodyStyle={{
                maxHeight: "65vh",
                overflow: "auto",
              }}
              footer={() => (
                <ButtonUpdate
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  loading={loading}
                />
              )}
            >
              <StyleForm>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Vendor Code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.VendorCode}
                  name="VendorCode"
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Vendor Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.VendorName}
                  name="VendorName"
                  error={!!touched.VendorName && !!errors.VendorName}
                  helperText={touched.VendorName && errors.VendorName}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Address}
                  name="Address"
                  error={!!touched.Address && !!errors.Address}
                  helperText={touched.Address && errors.Address}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Address2"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Address2}
                  name="Address2"
                  error={!!touched.Address2 && !!errors.Address2}
                  helperText={touched.Address2 && errors.Address2}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Country"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Country}
                  name="Country"
                  error={!!touched.Country && !!errors.Country}
                  helperText={touched.Country && errors.Country}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="City"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.City}
                  name="City"
                  error={!!touched.City && !!errors.City}
                  helperText={touched.City && errors.City}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Postal Code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.PostalCode}
                  name="PostalCode"
                  error={!!touched.PostalCode && !!errors.PostalCode}
                  helperText={touched.PostalCode && errors.PostalCode}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Phone1"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Phone1}
                  name="Phone1"
                  error={!!touched.Phone1 && !!errors.Phone1}
                  helperText={touched.Phone1 && errors.Phone1}
                  sx={{ gridColumn: "span 2" }}
                  onKeyPress={handleKeyPress}
                  placeholder="Example: 081234567890"
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Phone2"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Phone2}
                  name="Phone2"
                  error={!!touched.Phone2 && !!errors.Phone2}
                  helperText={touched.Phone2 && errors.Phone2}
                  sx={{ gridColumn: "span 2" }}
                  onKeyPress={handleKeyPress}
                  placeholder="Example: 081234567890"
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Email}
                  name="Email"
                  error={!!touched.Email && !!errors.Email}
                  helperText={touched.Email && errors.Email}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Fax"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Fax}
                  name="Fax"
                  error={!!touched.Fax && !!errors.Fax}
                  helperText={touched.Fax && errors.Fax}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Contact Person"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.ContactPerson}
                  name="ContactPerson"
                  error={!!touched.ContactPerson && !!errors.ContactPerson}
                  helperText={touched.ContactPerson && errors.ContactPerson}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="TOP Code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.TOPCode}
                  name="TOPCode"
                  error={!!touched.TOPCode && !!errors.TOPCode}
                  helperText={touched.TOPCode && errors.TOPCode}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Act No"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.ActNo}
                  name="ActNo"
                  error={!!touched.ActNo && !!errors.ActNo}
                  helperText={touched.ActNo && errors.ActNo}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Act Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.ActName}
                  name="ActName"
                  error={!!touched.ActName && !!errors.ActName}
                  helperText={touched.ActName && errors.ActName}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Bank Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.BankName}
                  name="BankName"
                  error={!!touched.BankName && !!errors.BankName}
                  helperText={touched.BankName && errors.BankName}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Bank Branch Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.BankBranchName}
                  name="BankBranchName"
                  error={
                    !!touched.BankBranchName && !!errors.BankBranchName
                  }
                  helperText={
                    touched.BankBranchName && errors.BankBranchName
                  }
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="NPWP"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.NPWP}
                  name="NPWP"
                  error={!!touched.NPWP && !!errors.NPWP}
                  helperText={touched.NPWP && errors.NPWP}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 4" }}
                  multiline
                  rows={4}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

              </StyleForm>

            </Modal>
          )}
        </Formik>
      </Box>
    </Box >
  );
};


const checkoutSchema = yup.object().shape({
  VendorName: yup
    .string()
    .max(100, "Maximum of 100 characters allowed")
    .required("Please complete this Vendor Name."),
  Address: yup
    .string()
    .max(500, "Maximum of 500 characters allowed")
    .required("Please complete this Address."),
  Address2: yup
    .string()
    .max(500, "Maximum of 500 characters allowed")
    .required("Please complete this Address2."),
  City: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this City."),
  Country: yup
    .string()
    .max(30, "Maximum of 30 characters allowed")
    .required("Please complete this Country."),
  PostalCode: yup
    .string()
    .max(5, "Maximum of 5 characters allowed")
    .required("Please complete this PostalCode."),
  Phone1: yup
    .string()
    .max(14, "Maximum of 14 characters allowed")
    .required("Please complete this Phone Number 1."),
  Phone2: yup
    .string()
    .max(14, "Maximum of 14 characters allowed")
    .required("Please complete this Phone Number 2.")
    .test(
      "phone-not-same",
      "Phone Number 1 and Phone Number 2 should not be the same",
      function (value) {
        return value !== this.parent.Phone1;
      }
    ),
  Email: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Email."),
  Fax: yup
    .string()
    .max(15, "Maximum of 15 characters allowed")
    .required("Please complete this Fax."),
  ContactPerson: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this ContactPerson."),
  TOPCode: yup
    .string()
    .max(2, "Maximum of 2 characters allowed")
    .required("Please complete this TOPCode."),
  ActNo: yup
    .string()
    .max(30, "Maximum of 30 characters allowed")
    .required("Please complete this ActNo."),
  ActName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this ActName."),
  BankName: yup
    .string()
    .max(30, "Maximum of 30 characters allowed")
    .required("Please complete this BankName."),
  BankBranchName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this BankBranchName."),
  NPWP: yup
    .string()
    .max(30, "Maximum of 30 characters allowed")
    .required("Please complete this NPWP."),
});

export default EditVendor;
