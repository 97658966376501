import React, { useCallback, useEffect, useState } from 'react'
import { Table } from 'antd'
import dayjs from 'dayjs'
import { Link, useParams } from 'react-router-dom'
import StyleContent from '../../../components/Global/StyleContent'
import StyleGridHeader from '../../../components/Global/StyleContent/StyleGrid/StyleGridHeader'
import Header from '../../../components/Header'
import SearchData from '../../global/components/FeatureTable/SearchData'
import { getDetailPreventive } from '../API'

const SummaryPreventive = () => {

    const { faccode, buildcode } = useParams();

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await getDetailPreventive(faccode, buildcode);
            setData(response);
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setData([]);
            }
        }
        setIsLoading(false);
    }, [buildcode, faccode]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);


    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = data.filter((item) =>
        Object.values(item).some(
            (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    const columns = [
        {
            title: 'No',
            dataIndex: 'key',
            width: 100,
            fixed: 'left',
            sorter: (a, b) => a.key - b.key,
        },
        {
            title: 'PM Number',
            dataIndex: 'PMNumber',
            sorter: (a, b) => a.PMNumber.localeCompare(b.PMNumber),
            width: 200,
            fixed: 'left',
            render: (value, record) =>
                <Link to={`/transaction/pmconfiguration/form?action=view&faccode=${faccode}&trannumber=${record.PMNumber}`}>
                    {/* <p style={{ fontWeight: "bold" }}>{dayjs(value).format('YYYY-MM-DD')}</p> */}
                    <p style={{ fontWeight: "bold" }}>{value}</p>
                </Link>
        },
        {
            title: 'Schedule Date',
            dataIndex: 'ScheduleDate',
            sorter: (a, b) => a.ScheduleDate.localeCompare(b.ScheduleDate),
        },
        {
            title: 'Reference Type',
            dataIndex: 'PMType',
            sorter: (a, b) => a.PMType.localeCompare(b.PMType),
        },
        {
            title: 'Reference Name',
            dataIndex: 'PMTypeRefName',
            sorter: (a, b) => a.PMTypeRefName.localeCompare(b.PMTypeRefName),
        },
        {
            title: 'Facility',
            dataIndex: 'FacilityName',
            sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
        },
        {
            title: 'Location',
            dataIndex: 'LocationName',
            sorter: (a, b) => a.LocationName.localeCompare(b.LocationName),
        },
        {
            title: 'Schedule Start',
            dataIndex: 'ScheduleStart',
            sorter: (a, b) => a.ScheduleStart.localeCompare(b.ScheduleStart),
            render: (date) => <p>{dayjs(date).format('YYYY-MM-DD')}</p>
        },
        {
            title: 'Schedule End',
            dataIndex: 'ScheduleEnd',
            sorter: (a, b) => a.ScheduleEnd.localeCompare(b.ScheduleEnd),
            render: (date) => <p>{dayjs(date).format('YYYY-MM-DD')}</p>

        },
    ];

    return (
        <StyleContent>
            <StyleGridHeader>
                <Header title={`PREVENTIVE MAINTENANCE`} subtitle={`All data a Status Preventive`} />
            </StyleGridHeader>

            <SearchData
                filterValue={searchText}
                handleFilterChange={handleSearch}
            />

            <Table
                loading={isLoading}
                columns={columns}
                dataSource={filteredData}
                pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                scroll={{ x: 'max-content' }}
            />
        </StyleContent>
    )
}

export default SummaryPreventive