import { Button, Form, Input, Modal, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import Header from '../Header';
import SearchData from '../../pages/global/components/FeatureTable/SearchData';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

const InputDetailModalSelect = ({ title, label, name, dataSource, loading, columns, onData, onOpenModal, onDetail, onEdit, LinktoCreate }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectRow, setSelectRow] = useState("");

    useEffect(() => {
        if (onEdit) {
            setSelectedRowKeys([onEdit.key]);
        }
    }, [onEdit]);


    const showModal = () => {
        setIsModalOpen(true);
        if (!onEdit) {
            onOpenModal(true);
        }
    };

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = dataSource.filter((item) =>
        Object.values(item).some((val) =>
            val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    const rowSelection = {
        type: "checkbox",
        selectedRowKeys, // Bind selected row keys to rowSelection
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectRow(selectedRows);
        },
    };

    // const handleRowClick = (record, rowIndex) => {
    //     setSelectedRowKeys([record.key]);
    //     onData(record);
    //     setIsModalOpen(false);
    // };

    const handleSubmit = () => {
        // console.log("Data Select", selectRow.map((item) => item.FacilityName).join(", "));        
        onData(selectRow)
        setIsModalOpen(false);
    }

    const handleClose = () => {
        setIsModalOpen(false);
        setSelectedRowKeys([]);
    }


    return (
        <div>
            <Form.Item
                label={!onDetail ? label : ''}
                name={name}
                style={
                    onDetail && { margin: 0, }
                }
                rules={[
                    {
                        required: true,
                        message: `Please input your ${label}!`,
                    },
                ]}
            >
                <Input onClick={showModal} readOnly placeholder={`${label}`} className="w-full" />
            </Form.Item>

            <Modal
                centered
                title={
                    <Box display="flex" justifyContent="space-between">
                        <Header title={title} subtitle="" />
                        <Link to={LinktoCreate} target="_blank" style={{ textDecoration: 'none' }}>
                            <Button icon={<PlusOutlined />} onClick={handleClose} >
                                Add New
                            </Button>
                        </Link>
                    </Box>
                }
                open={isModalOpen}
                closable={false}
                style={{
                    maxWidth: "90%",
                    minWidth: "80%",
                }}
                bodyStyle={{
                    maxHeight: "65vh",
                    overflow: "auto",
                }}
                footer={
                    <>
                        <Button type="primary" onClick={handleSubmit}>Submit</Button>
                        <Button onClick={handleClose}>Close</Button>
                    </>
                }
            >
                <div className="flex justify-end mb-2">
                    <SearchData value={searchText} onChange={handleSearch} />
                </div>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={filteredData}
                    rowSelection={rowSelection}
                    // onRow={(record, rowIndex) => ({
                    //     onClick: () => {
                    //         handleRowClick(record, rowIndex);
                    //     },
                    // })}
                    scroll={
                        columns.length > 5 && { x: 2000 }
                    }
                />
            </Modal>
        </div>
    );
};

export default InputDetailModalSelect;
