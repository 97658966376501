import { Box, Button, Typography } from '@mui/material'
import { Field } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react'

const UploadMap = ({ files, images }) => {

  const [imageUrl, setImageUrl] = useState(!images ? null : images);
  const { enqueueSnackbar } = useSnackbar();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const allowedExtensions = ['png', 'jpg', 'jpeg'];

    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        const reader = new FileReader();

        reader.onloadend = () => {
          const img = new Image();
          img.src = reader.result;

          img.onload = () => {
            // Set desired width and height
            const MAX_WIDTH = 800; // Width yang diinginkan
            const MAX_HEIGHT = 800; // Height yang diinginkan

            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > MAX_WIDTH) {
                height = (height * MAX_WIDTH) / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width = (width * MAX_HEIGHT) / height;
                height = MAX_HEIGHT;
              }
            }

            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);

            // Convert canvas to base64 image
            const resizedImage = canvas.toDataURL('image/jpeg'); // Format output, bisa diganti ke 'image/png'
            setImageUrl(resizedImage);

            // Optional: Konversi kembali ke Blob/File untuk dikirim ke server
            canvas.toBlob((blob) => {
              const resizedFile = new File([blob], file.name, { type: blob.type });
              files(resizedFile);
            }, file.type);
          };
        };

        reader.readAsDataURL(file);
      } else {
        enqueueSnackbar('The uploaded file must be an image with the extension .png, .jpg, or .jpeg', { variant: 'error' });
        event.target.value = null;
        files(null);
      }
    }
  };


  const clearImage = () => {
    if (!images) {
      setImageUrl(null);
    } else {
      setImageUrl(images);
    }
  }

  return (
    <Box
      sx={{
        gridColumn: "span 4",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
      }}
    >
      <Typography variant="h3">Upload Image</Typography>
      <label htmlFor="upload-image">
        <img
          src={!imageUrl ? "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png" : imageUrl}
          alt="Uploaded"
          height="200"
          width="100%"
        />
      </label>
      <div style={{
        display: "flex",
        gap: "10px"
      }}>
        <label htmlFor="upload-image">
          <Button variant="contained" component="span" color="primary">
            Upload Image
          </Button>
          <Field
            id="upload-image"
            hidden
            accept="image/*"
            type="file"
            onChange={handleFileUpload}
            name="image"
          />
        </label>
        {imageUrl &&
          <Button variant="contained" onClick={clearImage} color="error">
            Delete
          </Button>
        }
      </div>
    </Box>
  )
}

export default UploadMap