import React, { useCallback, useEffect, useState, useRef } from "react";
import { Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormWORE from "./WO_RE/form";
import FormWODM from "./WO_Doc/form";
import FormWOCT from "./WO_CT/form";
import { ButtonForm } from "../../global/components/ButtonAction";
import { getWOMainTrasaction, getWORequest } from "../Api/GetData";
import { PostWOTransaction } from "../Api/PostData";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import dayjs from "dayjs";
import FormTranApproval from "../TranApproval/form";
import { getCategory, getFacility, getSubCategory } from "../../../Api/Master/GetData";
import InputModal from "../../../components/Global/InputModal";
import { columnsCategory, columnsFacility, columnsSubCategory, columnsWORequest } from "./ColumnsWOT";
import TranView from "../TranApproval/tranView";
import { FacilityAuthority } from "../../../components/Global/Authority";

const FormWOTransaction = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const action = searchParams.get('action');
    const faccode = searchParams.get('faccode');
    const trannumber = searchParams.get('trannumber');

    const [WOTransaction, setWOTransaction] = useState("");
    const [dataWOTransaction, setDataWOTransaction] = useState("");
    const [isDisable, setIsDisable] = useState(false);
    const [onApproval, setOnApproval] = useState(false);

    useEffect(() => {
        if (faccode && trannumber) {
            const fecthWOTransaction = async () => {
                try {
                    const response = await getWOMainTrasaction(faccode, trannumber);
                    setWOTransaction(response[0].WOTransaction);
                    setDataWOTransaction(response[0]);
                    // console.log(response);
                } catch (error) {
                    console.log(error);
                }
            }

            fecthWOTransaction();
        }
    }, [faccode, trannumber]);

    useEffect(() => {
        if (faccode && trannumber) {
            if (WOTransaction) {
                setIsDisable(true);
                setOnApproval(true);
                setWONumber(WOTransaction.WONumber);
                setOpenModalFacility(true);
            }
        }
    }, [WOTransaction, faccode, trannumber]);

    const myFacilityAuth = FacilityAuthority();

    const formRef = useRef(null);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const [WONumber, setWONumber] = useState("");

    const [dataFacility, setDataFacility] = useState([]);
    const [selectFacility, setSelectFacility] = useState("");
    const [openModalFacility, setOpenModalFacility] = useState("");
    const FacilityCode = selectFacility ? selectFacility.FacilityCode : '';
    const FacilityName = selectFacility ? selectFacility.FacilityName : '';

    const [dataWORequest, setDataWORequest] = useState([]);
    const [selectWORequest, setSelectWORequest] = useState("");
    const [openModalWORequest, setOpenModalWORequest] = useState("");
    const WORNumber = selectWORequest ? selectWORequest.WORequest.WORNumber : '';
    const RequestBy = selectWORequest ? selectWORequest.WORequest.RequestBy : '';
    const EmployeeName = selectWORequest ? selectWORequest.EmployeeName : '';
    const WOType = selectWORequest ? selectWORequest.WORequest.WOType : '';
    const WOTypeRefName = selectWORequest ? selectWORequest.WOTypeRefName : '';
    const LocationCode = selectWORequest ? selectWORequest.WORequest.LocationCode : '';
    const LocationName = selectWORequest.LocationName ? selectWORequest.LocationName : '-';

    const [dataCategory, setDataCategory] = useState([]);
    const [selectCategory, setSelectCategory] = useState("");
    const [openModalCategory, setOpenModalCategory] = useState("");
    const CategoryCode = selectCategory ? selectCategory.CatCode : '';
    const CategoryName = selectCategory ? selectCategory.CatName : '';

    const [dataSubCategory, setDataSubCategory] = useState([]);
    const [selectSubCategory, setSelectSubCategory] = useState("");
    const [openModalSubCategory, setOpenModalSubCategory] = useState("");
    const SubCategoryCode = selectSubCategory ? selectSubCategory.SubCategory.SubCatCode : '';
    const SubCategoryName = selectSubCategory ? selectSubCategory.SubCategory.SubCatName : '';


    const [WODate, setWODate] = useState(dayjs());
    const [DueDateWO, setDueDateWO] = useState(dayjs().add(7, 'day'));

    const [dataRE, setDataRE] = useState([]);
    const [dataDM, setDataDM] = useState([]);
    const [dataCT, setDataCT] = useState([]);


    // FACILITY
    useEffect(() => {
        const fetchFacility = async () => {
            try {
                const response = await getFacility(myFacilityAuth, 'false');
                setDataFacility(response);

                if (faccode && trannumber) {
                    const selected = response.filter((item) => item.FacilityCode === faccode);
                    setSelectFacility(selected[0]);
                }

            } catch (error) {
                console.log(error);
            }
        }
        if (openModalFacility) {
            fetchFacility();
            setOpenModalFacility(false)
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [faccode, openModalFacility, trannumber]);

    // WO REQUEST
    useEffect(() => {
        const fetchWORequest = async () => {
            try {
                const res = await getWORequest(FacilityCode);

                const filterRes = res.filter((item) =>
                    item.WORequest.TranStatus === 2 &&
                    item.WORequest.IsClosed === false
                )
                setDataWORequest(filterRes);

            } catch (error) {
                console.log(error);
            }
        }
        if (openModalWORequest && FacilityCode) {
            fetchWORequest();
            setIsLoading(false);
        }
    }, [FacilityCode, openModalWORequest]);


    // CATEGORY
    useEffect(() => {
        const fetchCategory = async () => {
            try {
                const res = await getCategory("Work");
                setDataCategory(res);
            } catch (error) {
                console.log(error);
            }
        }

        if (openModalCategory) {
            fetchCategory();
            setOpenModalCategory(false);
            setIsLoading(false);
        }
    }, [openModalCategory]);


    // SUBCATEGORY
    useEffect(() => {
        const fetchSubCategory = async () => {
            try {
                const res = await getSubCategory("Work", CategoryCode);
                setDataSubCategory(res);
            } catch (error) {
                console.log(error);
            }
        }

        if (openModalSubCategory && CategoryCode) {
            fetchSubCategory();
            setOpenModalSubCategory(false);
            setIsLoading(false);
        }

    }, [CategoryCode, openModalSubCategory]);




    useEffect(() => {
        if (formRef.current) {
            if (faccode && trannumber) {
                if (WOTransaction && dataWOTransaction) {
                    formRef.current.setFieldValue("FacilityName", dataWOTransaction.FacilityName);
                    formRef.current.setFieldValue("WORNumber", WOTransaction.WORNumber);
                    formRef.current.setFieldValue("RequestBy", WOTransaction.RequestBy);
                    formRef.current.setFieldValue("WOType", WOTransaction.WOType);
                    formRef.current.setFieldValue("LocationName", dataWOTransaction.LocationName);
                    formRef.current.setFieldValue("CategoryName", dataWOTransaction.WorkCategory);
                    formRef.current.setFieldValue("SubCategoryName", dataWOTransaction.WorkSubCategory);
                }
            } else {
                formRef.current.setFieldValue("FacilityName", FacilityName);
                formRef.current.setFieldValue("WORNumber", WORNumber);
                formRef.current.setFieldValue("RequestBy", EmployeeName);
                formRef.current.setFieldValue("WOType", WOTypeRefName);
                formRef.current.setFieldValue("LocationName", LocationName);
                formRef.current.setFieldValue("CategoryName", CategoryName);
                formRef.current.setFieldValue("SubCategoryName", SubCategoryName);
            }
        }
    }, [CategoryName, EmployeeName, FacilityName, LocationName, SubCategoryName, WORNumber, WOTransaction, WOTypeRefName, dataWOTransaction, faccode, trannumber]);

    const FetchWONumber = useCallback(async () => {
        try {
            const response = await getWOMainTrasaction(FacilityCode);
            if (response.length > 0) {
                const bcData = response.filter(item => item.WOTransaction.WONumber && item.WOTransaction.WONumber.startsWith('WON'));
                if (bcData.length > 0) {
                    const lastCode = bcData[bcData.length - 1].WOTransaction.WONumber;
                    const nextNumber = parseInt(lastCode.substr(3)) + 1;
                    setWONumber(`WON${nextNumber.toString().padStart(4, "0")}`);
                } else {
                    setWONumber("WON0001");
                }
            } else {
                setWONumber("WON0001");
            }
        } catch (error) {
            setWONumber("WON0001");
            console.log(error);
        }
    }, [FacilityCode]);

    useEffect(() => {
        if (FacilityCode && (!faccode || !trannumber)) {
            FetchWONumber();
        }
    }, [FacilityCode, FetchWONumber, faccode, trannumber]);

    useEffect(() => {
        if (formRef.current) {
            formRef.current.setFieldValue("WONumber", WONumber);
        }
    }, [WONumber]);

    const handleCancel = () => {
        formRef.current.resetForm();
        setWONumber('');
        setWODate(null);
        setDueDateWO(null);
    };


    const handleFormSubmit = async (values) => {
        try {
            setLoading(true);

            if (dataRE.length === 0 || dataCT.length === 0) {
                enqueueSnackbar("Complete the detail Work Order data form!", { variant: "warning" });
                setLoading(false);
                return;
            }

            const WODateNow = WODate.format('YYYY-MM-DD');
            const WODueDateNow = DueDateWO.format('YYYY-MM-DD');

            const payload = {
                ...values,
                FacilityCode: FacilityCode,
                WONumber: WONumber,
                WODate: WODateNow,
                WORNumber: WORNumber,
                RequestBy: RequestBy,
                WOType: WOType,
                LocationCode: LocationCode,
                WorkCatCode: CategoryCode,
                WorkSubCatCode: SubCategoryCode,
                DueDateWO: WODueDateNow,
                resources: dataRE,
                documents: dataDM,
                contacts: dataCT,
            };

            const response = await PostWOTransaction(payload);
            enqueueSnackbar(response.data.statusMessage, { variant: "success" });
            navigate("/transaction/workorder");

        } catch (error) {
            if (error.response && error.response.data && error.response.data.statusMessage) {
                const errorMessage = error.response.data.statusMessage;
                enqueueSnackbar(errorMessage, { variant: "error" });
            } else {
                enqueueSnackbar("Data failed to save.", { variant: "error" });
            }
            console.error("error code", error.response.data);
        }
        setLoading(false);
    };


    return (
        <>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
                innerRef={formRef}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                }) => (
                    <Box>
                        <StyleContent>

                            <Header
                                title="WORK ORDER"
                                subtitle="Create a Work Order"
                            />

                            <StyleForm>

                                <InputModal
                                    title="FACILITY"
                                    label="Facility Name"
                                    name="FacilityName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.FacilityName}
                                    error={!!touched.FacilityName && !!errors.FacilityName}
                                    helperText={touched.FacilityName && errors.FacilityName}
                                    dataSource={dataFacility}
                                    columns={columnsFacility}
                                    loading={isLoading}
                                    propsSelecteRow={(values) => setSelectFacility(values)}
                                    propsOpenModal={(values) => setOpenModalFacility(values)}
                                    LinktoCreate={`/master/facility/form`}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="WO Number"
                                    onBlur={handleBlur}
                                    value={WONumber}
                                    onChange={(e) => {
                                        handleChange(e)
                                        setWONumber(e.target.value);
                                    }}
                                    name="WONumber"
                                    error={!!touched.WONumber && !!errors.WONumber}
                                    helperText={touched.WONumber && errors.WONumber}
                                    sx={{ gridColumn: "span 2" }}
                                    inputProps={{ maxLength: 10 }}
                                    InputProps={{
                                        readOnly: isDisable,
                                    }}
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="WO Date"
                                        value={WODate}
                                        onBlur={handleBlur}
                                        onChange={(date) => setWODate(date)}
                                        name="WODate"
                                        error={!!touched.WODate && !!errors.WODate}
                                        helperText={touched.WODate && errors.WODate}
                                        sx={{ gridColumn: "span 2" }}
                                        readOnly={isDisable}
                                    />
                                </LocalizationProvider>

                                <InputModal
                                    title="WO REQUEST"
                                    label="WO Request"
                                    name="WORNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.WORNumber}
                                    error={!!touched.WORNumber && !!errors.WORNumber}
                                    helperText={touched.WORNumber && errors.WORNumber}
                                    dataSource={dataWORequest}
                                    columns={columnsWORequest}
                                    loading={isLoading}
                                    propsSelecteRow={(values) => setSelectWORequest(values)}
                                    propsOpenModal={(values) => setOpenModalWORequest(values)}
                                    LinktoCreate={`/transaction/worequest/form?faccode=${FacilityCode}`}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Request By"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.RequestBy}
                                    name="RequestBy"
                                    error={!!touched.RequestBy && !!errors.RequestBy}
                                    helperText={touched.RequestBy && errors.RequestBy}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="WO Type"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.WOType}
                                    name="WOType"
                                    error={!!touched.WOType && !!errors.WOType}
                                    helperText={touched.WOType && errors.WOType}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Location Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.LocationName}
                                    name="LocationName"
                                    error={!!touched.LocationName && !!errors.LocationName}
                                    helperText={touched.LocationName && errors.LocationName}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />

                                <InputModal
                                    title="CATEGORY"
                                    label="Category Name"
                                    name="CategoryName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.CategoryName}
                                    error={!!touched.CategoryName && !!errors.CategoryName}
                                    helperText={touched.CategoryName && errors.CategoryName}
                                    dataSource={dataCategory}
                                    columns={columnsCategory}
                                    loading={isLoading}
                                    propsSelecteRow={(values) => setSelectCategory(values)}
                                    propsOpenModal={(values) => setOpenModalCategory(values)}
                                    LinktoCreate={`/master/category/form?moduleid=Work`}
                                />

                                <InputModal
                                    title="SUBCATEGORY"
                                    label="SubCategory Name"
                                    name="SubCategoryName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.SubCategoryName}
                                    error={!!touched.SubCategoryName && !!errors.SubCategoryName}
                                    helperText={touched.SubCategoryName && errors.SubCategoryName}
                                    dataSource={dataSubCategory}
                                    columns={columnsSubCategory}
                                    loading={isLoading}
                                    propsSelecteRow={(values) => setSelectSubCategory(values)}
                                    propsOpenModal={(values) => setOpenModalSubCategory(values)}
                                    LinktoCreate={`/master/subcategory/form?moduleid=Work`}
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Due Date"
                                        value={DueDateWO}
                                        onBlur={handleBlur}
                                        onChange={(date) => setDueDateWO(date)}
                                        name="DueDateWO"
                                        error={!!touched.DueDateWO && !!errors.DueDateWO}
                                        helperText={touched.DueDateWO && errors.DueDateWO}
                                        sx={{ gridColumn: "span 2" }}
                                        readOnly={isDisable}
                                    />
                                </LocalizationProvider>

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Description"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={WOTransaction ? (WOTransaction.Description || values.Description) : values.Description}
                                    name="Description"
                                    sx={{ gridColumn: "span 4" }}
                                    multiline
                                    rows={4}
                                    inputProps={{
                                        readOnly: isDisable
                                    }}
                                />

                            </StyleForm>

                            <Box marginTop={6}>
                                <Box sx={{
                                    margin: "20px 0",
                                    padding: 2,
                                    borderRadius: 2,
                                    boxShadow: "0px 0px 10px #00000026",
                                }}>
                                    <FormWORE
                                        onSaveData={(data) => setDataRE(data)}
                                        FacilityCode={FacilityCode}
                                        WONumber={WONumber}
                                        onEdit={false}
                                        onApproval={onApproval}
                                    />
                                </Box>

                                <Box sx={{
                                    margin: "20px 0",
                                    padding: 2,
                                    borderRadius: 2,
                                    boxShadow: "0px 0px 10px #00000026",
                                }}>
                                    <FormWODM
                                        onSaveData={(data) => setDataDM(data)}
                                        FacilityCode={FacilityCode}
                                        WONumber={WONumber}
                                        onEdit={false}
                                        onApproval={onApproval}
                                    />
                                </Box>

                                <Box sx={{
                                    margin: "20px 0",
                                    padding: 2,
                                    borderRadius: 2,
                                    boxShadow: "0px 0px 10px #00000026",
                                }}>
                                    <FormWOCT
                                        onSaveData={(data) => setDataCT(data)}
                                        FacilityCode={FacilityCode}
                                        WONumber={WONumber}
                                        onEdit={false}
                                        onApproval={onApproval}
                                    />
                                </Box>
                            </Box>

                        </StyleContent>

                        {
                            (faccode && trannumber) ? (
                                action === 'process' ? (
                                    <FormTranApproval />
                                ) : (
                                    <TranView />
                                )
                            ) : (
                                <ButtonForm
                                    handleCancel={handleCancel}
                                    handleSubmit={handleSubmit}
                                    loading={loading}
                                />
                            )
                        }

                    </Box>
                )}
            </Formik>
        </>
    );
};

const checkoutSchema = yup.object().shape({
    FacilityName: yup
        .string()
        .max(30, "Maximum of 30 characters allowed")
        .required("Please complete this Facility Name."),
    WONumber: yup
        .string()
        .max(30, "Maximum of 30 characters allowed")
        .required("Please complete this WO Number."),
    WORNumber: yup
        .string()
        .max(30, "Maximum of 30 characters allowed")
        .required("Please complete this WO Request Number."),
    RequestBy: yup
        .string()
        .max(30, "Maximum of 30 characters allowed")
        .required("Please complete this WO Request Number."),
    WOType: yup
        .string()
        .max(30, "Maximum of 30 characters allowed")
        .required("Please complete this WO Request Number."),
    LocationName: yup
        .string()
        .max(30, "Maximum of 30 characters allowed")
        .required("Please complete this WO Request Number."),
    CategoryName: yup
        .string()
        .required("Please complete this Work Category Code."),
    SubCategoryName: yup
        .string()
        .required("Please complete this Work SubCategory Code."),
});
const initialValues = {
    FacilityName: '',
    WONumber: '',
    WODate: '',
    WORNumber: '',
    RequestBy: '',
    WOType: '',
    LocationName: '',
    CategoryName: '',
    SubCategoryName: '',
    DueDateWO: '',
    Description: ''
};

export default FormWOTransaction;
