import React, { useEffect, useState } from 'react'
import { Table, Typography } from 'antd';
import { getPMResourceTrasaction } from '../../Api/GetData';

const DataPMRE = ({ record }) => {

  const [dataRE, setDataRE] = useState([]);

  useEffect(() => {
    const fetchDataRE = async () => {
      try {
        const response = await getPMResourceTrasaction();
        const filterData = response.filter((item) =>
          item.FacilityCode === record.FacilityCode &&
          item.PMNumber === record.PMNumber
        )
        setDataRE(filterData);
      } catch (error) {
        console.log(error.response.data);
      }
    }
    fetchDataRE();
  }, [record]);

  const columns = [
    {
      key: 'DetailNo',
      title: 'No',
      dataIndex: 'DetailNo',
      width: 60,
    },
    {
      title: 'Resource',
      dataIndex: 'Resource',
    },
    {
      title: 'Description',
      dataIndex: 'Description',
    },
  ];

  return (
    <div key="document-tab" style={{ margin: "5px 0" }}>
      <Typography.Title level={3} style={{ margin: " 0  0 10px" }}>
        Resource
      </Typography.Title>
      <Table
        bordered
        columns={columns}
        dataSource={dataRE}
        pagination={false}
        scroll={{ x: 'max-content' }}
      />
    </div>
  )
}

export default DataPMRE