import React, { useRef, useState } from "react";
import * as yup from "yup";
import { Box, IconButton, TextField, Tooltip } from "@mui/material";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import { ButtonUpdate } from "../../global/components/ButtonAction";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { Modal } from "antd";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import SwitchComponent from "../../../components/Global/SwitchSuspend";
import { updateSubCategory } from "../../../Api/Master/UpdateData";

const EditSubCategory = ({ data, onEdit }) => {
  const dataEdit = data.SubCategory;

  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [isSuspend, setIsSuspend] = useState(false);

  const handleSwitchChange = (checked) => {
    setIsSuspend(checked);
    formRef.current.resetForm();
  };

  const handleOpen = () => {
    setOpen(true);
    setIsSuspend(dataEdit.IsSuspend);
  };

  const handleClose = () => {
    formRef.current.resetForm();
    setOpen(false);
    setIsSuspend(dataEdit.IsSuspend)
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const modifivalues = {
        ...values,
        IsSuspend: isSuspend,
      };
      const response = await updateSubCategory(
        dataEdit.ModuleID,
        dataEdit.CatCode,
        dataEdit.SubCatCode,
        modifivalues
      );
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      onEdit(dataEdit.ModuleID && dataEdit.CatCode && dataEdit.SubCatCode);
      setOpen(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to update.", { variant: "error" });
      }
      console.error("error code", error.response);
      formRef.current.resetForm();
      setOpen(false);
    }
    setLoading(false);
  };

  return (
    <Box>
      <Tooltip title="Edit" fontSize="large">
        <IconButton onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Box>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={dataEdit}
          validationSchema={checkoutSchema}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <Modal
              title={
                <StyleEditHeader>
                  <Header
                    title="SUBCATEGORY"
                    subtitle="Edit Data a SubCategory"
                  />
                  <SwitchComponent
                    isSuspend={isSuspend}
                    handleSwitchChange={handleSwitchChange}
                  />
                </StyleEditHeader>
              }
              centered
              open={open}
              closable={false}
              style={{
                maxWidth: "90%",
                minWidth: "80%",
              }}
              bodyStyle={{
                maxHeight: "65vh",
                overflow: "auto",
              }}
              footer={() => (
                <ButtonUpdate
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  loading={loading}
                />
              )}
            >
              <StyleForm>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Module ID"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.ModuleID}
                  name="ModuleID"
                  error={!!touched.ModuleID && !!errors.ModuleID}
                  helperText={touched.ModuleID && errors.ModuleID}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Category Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={data.CatName}
                  name="CatCode"
                  error={!!touched.CatCode && !!errors.CatCode}
                  helperText={touched.CatCode && errors.CatCode}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="SubCategory Code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.SubCatCode}
                  name="SubCatCode"
                  error={!!touched.SubCatCode && !!errors.SubCatCode}
                  helperText={touched.SubCatCode && errors.SubCatCode}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="SubCategory Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.SubCatName}
                  name="SubCatName"
                  error={!!touched.SubCatName && !!errors.SubCatName}
                  helperText={touched.SubCatName && errors.SubCatName}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  error={!!touched.Description && !!errors.Description}
                  helperText={touched.Description && errors.Description}
                  sx={{ gridColumn: "span 4" }}
                  multiline
                  rows={4}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />
              </StyleForm>
            </Modal>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  SubCatName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Cat Name."),
});

export default EditSubCategory;
