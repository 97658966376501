import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_BASEURL;


// MASTER DATA

export const getModuleID = async (Type) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/moduleID${Type ? `?Type=${Type}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;

}

export const getFacility = async (FacilityCode, Suspend) => {
    const token = Cookies.get('accessToken');
    const isSuspend = Suspend ? `isSuspend=${Suspend}` : ''
    const response = await axios.get(`${baseUrl}/get/facility${FacilityCode ? `?FacilityCode=${FacilityCode}&` : '?'}${isSuspend}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));

}

export const getBuilding = async (FacilityCode, BuildingCode, Suspend) => {
    const token = Cookies.get('accessToken');
    const isSuspend = Suspend ? `isSuspend=${Suspend}` : ''
    const response = await axios.get(`${baseUrl}/get/building${FacilityCode ? `?FacilityCode=${FacilityCode}&` : '?'}${BuildingCode ? `BuildingCode=${BuildingCode}&` : ''}${isSuspend}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getMap = async (FacilityCode, MapCode, Suspend) => {
    const token = Cookies.get('accessToken');
    const isSuspend = Suspend ? `isSuspend=${Suspend}` : ''
    const response = await axios.get(`${baseUrl}/get/maps${FacilityCode ? `?FacilityCode=${FacilityCode}&` : '?'}${MapCode ? `MapCode=${MapCode}&` : ''}${isSuspend}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getFloor = async (FacilityCode, BuildingCode, FloorLevelCode, Suspend) => {
    const token = Cookies.get('accessToken');
    const isSuspend = Suspend ? `isSuspend=${Suspend}` : ''
    const response = await axios.get(`${baseUrl}/get/floor${FacilityCode ? `?FacilityCode=${FacilityCode}&` : '?'}${BuildingCode ? `BuildingCode=${BuildingCode}&` : ''}${FloorLevelCode ? `FloorLevelCode=${FloorLevelCode}&` : ''}${isSuspend}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getType = async (ModuleID, TypeCode, Suspend) => {
    const token = Cookies.get('accessToken');
    const isSuspend = Suspend ? `isSuspend=${Suspend}` : ''
    const response = await axios.get(`${baseUrl}/get/type${ModuleID ? `?ModuleID=${ModuleID}&` : '?'}${TypeCode ? `TypeCode=${TypeCode}&` : ''}${isSuspend}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getEquipmentModel = async (ModelCode, Suspend) => {
    const token = Cookies.get('accessToken');
    const isSuspend = Suspend ? `isSuspend=${Suspend}` : ''
    const response = await axios.get(`${baseUrl}/get/equipmentmodel${ModelCode ? `?ModelCode=${ModelCode}&` : '?'}${isSuspend}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getEquipment = async (FacilityCode, Suspend) => {
    const token = Cookies.get('accessToken');
    const isSuspend = Suspend ? `isSuspend=${Suspend}` : ''
    const response = await axios.get(`${baseUrl}/get/equipment${FacilityCode ? `?FacilityCode=${FacilityCode}&` : '?'}${isSuspend}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getCategory = async (ModuleID, Suspend) => {
    const token = Cookies.get('accessToken');
    const isSuspend = Suspend ? `isSuspend=${Suspend}` : ''
    const response = await axios.get(`${baseUrl}/get/category${ModuleID ? `?ModuleID=${ModuleID}&` : ''}${isSuspend}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getSubCategory = async (ModuleID, CatCode, SubCatCode, Suspend) => {
    const token = Cookies.get('accessToken');
    const isSuspend = Suspend ? `isSuspend=${Suspend}` : ''
    const response = await axios.get(`${baseUrl}/get/subcategory${ModuleID ? `?ModuleID=${ModuleID}&` : '?'}${CatCode ? `CatCode=${CatCode}&` : ''}${SubCatCode ? `SubCatCode=${SubCatCode}&` : ''}${isSuspend}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getIcon = async (Suspend) => {
    const token = Cookies.get('accessToken');
    const isSuspend = Suspend ? `?isSuspend=${Suspend}` : ''
    const response = await axios.get(`${baseUrl}/get/icon${isSuspend}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getSchedule = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/schedule`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getSensor = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/sensor`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getLocation = async (FacilityCode, Suspend) => {
    const token = Cookies.get('accessToken');
    const isSuspend = Suspend ? `isSuspend=${Suspend}` : ''
    const url = `${baseUrl}/get/location${FacilityCode ? `?FacilityCode=${FacilityCode}&` : '?'}${isSuspend}`;
    const response = await axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}


export const getVehicle = async (FacilityCode, Suspend) => {
    const token = Cookies.get('accessToken');
    const isSuspend = Suspend ? `isSuspend=${Suspend}` : ''
    const response = await axios.get(`${baseUrl}/get/vehicle${FacilityCode ? `?FacilityCode=${FacilityCode}&` : '?'}${isSuspend}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getVendor = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/vendor`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getPart = async (Suspend) => {
    const token = Cookies.get('accessToken');
    const isSuspend = Suspend ? `?isSuspend=${Suspend}` : ''
    const response = await axios.get(`${baseUrl}/get/part${isSuspend}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}


// GET MASTER

export const getEquipmentVehicle = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/equipmentvehicle`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getUnits = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/unit`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}