import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box, Tooltip, Typography } from "@mui/material";
import EditType from "./edit";
import DeleteType from "./delete";
import { Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import DetailGrid from "../../../components/Global/Grid/DetailGrid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { getType } from "../../../Api/Master/GetData";
import {
    AddAuthorityIdx,
    DeleteAuthorityIdx,
    EditAuthorityIdx,
    PrintAuthorityIdx
} from "../../../components/Global/Authority";

const Type = () => {

    const myAddAuthIdx = AddAuthorityIdx();
    const myEditAuthIdx = EditAuthorityIdx();
    const myDeleteAuthIdx = DeleteAuthorityIdx();
    const myPrintAuthIdx = PrintAuthorityIdx();

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState("");

    const fetchData = async () => {
        try {
            const response = await getType();
            setData(response);
        } catch (error) {
            //console.log(error);
            if (error.response && error.response.status === 404) {
                setData([]);
            }
        }
    };
    
    useEffect(() => {
        fetchData();
        setIsLoading(false);
    }, []);

    const columns = [
        {
            key: 'key',
            title: 'No',
            dataIndex: 'key',
            width: 80,
            fixed: 'left',
            sorter: (a, b) => a.key - b.key,
        },
        {
            title: 'Type Code',
            dataIndex: 'TypeCode',
            fixed: 'left',
            sorter: (a, b) => a.TypeCode.localeCompare(b.TypeCode),
        },
        {
            title: 'Type Name',
            dataIndex: 'TypeName',
            sorter: (a, b) => a.TypeName.localeCompare(b.TypeName),
        },
        {
            title: 'Module ID',
            dataIndex: 'ModuleID',
            sorter: (a, b) => a.ModuleID.localeCompare(b.ModuleID),
        },
        {
            title: 'Active',
            key: 'Active',
            dataIndex: 'IsSuspend',
            width: 120,
            render: (isSuspend) => (
                <Box display="flex" justifyContent="center" >
                    {
                        isSuspend ? (
                            <Tooltip title="Inactive">
                                <CancelIcon color="error" />
                            </Tooltip>
                        ) : (
                            <Tooltip title="Active" >
                                <CheckCircleIcon color="success" />
                            </Tooltip >
                        )}
                </Box>
            )
        },
    ]

    if (myEditAuthIdx || myDeleteAuthIdx) {
        columns.push({
            title: 'Action',
            key: 'Action',
            fixed: 'right',
            width: 120,
            render: (_, record) => (
                <Box display="flex" justifyContent="center">
                    {myEditAuthIdx && (
                        <EditType data={record} onEdit={fetchData} />
                    )}
                    {myDeleteAuthIdx && !record.IsSuspend && (
                        <DeleteType data={record} onDelete={fetchData} />
                    )}
                </Box>
            ),
        },)
    }

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = data.filter((item) =>
        Object.values(item).some(
            (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    const [selectedRows, setSelectedRows] = useState([]);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
            // console.log(selectedRows);
        },
    };

    const columnsExport = columns
        .filter((item) => item.key !== "Action" && item.key !== "key")
        .map((item) => {

            if (item.title === "Active") {
                return {
                    title: item.title,
                    key: item.dataIndex,
                    render: (record) => (record.IsSuspend ? "Inactive" : "Active"),
                };
            }

            return {
                title: item.title,
                key: item.dataIndex,
            };
        });

    const processExportData = (data) =>
        data.map((item) => ({
            ...item,
            IsSuspend: item.IsSuspend ? "Inactive" : "Active",
        }));

    return (
        <StyleContent>
            <StyleGridHeader>
                <Header
                    title="TYPE"
                    subtitle="All Type Data"
                />

                <Box display="flex" justifyContent="end" gap="8px">
                    {myPrintAuthIdx && (
                        <ExportData
                            data={processExportData(selectedRows.length > 0 ? selectedRows : data)}
                            columns={columnsExport}
                            name="Type"
                        />
                    )}

                    {myAddAuthIdx && (
                        <ButtonAddNew
                            link="form"
                        />
                    )}
                </Box>
            </StyleGridHeader>

            <SearchData
                filterValue={searchText}
                handleFilterChange={handleSearch}
            />

            <Table
                loading={isLoading}
                columns={columns}
                dataSource={filteredData}
                rowSelection={data.length === 0 ? null : rowSelection}
                expandable={data.length === 0 ? null : {
                    expandedRowRender: (record) => (
                        <DetailGrid>
                            <Typography variant="h5" fontWeight={600}>Description : </Typography>
                            <Typography>{record.Description}</Typography>
                        </DetailGrid>
                    ),
                    rowExpandable: (record) => record.Description !== '',
                }}
                pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                scroll={{ x: 'max-content' }}
            />

        </StyleContent>
    );
};

export default Type;
