import React, { useCallback, useState, useEffect, useRef } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ButtonForm } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { getCategory, getModuleID, getSubCategory } from "../../../Api/Master/GetData";
import { postSubCategory } from "../../../Api/Master/PostData";
import InputModal from "../../../components/Global/InputModal";
import { columnsCategory } from "./ColumnsSubCat";

const FormSubCategory = () => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramsModuleId = searchParams.get('moduleid');
  const paramsCategory = searchParams.get('category');


  useEffect(() => {
    if (paramsModuleId && paramsCategory) {
      setOpenModuleID(true);
      setSelectedModuleID(paramsModuleId);

      setOpenModalCategory(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [moduleID, setModuleID] = useState([]);
  const [selectedModuleID, setSelectedModuleID] = useState("");
  const [OpenModuleID, setOpenModuleID] = useState(false);

  const [dataCategory, setDataCategory] = useState([]);
  const [selectCategory, setSelectCategory] = useState(null);
  const [openModalCategory, setOpenModalCategory] = useState("");
  const CategoryName = selectCategory ? selectCategory.CatName : '';
  const CategoryCode = selectCategory ? selectCategory.CatCode : '';

  const [SubCatCode, setSubCatCode] = useState("");

  useEffect(() => {
    const fetchModuleID = async () => {
      setIsLoading(true);
      try {
        const response = await getModuleID('category');
        setModuleID(response);
      } catch (error) {
        console.log(error);
      }
    };

    if (OpenModuleID) {
      fetchModuleID();
      setOpenModuleID(false);
    }
  }, [OpenModuleID]);


  // CATEGORY
  useEffect(() => {
    const fetchCategory = async () => {
      setIsLoading(true);
      try {
        const response = await getCategory(selectedModuleID, 'false');
        setDataCategory(response);

        if (paramsModuleId && paramsCategory) {
          const seleted = response.filter(item => item.CatCode === paramsCategory);
          setSelectCategory(seleted[0]);
        }

      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };

    if (openModalCategory) {
      fetchCategory();
      setOpenModalCategory(false);
    }
  }, [openModalCategory, paramsCategory, paramsModuleId, selectedModuleID]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("CategoryName", CategoryName);
    }
  }, [CategoryName]);



  const fetchSubCatCode = useCallback(async () => {
    try {
      const response = await getSubCategory(selectedModuleID, CategoryCode);
      if (response.length > 0) {
        const CAData = response.filter((item) =>
          item.SubCategory.SubCatCode.startsWith("SCA")
        );
        if (CAData.length > 0) {
          const lastCode = CAData[CAData.length - 1].SubCategory.SubCatCode;
          const nextNumber = parseInt(lastCode.substr(3)) + 1;
          setSubCatCode(`SCA${nextNumber.toString().padStart(4, "0")}`);
        }
      }
    } catch (error) {
      setSubCatCode("SCA0001");
      console.log(error);
    }
  }, [CategoryCode, selectedModuleID]);

  useEffect(() => {
    if (selectedModuleID && CategoryCode) {
      fetchSubCatCode();
    }
  }, [selectedModuleID, fetchSubCatCode, CategoryCode, SubCatCode]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("ModuleID", selectedModuleID);
      formRef.current.setFieldValue("SubCatCode", SubCatCode);
    }
  }, [SubCatCode, CategoryCode, selectedModuleID]);

  const handleModuleIDSelection = (event, value) => {
    if (value) {
      setSelectedModuleID(value.ModuleID);
    } else {
      setSelectedModuleID("");
    }
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const modifiedValues = {
        ...values,
        ModuleID: selectedModuleID,
        CatCode: CategoryCode,
        SubCatCode: SubCatCode,
      };

      const response = await postSubCategory(modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/master/subcategory");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response);
    }
    setLoading(false);
  };

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
        innerRef={formRef}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Box>
            <StyleContent>
              <Header
                title="FORM SUBCATEGORY"
                subtitle="Create a SubCategory"
              />
              <StyleForm>
                <Autocomplete
                  fullWidth
                  disablePortal
                  onOpen={() => setOpenModuleID(true)}
                  loading={isLoading}
                  options={moduleID}
                  getOptionLabel={(option) => option.ModuleID}
                  sx={{ gridColumn: "span 2" }}
                  onChange={(event, value) => {
                    handleModuleIDSelection(event, value);
                    setFieldValue("ModuleID", value ? value.ModuleID : "");
                  }}
                  value={
                    moduleID.find(
                      (option) => option.ModuleID === selectedModuleID
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Module Id*"
                      onBlur={handleBlur}
                      error={!!touched.ModuleID && !!errors.ModuleID}
                      helperText={touched.ModuleID && errors.ModuleID}
                    />
                  )}
                />

                <InputModal
                  title="CATEGORY"
                  label="Category Name"
                  name="CategoryName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.CategoryName}
                  error={!!touched.CategoryName && !!errors.CategoryName}
                  helperText={touched.CategoryName && errors.CategoryName}
                  dataSource={dataCategory}
                  columns={columnsCategory}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectCategory(values)}
                  propsOpenModal={(values) => setOpenModalCategory(values)}
                  LinktoCreate={`/master/category/form?moduleid=Equipment`}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="SubCategory Code*"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setSubCatCode(e.target.value);
                  }}
                  value={SubCatCode}
                  name="SubCatCode*"
                  error={!!touched.SubCatCode && !!errors.SubCatCode}
                  helperText={touched.SubCatCode && errors.SubCatCode}
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{ maxLength: 7 }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="SubCategory Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.SubCatName}
                  name="SubCatName"
                  error={!!touched.SubCatName && !!errors.SubCatName}
                  helperText={touched.SubCatName && errors.SubCatName}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 4" }}
                  multiline
                  rows={4}
                />
              </StyleForm>
            </StyleContent>

            <ButtonForm
              handleSubmit={handleSubmit}
              loading={loading}
            />
          </Box>
        )}
      </Formik>
    </>
  );
};

const checkoutSchema = yup.object().shape({
  ModuleID: yup.string().required("Please complete this Module ID."),
  CategoryName: yup.string().required("Please complete this Category Name."),
  SubCatCode: yup.string().required("Please complete this SubCategory Code."),
  SubCatName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this SubCategory Name."),
});
const initialValues = {
  ModuleID: "",
  CategoryName: "",
  SubCatCode: "",
  SubCatName: "",
  Description: "",
};

export default FormSubCategory;
