import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_BASEURL;


export const getDashboardSummary = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/dashboardSummary`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    return response.data;
}

export const getWOReqSummary = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/woRequestSummary`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    return response.data.FacilitySummaries;
}

export const getMapDashboard = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/mapdashboard`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    return response.data;
};


// CHARTS
export const getComplineRate = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/compilancerate?startDate=2024-01-01&endDate=2024-12-31`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    return response.data;
}

export const getResponseTime = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/maintenancetime?startDate=2024-01-01&endDate=2024-12-31`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    return response.data;
}

export const getSukuCabang = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/stockstatus?StartDate=2024-01-01&EndDate=2024-12-31`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    return response.data;
}

export const getWorkOrderStatus = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/wostatus?startDate=2024-01-01&endDate=2024-12-31`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    return response.data;
}

export const getTechnicianPerformance = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/kinerjateknisi?startDate=2024-01-01&endDate=2024-12-31`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    return response.data;

}

export const getDetailPreventive = async (FacilityCode, BuildingCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/detailpreventive?FacilityCode=${FacilityCode}&BuildingCode=${BuildingCode}`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));;
}

export const getDetailDemand = async (FacilityCode, BuildingCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/detaildemand?FacilityCode=${FacilityCode}&BuildingCode=${BuildingCode}`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));;
}

export const getOverdue = async (FacilityCode, BuildingCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/detailoverdue?FacilityCode=${FacilityCode}&BuildingCode=${BuildingCode}`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));;
}


export const StatusSummary = [
    {
        FacilityCode: "FA0001",
        FacilityName: "Citra Xperience Kemayoran",
        Building: [
            {
                BuildingCode: "BD0001",
                BuildingName: "Citra Tower",
                Preventive: 0,
                Demand: 0,
                Overdue: 0
            },
            // {
            //     BuildingCode: "BD0002",
            //     BuildingName: "Mall Citra Xperience",
            //     Preventive: 3,
            //     Demand: 2,
            //     Overdue: 0
            // },
            // {
            //     BuildingCode: "BD0003",
            //     BuildingName: "Taman Citra Xperience Kemayoran",
            //     Preventive: 0,
            //     Demand: 2,
            //     Overdue: 3
            // },
        ]
    },
    // {
    //     FacilityCode: "FA0002",
    //     FacilityName: "L'avenue",
    //     Building: [
    //         {
    //             BuildingCode: "BD0001",
    //             BuildingName: "Office Tower L'avenue",
    //             Preventive: 2,
    //             Demand: 0,
    //             Overdue: 3
    //         },
    //         {
    //             BuildingCode: "BD0002",
    //             BuildingName: "Apartment",
    //             Preventive: 2,
    //             Demand: 1,
    //             Overdue: 0
    //         },
    //     ]
    // }
]