import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box, Tooltip, Typography } from "@mui/material";
import DeleteIcons from "./delete";
import EditIcons from "./edit";
import { Image, Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import DetailGrid from "../../../components/Global/Grid/DetailGrid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { getIcon } from "../../../Api/Master/GetData";
import {
  AddAuthorityIdx,
  DeleteAuthorityIdx,
  EditAuthorityIdx,
  PrintAuthorityIdx
} from "../../../components/Global/Authority";

const IconMenu = () => {

  const myAddAuthIdx = AddAuthorityIdx();
  const myEditAuthIdx = EditAuthorityIdx();
  const myDeleteAuthIdx = DeleteAuthorityIdx();
  const myPrintAuthIdx = PrintAuthorityIdx();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const fetchData = async () => {
    try {
      const response = await getIcon();
      setData(response);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setData([]);
      }
    }
  };
  
  useEffect(() => {
    fetchData();
    setIsLoading(false);
  }, []);

  const columns = [
    {
      key: 'key',
      title: 'No',
      dataIndex: 'key',
      width: 80,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'Image',
      dataIndex: 'Image',
      render: (params) =>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Image
            style={{ maxWidth: 50, maxHeight: "100%", objectFit: "contain" }}
            src={`${process.env.REACT_APP_BASEURL_IMG}/${params}`}
          />
        </div>
    },
    {
      title: 'Icon Id',
      dataIndex: 'IconId',
      sorter: (a, b) => a.IconId.localeCompare(b.IconId),
    },
    {
      title: 'Icon Name',
      dataIndex: 'IconName',
      sorter: (a, b) => a.IconName.localeCompare(b.IconName),
    },
    {
      key: 'IsSuspend',
      title: 'Active',
      dataIndex: 'IsSuspend',
      width: 120,
      render: (isSuspend) => (
        <Box display="flex" justifyContent="center" >
          {
            isSuspend ? (
              <Tooltip title="Inactive">
                <CancelIcon color="error" />
              </Tooltip>
            ) : (
              <Tooltip title="Active" >
                <CheckCircleIcon color="success" />
              </Tooltip >
            )}
        </Box>
      )
    },
  ];

  if (myEditAuthIdx || myDeleteAuthIdx) {
    columns.push({
      title: 'Action',
      key: 'Action',
      width: 120,
      fixed: 'right',
      render: (_, record) => (
        <Box display="flex" justifyContent="center">
          {myEditAuthIdx && (
            <EditIcons data={record} onEdit={fetchData} />
          )}

          {myDeleteAuthIdx && !record.IsSuspend && (
            <DeleteIcons data={record} onDelete={fetchData} />
          )}
        </Box>
      ),
    },)
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some(
      (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      // console.log(selectedRows);
    },
  };

  const columnsExport = columns
    .filter((item) => item.key !== "Action" && item.key !== "key")
    .map((item) => {
      if (item.title === "Image") {
        return {
          title: item.title,
          key: item.dataIndex,
          render: (record) =>
            `${process.env.REACT_APP_BASEURL_IMG}/${record.Image}`,
        };
      }

      if (item.title === "Active") {
        return {
          title: item.title,
          key: item.dataIndex,
          render: (record) => (record.IsSuspend ? "Inactive" : "Active"),
        };
      }

      return {
        title: item.title,
        key: item.dataIndex,
      };
    });

  // Proses data sebelum ekspor
  const processExportData = (data) =>
    data.map((item) => ({
      ...item,
      Image: `${process.env.REACT_APP_BASEURL_IMG}/${item.Image}`,
      IsSuspend: item.IsSuspend ? "Inactive" : "Active",
    }));

  return (
    <StyleContent>
      <StyleGridHeader>
        <Header title="ICON" subtitle="All Icon Data" />

        <Box display="flex" justifyContent="end" gap="8px">
          {myPrintAuthIdx && (
            <ExportData
              data={processExportData(selectedRows.length > 0 ? selectedRows : data)}
              columns={columnsExport}
              name="Icon"
            />
          )}

          {myAddAuthIdx && (
            <ButtonAddNew
              link="form"
            />
          )}
        </Box>

      </StyleGridHeader>

      <SearchData
        filterValue={searchText}
        handleFilterChange={handleSearch}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filteredData}
        rowSelection={data.length === 0 ? null : rowSelection}
        expandable={data.every((record) => record.Description === "") ? null : {
          expandedRowRender: (record) => (
            <DetailGrid>
              <Typography variant="h5" fontWeight={600}>Description : </Typography>
              <Typography>{record.Description}</Typography>
            </DetailGrid>
          ),
          rowExpandable: (record) => record.Description !== '',
        }}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: 'max-content' }}
      />

    </StyleContent>
  );
};

export default IconMenu;
