import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material"
import { getIcon, getMap } from "../../../Api/Master/GetData";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";

const MapIcon = ({ propsFacilityCode, propsMapCode, propsIconName, propsIconLocY, propsIconLocX, propsIconUrl, propsDataEdit, setIcon }) => {
    console.log("Icons", setIcon);

    const [MapCode, setMapCode] = useState([]);
    const [availableIcons, setAvailableIcons] = useState([]);
    const [imageDimensions, setImageDimensions] = useState({ width: null, height: null });
    const [IconId, setIconId] = useState(!propsDataEdit ? null : propsDataEdit.IconId);

    const getLocationMap = MapCode.filter((item) =>
        item.Map.FacilityCode === propsFacilityCode &&
        item.Map.MapCode === propsMapCode
    ).map((item) => item.Map.Image);
    const locationMap = `${process.env.REACT_APP_BASEURL_IMG}/${getLocationMap}`;

    const fetchCode = useCallback(async () => {
        try {
            if (propsMapCode) {
                const resMap = await getMap();
                setMapCode(resMap);

                const resIcon = await getIcon();

                if (setIcon) {
                    // const id = "IC0006";
                    const filter = resIcon.filter(item => setIcon.includes(item.IconId));
                    // console.log("ICONS", filter);
                    setAvailableIcons(filter);
                } else {
                    setAvailableIcons(resIcon);
                }
            }
        } catch (error) {
            console.log();
        }
    }, [propsMapCode, setIcon]);

    useEffect(() => {
        fetchCode();
    }, [fetchCode]);

    const handleDragStart = (event, iconLink, iconId) => {
        event.dataTransfer.setData("text/plain", iconLink);
        setIconId(iconId.IconId);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const iconLink = event.dataTransfer.getData("text/plain");

        const existingIcons = event.target.querySelectorAll("img");
        existingIcons.forEach((icon) => {
            icon.parentNode.removeChild(icon);
        });

        const x = event.clientX - event.target.getBoundingClientRect().left - 10;
        const y = event.clientY - event.target.getBoundingClientRect().top - 10;

        const newIcon = document.createElement("img");
        newIcon.src = iconLink;
        newIcon.alt = "Dropped Icon";
        newIcon.width = 24;
        newIcon.style.position = "absolute";
        newIcon.style.top = `${y}px`;
        newIcon.style.left = `${x}px`;

        event.target.appendChild(newIcon);

        propsIconName(IconId)
        propsIconLocY(y)
        propsIconLocX(x)
        propsIconUrl(iconLink)
    };

    useEffect(() => {
        const image = new Image();
        image.src = locationMap;

        image.onload = () => {
            const width = image.width;
            const height = image.height;
            setImageDimensions({ width, height });
        };
    }, [locationMap]);

    useEffect(() => {
        if (propsMapCode && propsDataEdit && imageDimensions.width && imageDimensions.height) {

            const defaultIcon = document.createElement("img");
            defaultIcon.src = propsDataEdit.url;
            defaultIcon.alt = "Default Icon";
            defaultIcon.width = 24;
            defaultIcon.style.position = "absolute";
            defaultIcon.style.top = `${propsDataEdit.IconLocationY}px`;
            defaultIcon.style.left = `${propsDataEdit.IconLocationX}px`;

            const mapContainer = document.getElementById("map-container");
            mapContainer.appendChild(defaultIcon);

            propsIconName(propsDataEdit.IconId)
            propsIconLocY(propsDataEdit.IconLocationY)
            propsIconLocX(propsDataEdit.IconLocationX)
            propsIconUrl(propsDataEdit.url)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propsMapCode, imageDimensions, propsDataEdit]);

    return (
        <>
            {propsMapCode === "" ? null :
                <Box
                    sx={{
                        gridColumn: "span 4",
                        backgroundColor: "#ffffff",
                        width: "100%",
                        height: "100%",
                        boxShadow: "0px 0px 15px #00000016",
                        p: 4,
                        borderRadius: "8px",
                    }}>
                    <Typography
                        variant="h2"
                        fontWeight="bold"
                        sx={{ mb: "10px" }}
                    >
                        drag n drop icon to map
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: 2,
                            p: 1,
                            borderRadius: "10px",
                            border: "1px solid #00000026",
                        }}
                    >
                        {/* Icon Container with Scroll */}
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "6px",
                                overflowX: "auto", // Enable horizontal scrolling
                                maxWidth: "calc(100% - 150px)", // Limit width to leave space for buttons
                                paddingBottom: "8px", // Optional: Add padding for better scrollbar appearance
                            }}
                        >
                            {availableIcons.map((item, itemI) => (
                                <Box
                                    key={itemI}
                                    className="img"
                                    draggable
                                    onDragStart={(event) =>
                                        handleDragStart(
                                            event,
                                            `${process.env.REACT_APP_BASEURL_IMG}/${item.Image}`,
                                            item
                                        )
                                    }
                                    sx={{
                                        flexShrink: 0, // Prevent icons from shrinking when container width is smaller
                                    }}
                                >
                                    <img
                                        src={`${process.env.REACT_APP_BASEURL_IMG}/${item.Image}`}
                                        alt="icon"
                                        width={30}
                                    />
                                </Box>
                            ))}
                        </Box>

                        {/* Buttons */}
                        {!setIcon && (
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "4px",
                                }}
                            >
                                <Button onClick={fetchCode} icon={<ReloadOutlined />}></Button>
                                <Link
                                    to={`/master/icon/form`}
                                    target="_blank"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button icon={<PlusOutlined />}>Add Icon</Button>
                                </Link>
                            </Box>
                        )}
                    </Box>


                    <Box
                        sx={{
                            mt: 1,
                            borderRadius: "8px",
                            border: "1px solid #00000026",
                            p: 2,
                            width: imageDimensions?.width || "100%",
                            height: imageDimensions?.height || "auto",
                        }}
                    >
                        <Box
                            id="map-container"
                            sx={{
                                backgroundImage: `url(${locationMap})`,
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "100% 100%",
                                position: "relative",
                                width: "100%",
                                height: "100%",
                            }}
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                        >
                            {/* Tambahkan elemen lain jika diperlukan */}
                        </Box>
                    </Box>
                </Box >
            }
        </>
    );
};

export default MapIcon;
