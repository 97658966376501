import React, { useCallback, useEffect, useState, useRef } from "react";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

import Authentication from "../../auth";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getDMMainTrasaction, getWOContactTrasaction, getWODocumentTrasaction, getWOMainTrasaction, getWOResourceTrasaction } from "../Api/GetData";
import { ButtonForm } from "../../global/components/ButtonAction";
import { PostDMTransaction } from "../Api/PostData";
import FormDMPart from "./DM_Part/form";
import FormDMRE from "./DM_RE/form";
import FormDMDoc from "./DM_Doc/form";
import FormDMCT from "./DM_CT/form";
import dayjs from "dayjs";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import FormTranApproval from "../TranApproval/form";
import { StyleButtonForm } from "../../global/StyleCSS";
import { getFacility } from "../../../Api/Master/GetData";
import InputModal from "../../../components/Global/InputModal";
import { columnsFacility, columnsWorkOrder } from "./ColumunsDM";
import TranView from "../TranApproval/tranView";
import { FacilityAuthority } from "../../../components/Global/Authority";


const FormDMTransaction = () => {

    Authentication();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const action = searchParams.get('action');
    const faccode = searchParams.get('faccode');
    const trannumber = searchParams.get('trannumber');

    const [DMTransaction, setDMTransaction] = useState("");
    const [dataDMTransaction, setDataDMTransaction] = useState("");
    const [isDisable, setIsDisable] = useState(false);
    const [onApproval, setOnApproval] = useState(false);

    useEffect(() => {
        if (faccode || trannumber) {
            const fecthDMTransaction = async () => {
                try {
                    const response = await getDMMainTrasaction(faccode, trannumber);
                    setDMTransaction(response[0].DMTransaction);
                    setDataDMTransaction(response[0]);
                } catch (error) {
                    console.log(error);
                }
            }
            fecthDMTransaction();
        }
    }, [faccode, trannumber]);


    useEffect(() => {
        if (faccode && trannumber) {
            if (DMTransaction) {
                setIsDisable(true);
                setOnApproval(true);

                setOpenModalFacility(true);
                setOpenModalWorkOrder(true);

                setDMNumber(DMTransaction.DMNumber);
                setDMDate(dayjs(DMTransaction.DMDate));
                setRescheduleDate(dayjs(DMTransaction.RescheduleDate));
            }
        }
    }, [DMTransaction, dataDMTransaction, faccode, trannumber]);

    const myFacilityAuth = FacilityAuthority();

    const formRef = useRef(null);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const [DMNumber, setDMNumber] = useState("");

    const [dataFacility, setDataFacility] = useState([]);
    const [selectFacility, setSelectFacility] = useState("");
    const [openModalFacility, setOpenModalFacility] = useState("");
    const FacilityCode = selectFacility ? selectFacility.FacilityCode : '';
    const FacilityName = selectFacility ? selectFacility.FacilityName : '';

    const [dataWorkOrder, setDataWorkOrder] = useState([]);
    const [selectWorkOrder, setSelectWorkOrder] = useState("");
    const [openModalWorkOrder, setOpenModalWorkOrder] = useState("");
    const WONumber = selectWorkOrder ? selectWorkOrder.WOTransaction.WONumber : '';
    const RequestBy = selectWorkOrder ? selectWorkOrder.WOTransaction.RequestBy : '';
    const RequestByName = selectWorkOrder ? selectWorkOrder.RequestName : '';
    const DMType = selectWorkOrder ? selectWorkOrder.WOTransaction.WOType : '';
    const LocationCode = selectWorkOrder ? selectWorkOrder.WOTransaction.LocationCode : '';
    const LocationName = selectWorkOrder.LocationName ? selectWorkOrder.LocationName : '-';
    const WorkCatCode = selectWorkOrder ? selectWorkOrder.WOTransaction.WorkCatCode : '';
    const WorkCategory = selectWorkOrder ? selectWorkOrder.WorkCategory : '';
    const WorkSubCatCode = selectWorkOrder ? selectWorkOrder.WOTransaction.WorkSubCatCode : '';
    const WorkSubCategory = selectWorkOrder ? selectWorkOrder.WorkSubCategory : '';
    const DueDateWO = selectWorkOrder ? selectWorkOrder.WOTransaction.DueDateWO : null;


    const [DueDate, setDueDate] = useState(null);

    const [DMDate, setDMDate] = useState(dayjs());
    const [RescheduleDate, setRescheduleDate] = useState(null);

    const [dataPart, setDataPart] = useState([]);
    const [dataRE, setDataRE] = useState([]);
    const [dataDoc, setDataDoc] = useState([]);
    const [dataCT, setDataCT] = useState([]);

    const [dataWORE, setDataWORE] = useState([]);
    const [dataWODoc, setDataWODoc] = useState([]);
    const [dataWOCT, setDataWOCT] = useState([]);


    // FACILITY
    useEffect(() => {
        const fetchFacility = async () => {
            try {
                const response = await getFacility(myFacilityAuth, 'false');
                setDataFacility(response);

                if (faccode && trannumber) {
                    const seleted = response.filter((item) => item.FacilityCode === faccode)
                    setSelectFacility(seleted[0])
                }

            } catch (error) {
                console.log(error);
            }
        }
        if (openModalFacility) {
            fetchFacility();
            setOpenModalFacility(false)
            setIsLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [faccode, openModalFacility, trannumber]);


    // WORK ODER
    useEffect(() => {
        const fetchWorkOrder = async () => {
            try {
                const res = await getWOMainTrasaction(FacilityCode);

                const filterRes = res.filter((item) =>
                    item.WOTransaction.TranStatus === 2 &&
                    item.WOTransaction.IsClosed === false
                )

                setDataWorkOrder(filterRes);

                if (faccode && trannumber) {
                    const selected = res.filter((item) => item.WOTransaction.WONumber === DMTransaction.WONumber)
                    setSelectWorkOrder(selected[0])
                }

            } catch (error) {
                console.log(error);
            }
        }

        if (openModalWorkOrder) {
            fetchWorkOrder();
            setOpenModalWorkOrder(false);
            setIsLoading(false);
        }

    }, [DMTransaction.WONumber, FacilityCode, faccode, openModalWorkOrder, trannumber]);


    useEffect(() => {
        if (formRef.current) {
            formRef.current.setFieldValue("FacilityName", FacilityName);
            formRef.current.setFieldValue("WONumber", WONumber);
            formRef.current.setFieldValue("RequestBy", RequestByName);
            formRef.current.setFieldValue("DMType", DMType);
            formRef.current.setFieldValue("LocationName", LocationName);
            formRef.current.setFieldValue("WorkCategory", WorkCategory);
            formRef.current.setFieldValue("WorkSubCategory", WorkSubCategory);
        }
        if (selectWorkOrder) {
            setDueDate(dayjs(DueDateWO))
        }
    }, [DMType, DueDateWO, FacilityName, LocationName, RequestByName, WONumber, WorkCategory, WorkSubCategory, selectWorkOrder]);



    const FetchDMNumber = useCallback(async () => {
        try {
            const response = await getDMMainTrasaction();
            const filterCode = response.filter(
                (option) => option.DMTransaction.FacilityCode === FacilityCode
            );
            if (filterCode.length > 0) {
                const bcData = filterCode.filter(item => item.DMTransaction.DMNumber && item.DMTransaction.DMNumber.startsWith('DMN'));
                if (bcData.length > 0) {
                    const lastCode = bcData[bcData.length - 1].DMTransaction.DMNumber;
                    const nextNumber = parseInt(lastCode.substr(3)) + 1;
                    setDMNumber(`DMN${nextNumber.toString().padStart(4, "0")}`);
                } else {
                    setDMNumber("DMN0001");
                }
            } else {
                setDMNumber("DMN0001");
            }
        } catch (error) {
            setDMNumber("DMN0001");
            console.log(error);
        }
    }, [FacilityCode]);


    useEffect(() => {
        if (FacilityCode && (!faccode || !trannumber)) {
            FetchDMNumber();
        }
    }, [FacilityCode, FetchDMNumber, faccode, trannumber]);

    useEffect(() => {
        if (formRef.current) {
            formRef.current.setFieldValue("DMNumber", DMNumber);
        }
    }, [DMNumber]);


    // WO RESOURCE
    const fetchDataWORE = useCallback(async () => {
        try {
            if (selectWorkOrder && selectWorkOrder.WOTransaction) {
                const res = await getWOResourceTrasaction(FacilityCode, selectWorkOrder.WOTransaction.WONumber);
                const filter = res.reverse();
                setDataWORE(filter);
            }
        } catch (error) {
            console.log(error);
        }
    }, [FacilityCode, selectWorkOrder]);

    // WO DOCUMENT
    const fetchDataWODoc = useCallback(async () => {
        try {
            if (selectWorkOrder && selectWorkOrder.WOTransaction) {
                const res = await getWODocumentTrasaction(FacilityCode, selectWorkOrder.WOTransaction.WONumber);
                const filter = res.reverse();
                setDataWODoc(filter);
            }
        } catch (error) {
            console.log(error);
        }
    }, [selectWorkOrder, FacilityCode]);

    // WO CONTACT
    const fetchDataWOCT = useCallback(async () => {
        try {
            if (selectWorkOrder && selectWorkOrder.WOTransaction) {
                const res = await getWOContactTrasaction(FacilityCode, selectWorkOrder.WOTransaction.WONumber);
                const filter = res.reverse();
                setDataWOCT(filter);
            }
        } catch (error) {
            console.log(error);
        }
    }, [selectWorkOrder, FacilityCode]);

    useEffect(() => {
        if (selectWorkOrder && selectWorkOrder.WOTransaction) {
            fetchDataWORE();
            fetchDataWODoc();
            fetchDataWOCT();
        }
    }, [selectWorkOrder, fetchDataWOCT, fetchDataWODoc, fetchDataWORE]);

    const handleCancel = () => {
        formRef.current.resetForm();
        setDMNumber('');
        setDMDate(dayjs());
        setRescheduleDate(null);
    };

    const handleFormSubmit = async (values) => {
        try {
            setLoading(true);

            if (dataRE.length === 0 || dataCT.length === 0) {
                enqueueSnackbar("Complete the detail Demand data form!", { variant: "warning" });
                setLoading(false);
                return;
            }

            const DMDateNow = DMDate.format('YYYY-MM-DD');
            const DueDateNow = DueDate.format('YYYY-MM-DD');
            const RescheduleDateNow = RescheduleDate.format('YYYY-MM-DD');

            const payload = {
                ...values,
                FacilityCode: FacilityCode,
                DMNumber: DMNumber,
                WONumber: WONumber,
                DMDate: DMDateNow,
                DueDate: DueDateNow,
                RequestBy: RequestBy,
                DMType: DMType,
                LocationCode: LocationCode,
                WorkCatCode: WorkCatCode,
                WorkSubCatCode: WorkSubCatCode,
                RescheduleDate: RescheduleDateNow,
                resources: dataRE,
                parts: dataPart,
                documents: dataDoc,
                contacts: dataCT,
            };

            console.log('Submit Data', payload);

            const response = await PostDMTransaction(payload);
            enqueueSnackbar(response.data.statusMessage, { variant: "success" });
            navigate("/transaction/demand");

        } catch (error) {
            if (error.response && error.response.data && error.response.data.statusMessage) {
                const errorMessage = error.response.data.statusMessage;
                enqueueSnackbar(errorMessage, { variant: "error" });
            } else {
                enqueueSnackbar("Data failed to save.", { variant: "error" });
            }
            console.error("error code", error.response.data);
        }

        setLoading(false)
    };


    return (
        <>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
                innerRef={formRef}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <Box>
                        <StyleContent>

                            <Header
                                title="DEMAND"
                                subtitle="Create a Demand"
                            />

                            <StyleForm>

                                <InputModal
                                    title="FACILITY"
                                    label="Facility Name"
                                    name="FacilityName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.FacilityName}
                                    error={!!touched.FacilityName && !!errors.FacilityName}
                                    helperText={touched.FacilityName && errors.FacilityName}
                                    dataSource={dataFacility}
                                    columns={columnsFacility}
                                    loading={isLoading}
                                    propsSelecteRow={(values) => setSelectFacility(values)}
                                    propsOpenModal={(values) => setOpenModalFacility(values)}
                                    LinktoCreate={`/master/facility/form`}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="DM Number*"
                                    onBlur={handleBlur}
                                    value={DMNumber}
                                    onChange={(e) => {
                                        handleChange(e)
                                        setDMNumber(e.target.value);
                                    }}
                                    name="DMNumber"
                                    error={!!touched.DMNumber && !!errors.DMNumber}
                                    helperText={touched.DMNumber && errors.DMNumber}
                                    sx={{ gridColumn: "span 2" }}
                                    inputProps={{ maxLength: 10 }}
                                    InputProps={{
                                        readOnly: isDisable
                                    }}
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="DM Date*"
                                        value={DMDate}
                                        onBlur={handleBlur}
                                        onChange={(date) => setDMDate(date)}
                                        name="DMDate"
                                        error={!!touched.DMDate && !!errors.DMDate}
                                        helperText={touched.DMDate && errors.DMDate}
                                        sx={{ gridColumn: "span 2" }}
                                        readOnly={isDisable}
                                    />
                                </LocalizationProvider>

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="DM Status*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={DMTransaction ? (DMTransaction.DMStatus || values.DMStatus) : values.DMStatus}
                                    name="DMStatus"
                                    error={!!touched.DMStatus && !!errors.DMStatus}
                                    helperText={touched.DMStatus && errors.DMStatus}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: isDisable
                                    }}
                                />

                                <InputModal
                                    title="WORK ORDER"
                                    label="Work Order"
                                    name="WONumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.WONumber}
                                    error={!!touched.WONumber && !!errors.WONumber}
                                    helperText={touched.WONumber && errors.WONumber}
                                    dataSource={dataWorkOrder}
                                    columns={columnsWorkOrder}
                                    loading={isLoading}
                                    propsSelecteRow={(values) => setSelectWorkOrder(values)}
                                    propsOpenModal={(values) => setOpenModalWorkOrder(values)}
                                    LinktoCreate={`/transaction/workorder/form`}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Request By*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.RequestBy}
                                    name="RequestBy"
                                    error={!!touched.RequestBy && !!errors.RequestBy}
                                    helperText={touched.RequestBy && errors.RequestBy}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="DM Type*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.DMType}
                                    name="DMType"
                                    error={!!touched.DMType && !!errors.DMType}
                                    helperText={touched.DMType && errors.DMType}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Location Name*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.LocationName}
                                    name="LocationName"
                                    error={!!touched.LocationName && !!errors.LocationName}
                                    helperText={touched.LocationName && errors.LocationName}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Work Category Name*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.WorkCategory}
                                    name="WorkCategory"
                                    error={!!touched.WorkCategory && !!errors.WorkCategory}
                                    helperText={touched.WorkCategory && errors.WorkCategory}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Work SubCategory Name*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.WorkSubCategory}
                                    name="WorkSubCategory"
                                    error={!!touched.WorkSubCategory && !!errors.WorkSubCategory}
                                    helperText={touched.WorkSubCategory && errors.WorkSubCategory}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />


                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Due Date"
                                        value={DueDate}
                                        onBlur={handleBlur}
                                        onChange={(date) => setDueDate(date)}
                                        name="DueDate"
                                        error={!!touched.DueDate && !!errors.DueDate}
                                        helperText={touched.DueDate && errors.DueDate}
                                        sx={{ gridColumn: "span 2" }}
                                        readOnly={true}
                                    />
                                </LocalizationProvider>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Reschedule Date"
                                        value={RescheduleDate}
                                        onBlur={handleBlur}
                                        onChange={(date) => setRescheduleDate(date)}
                                        name="RescheduleDate"
                                        error={!!touched.RescheduleDate && !!errors.RescheduleDate}
                                        helperText={touched.RescheduleDate && errors.RescheduleDate}
                                        sx={{ gridColumn: "span 2" }}
                                        readOnly={isDisable}
                                    />
                                </LocalizationProvider>

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Comments"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={DMTransaction ? (DMTransaction.Comments || values.Comments) : values.Comments}
                                    name="Comments"
                                    sx={{ gridColumn: "span 2" }}
                                    multiline
                                    rows={4}
                                    InputProps={{
                                        readOnly: isDisable
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Description"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={DMTransaction ? (DMTransaction.Description || values.Description) : values.Description}
                                    name="Description"
                                    sx={{ gridColumn: "span 2" }}
                                    multiline
                                    rows={4}
                                    InputProps={{
                                        readOnly: isDisable
                                    }}
                                />

                            </StyleForm>

                            <Box sx={{
                                margin: "20px 0",
                                padding: 2,
                                borderRadius: 2,
                                boxShadow: "0px 0px 10px #00000026",
                            }}>
                                <FormDMRE
                                    onSaveData={(data) => setDataRE(data)}
                                    FacilityCode={FacilityCode}
                                    DMNumber={DMNumber}
                                    onEdit={false}
                                    onApproval={onApproval}
                                    WORE={dataWORE}
                                />
                            </Box>

                            <Box sx={{
                                margin: "20px 0",
                                padding: 2,
                                borderRadius: 2,
                                boxShadow: "0px 0px 10px #00000026",
                            }}>
                                <FormDMPart
                                    onSaveData={(data) => setDataPart(data)}
                                    FacilityCode={FacilityCode}
                                    DMNumber={DMNumber}
                                    onEdit={false}
                                    onApproval={onApproval}
                                />
                            </Box>

                            <Box sx={{
                                margin: "20px 0",
                                padding: 2,
                                borderRadius: 2,
                                boxShadow: "0px 0px 10px #00000026",
                            }}>
                                <FormDMDoc
                                    onSaveData={(data) => setDataDoc(data)}
                                    FacilityCode={FacilityCode}
                                    DMNumber={DMNumber}
                                    onEdit={false}
                                    onApproval={onApproval}
                                    WODoc={dataWODoc}
                                />
                            </Box>

                            <Box sx={{
                                margin: "20px 0",
                                padding: 2,
                                borderRadius: 2,
                                boxShadow: "0px 0px 10px #00000026",
                            }}>
                                <FormDMCT
                                    onSaveData={(data) => setDataCT(data)}
                                    FacilityCode={FacilityCode}
                                    DMNumber={DMNumber}
                                    onEdit={false}
                                    onApproval={onApproval}
                                    WOCT={dataWOCT}
                                />
                            </Box>

                        </StyleContent>

                        {
                            (faccode || trannumber) ? (
                                action === 'process' ? (
                                    <FormTranApproval />
                                ) : (
                                    <TranView />
                                )
                            ) : (
                                <ButtonForm
                                    handleCancel={handleCancel}
                                    handleSubmit={handleSubmit}
                                    loading={loading}
                                />
                            )
                        }

                    </Box>
                )}
            </Formik>
        </>
    );
};

const checkoutSchema = yup.object().shape({
    FacilityName: yup
        .string()
        .max(30, "Maximum of 30 characters allowed")
        .required("Please complete this Facility Name."),
    DMNumber: yup
        .string()
        .max(30, "Maximum of 30 characters allowed")
        .required("Please complete this DM Number."),
    DMStatus: yup
        .string()
        .max(30, "Maximum of 30 characters allowed")
        .required("Please complete this DM Status."),
    WONumber: yup
        .string()
        .required("Please complete this WO Number."),
    RequestBy: yup
        .string()
        .max(30, "Maximum of 30 characters allowed")
        .required("Please complete this Reques By."),
    DMType: yup
        .string()
        .required("Please complete this DM Type."),
    LocationName: yup
        .string()
        .required("Please complete this Location Name."),
    WorkCategory: yup
        .string()
        .required("Please complete this Work Category Code."),
    WorkSubCategory: yup
        .string()
        .required("Please complete this Work SubCategory Code."),
});

const initialValues = {
    FacilityName: "",
    DMNumber: "",
    DMDate: "",
    DMStatus: "",
    WONumber: "",
    RequestBy: "",
    DMType: "",
    LocationName: "",
    WorkCategory: "",
    WorkSubCategory: "",
    Comments: "",
    Description: ""
};


export default FormDMTransaction;
