import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box, Tooltip, Typography } from "@mui/material";
import { Space, Table, Tabs } from "antd";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import SearchData from "../../global/components/FeatureTable/SearchData";
import { getMainAdjustmentTransaction } from "../Api/GetData";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import AddAuthorityIdx from "../../../components/Global/Authority/AddAuthority";
import EditAuthorityIdx from "../../../components/Global/Authority/EditAuthority";
import DeleteAuthorityIdx from "../../../components/Global/Authority/DeleteAuthority";
import PrintAuthorityIdx from "../../../components/Global/Authority/PrintAuthority";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DataDetailAdjustment from "./AdjustmentDetail";
import EditAdjustmentTransaction from "./edit";
import DeleteAdjustmentTransaction from "./delete";

const AdjustmentTransaction = () => {

    const myAddAuthIdx = AddAuthorityIdx();
    const myEditAuthIdx = EditAuthorityIdx();
    const myDeleteAuthIdx = DeleteAuthorityIdx();
    const myPrintAuthIdx = PrintAuthorityIdx();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState("");

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await getMainAdjustmentTransaction();
            setData(response);
        } catch (error) {
            console.log(error.response.data);
        }
        setIsLoading(false)
    };

    useEffect(() => {
        fetchData();
    }, []);

    const expandedRowRender = (record) => {

        return (
            <Box sx={{ margin: "10px" }}>
                {record.Adjustment.Description !== '' ?
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            marginBottom: "14px",
                            borderBottom: '2px solid #00000015'
                        }}
                    >
                        <Typography variant="h5" fontWeight={600}>Description : </Typography>
                        <Typography>{record.Adjustment.Description}</Typography>
                    </div>
                    : ''
                }
                <Tabs
                    // onChange={onChange}
                    type="card"
                    items={[
                        {
                            label: 'Detail',
                            key: 'detail',
                            children: <DataDetailAdjustment record={record.Adjustment} />,
                        },
                    ]}
                />
            </Box>
        );
    };


    const columns = [
        {
            title: 'No',
            dataIndex: 'key',
            width: 100,
            fixed: 'left',
            sorter: (a, b) => a.key - b.key,
        },
        {
            title: 'Facility Name',
            dataIndex: 'FacilityName',
            key: 'FacilityName',
            sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
        },
        {
            title: 'Adjustment Number',
            dataIndex: ['Adjustment', 'ADNumber'],
            key: 'AdjustmentNumber',
            sorter: (a, b) => a.Adjustment.ADNumber.localeCompare(b.Adjustment.ADNumber),
        },
        {
            title: 'Periode',
            dataIndex: ['Adjustment', 'Periode'],
            key: 'Periode',
            sorter: (a, b) => a.Adjustment.Periode.localeCompare(b.Adjustment.Periode),
        },
        {
            title: 'Adjustment Date',
            dataIndex: ['Adjustment', 'ADDate'],
            key: 'AdjustmentDate',
            sorter: (a, b) => a.Adjustment.ADDate.localeCompare(b.Adjustment.ADDate),
        },
        {
            title: 'Adjustment',
            dataIndex: 'ADName',
            key: 'Adjustment',
            sorter: (a, b) => a.ADName.localeCompare(b.ADName),
        },
        {
            title: 'Incoming',
            dataIndex: ['Adjustment', 'Incoming'],
            key: 'Incoming',
            render: (value) => (
                <Box display="flex" justifyContent="center" >
                    {
                        value ? (
                            <Tooltip title="Inactive">
                                <CancelIcon color="error" />
                            </Tooltip>
                        ) : (
                            <Tooltip title="Active" >
                                <CheckCircleIcon color="success" />
                            </Tooltip >
                        )}
                </Box>
            )
        },
        {
            title: 'Posting GL',
            key: 'IsPostingGL',
            dataIndex: ['Adjustment', 'IsPostingGL'],
            render: (value) => (
                <Box display="flex" justifyContent="center" >
                    {
                        value ? (
                            <Tooltip title="Inactive">
                                <CancelIcon color="error" />
                            </Tooltip>
                        ) : (
                            <Tooltip title="Active" >
                                <CheckCircleIcon color="success" />
                            </Tooltip >
                        )}
                </Box>
            )
        },
        {
            title: 'Status',
            key: 'Status',
            dataIndex: ['Adjustment', 'AppStatus'],
            sorter: (a, b) => a.Adjustment.AppStatus.localeCompare(b.Adjustment.AppStatus),
            render: (_, record) => {
                if (!record.Adjustment.AppStatus) {
                    return "-";
                }
                return record.Adjustment.AppStatus;
            }
        },
    ];

    if (myEditAuthIdx > 0 || myDeleteAuthIdx > 0) {
        columns.push({
            title: 'Action',
            key: 'Action',
            fixed: 'right',
            width: 100,
            render: (_, record) => {
                if (record.Adjustment.AppStatus === 'CANCEL') {
                    return null;
                }
                return (
                    <Space>
                        {myEditAuthIdx > 0 && (
                            <EditAdjustmentTransaction data={record} onEdit={fetchData} />
                        )}

                        {myDeleteAuthIdx > 0 && (
                            <DeleteAdjustmentTransaction data={record} onDelete={fetchData} />
                        )}
                    </Space>
                );
            },
        })
    }


    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = data.filter((item) =>
        Object.values(item).some(
            (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    const [selectedRows, setSelectedRows] = useState([]);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
            // console.log(selectedRows);
        },
    };

    const columnsExport = columns
        .filter((item) => item.key !== "Action" && item.key !== "key")
        .map((item) => {

            if (item.title === "Active") {
                return {
                    title: item.title,
                    key: item.dataIndex,
                    render: (record) => (record.IsSuspend ? "Inactive" : "Active"),
                };
            }

            return {
                title: item.title,
                key: item.dataIndex,
            };
        });

    const processExportData = (data) =>
        data.map((item) => ({
            ...item,
            IsSuspend: item.IsSuspend ? "Inactive" : "Active",
        }));

    return (
        <StyleContent>
            <StyleGridHeader>
                <Header
                    title="ADJUSTMENT"
                    subtitle="All Adjustment data"
                />
                <Box display="flex" justifyContent="end" gap="8px">
                    {myPrintAuthIdx > 0 && (
                        <ExportData
                            data={processExportData(selectedRows.length > 0 ? selectedRows : data)}
                            columns={columnsExport}
                            name="Adjustment"
                        />
                    )}

                    {myAddAuthIdx > 0 && (
                        <ButtonAddNew
                            link="form"
                        />
                    )}
                </Box>
            </StyleGridHeader>

            <SearchData
                filterValue={searchText}
                handleFilterChange={handleSearch}
            />

            <Table
                loading={isLoading}
                columns={columns}
                dataSource={filteredData}
                rowSelection={data.length === 0 ? null : rowSelection}
                expandable={{
                    expandedRowRender
                }}
                pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                scroll={{ x: 'max-content' }}
            />

        </StyleContent>
    );
};

export default AdjustmentTransaction