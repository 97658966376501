import React, { useRef, useState } from "react";
import * as yup from "yup";
import { Box, IconButton, TextField, Tooltip } from "@mui/material";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from '@mui/icons-material/Edit';
import { ButtonUpdate } from "../../global/components/ButtonAction";
import { Modal } from "antd";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { onlyNumber } from "../../global/Components";
import SwitchComponent from "../../../components/Global/SwitchSuspend";
import { updateBuilding } from "../../../Api/Master/UpdateData";

const EditBuildings = ({ data, onEdit }) => {

    const dataEdit = data.Building;

    const formRef = useRef(null);
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [isSuspend, setIsSuspend] = useState(false);

    const handleSwitchChange = (checked) => {
        setIsSuspend(checked);
        formRef.current.resetForm();
    };

    const handleOpen = () => {
        setOpen(true);
        setIsSuspend(data.IsSuspend);
    };

    const handleClose = () => {
        formRef.current.resetForm();
        setIsSuspend(dataEdit.IsSuspend)
        setOpen(false);
    };

    const handleFormSubmit = async (values) => {
        setLoading(true);
        try {
            const modifiedValues = {
                ...values,
                IsSuspend: isSuspend,
            };
            const response = await updateBuilding(dataEdit.FacilityCode, dataEdit.BuildingCode, modifiedValues);
            enqueueSnackbar(response.data.statusMessage, { variant: "success" });
            onEdit(dataEdit.FacilityCode && dataEdit.BuildingCode);
            setOpen(false);
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.statusMessage
            ) {
                const errorMessage = error.response.data.statusMessage;
                enqueueSnackbar(errorMessage, { variant: "error" });
            } else {
                enqueueSnackbar("Data failed to update.", { variant: "error" });
            }
            console.error("error code", error.response);
            formRef.current.resetForm();
            setOpen(false);
        }
        setLoading(false);
    };

    const handleKeyPress = (e) => {
        if (!onlyNumber.test(e.key)) {
            e.preventDefault();
        }
    };

    return (
        <Box>
            <Tooltip title="Edit" fontSize="large">
                <IconButton onClick={handleOpen}>
                    <EditIcon />
                </IconButton>
            </Tooltip>


            <Box>

                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={dataEdit}
                    validationSchema={checkoutSchema}
                    innerRef={formRef}
                >
                    {({

                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,

                    }) => (
                        <Modal
                            title={
                                <StyleEditHeader>
                                    <Header
                                        title="BUILDING"
                                        subtitle="Edit Data a Building"
                                    />
                                    <SwitchComponent
                                        isSuspend={isSuspend}
                                        handleSwitchChange={handleSwitchChange}
                                    />
                                </StyleEditHeader>
                            }
                            centered
                            open={open}
                            closable={false}
                            style={{
                                maxWidth: "90%",
                                minWidth: "80%",
                            }}
                            bodyStyle={{
                                maxHeight: "65vh",
                                overflow: "auto",
                            }}
                            footer={() => (
                                <ButtonUpdate
                                    handleClose={handleClose}
                                    handleSubmit={handleSubmit}
                                    loading={loading}
                                />
                            )}
                        >

                            <StyleForm>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Facility Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={data.FacilityName}
                                    name="FacilityCode"
                                    error={!!touched.FacilityName && !!errors.FacilityName}
                                    helperText={touched.FacilityName && errors.FacilityName}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Building Code"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.BuildingCode}
                                    name="BuildingCode"
                                    readOnly
                                    error={!!touched.BuildingCode && !!errors.BuildingCode}
                                    helperText={touched.BuildingCode && errors.BuildingCode}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Building Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.BuildingName}
                                    name="BuildingName"
                                    error={!!touched.BuildingName && !!errors.BuildingName}
                                    helperText={touched.BuildingName && errors.BuildingName}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: isSuspend,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Address"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.Address}
                                    name="Address"
                                    error={!!touched.Address && !!errors.Address}
                                    helperText={touched.Address && errors.Address}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: isSuspend,
                                    }}
                                />


                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Phone Number 1"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.Phone1}
                                    name="Phone1"
                                    error={!!touched.Phone1 && !!errors.Phone1}
                                    helperText={touched.Phone1 && errors.Phone1}
                                    sx={{ gridColumn: "span 2" }}
                                    onKeyPress={handleKeyPress}
                                    placeholder="Example: 081234567890"
                                    InputProps={{
                                        readOnly: isSuspend,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Phone Number 2"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.Phone2}
                                    name="Phone2"
                                    error={!!touched.Phone2 && !!errors.Phone2}
                                    helperText={touched.Phone2 && errors.Phone2}
                                    sx={{ gridColumn: "span 2" }}
                                    onKeyPress={handleKeyPress}
                                    placeholder="Example: 081234567890"
                                    InputProps={{
                                        readOnly: isSuspend,
                                    }}
                                />


                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Contact Person"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.ContactPerson}
                                    name="ContactPerson"
                                    error={!!touched.ContactPerson && !!errors.ContactPerson}
                                    helperText={touched.ContactPerson && errors.ContactPerson}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: isSuspend,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Description"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.Description}
                                    name="Description"
                                    sx={{ gridColumn: "span 2" }}
                                    multiline
                                    rows={4}
                                    InputProps={{
                                        readOnly: isSuspend,
                                    }}
                                />

                            </StyleForm>



                        </Modal>
                    )}
                </Formik>
            </Box>
        </Box >
    );
};

const checkoutSchema = yup.object().shape({
    BuildingName: yup
        .string()
        .max(50, "Maximum of 50 characters allowed")
        .required("Please complete this Building Name."),
    Address: yup
        .string()
        .max(200, "Maximum of 200 characters allowed")
        .required("Please complete this Address."),
    Phone1: yup
        .string()
        .max(15, "Maximum of 15 characters allowed")
        .required("Please complete this Phone Number 1."),
    Phone2: yup
        .string()
        .max(15, "Maximum of 15 characters allowed")
        .required("Please complete this Phone Number 2.")
        .test(
            "phone-not-same",
            "Phone Number 1 and Phone Number 2 should not be the same",
            function (value) {
                return value !== this.parent.Phone1;
            }
        ),
    ContactPerson: yup
        .string()
        .max(50, "Maximum of 50 characters allowed")
        .required("Please complete this Contact Person."),

});

export default EditBuildings