import React, { useCallback, useEffect, useState } from 'react'
import { Table } from 'antd'
import dayjs from 'dayjs'
import { Link, useParams } from 'react-router-dom'
import { getDetailDemand } from '../API'
import StyleContent from '../../../components/Global/StyleContent'
import StyleGridHeader from '../../../components/Global/StyleContent/StyleGrid/StyleGridHeader'
import Header from '../../../components/Header'
import SearchData from '../../global/components/FeatureTable/SearchData'

const SummaryDemand = () => {

    const { faccode, buildcode } = useParams();

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await getDetailDemand(faccode, buildcode);
            setData(response);
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setData([]);
            }
        }
        setIsLoading(false);
    }, [buildcode, faccode]);


    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = data.filter((item) =>
        Object.values(item).some(
            (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    const columns = [
        {
            title: 'No',
            dataIndex: 'key',
            width: 100,
            fixed: 'left',
            sorter: (a, b) => a.key - b.key,
        },
        {
            title: 'Tran Number',
            dataIndex: 'TranNumber',
            fixed: 'left',
            width: 200,
            sorter: (a, b) => a.TranNumber.localeCompare(b.TranNumber),
            render: (value, record) =>
                <Link to={`/transaction/demand/form?action=view&faccode=${faccode}&trannumber=${record.TranNumber}`}>
                    <p style={{ fontWeight: "bold" }}>{value}</p>
                    {/* <p style={{ fontWeight: "bold" }}>{dayjs(value).format('YYYY-MM-DD')}</p> */}
                </Link>
        },
        {
            title: 'Due Date',
            dataIndex: 'DueDate',
            sorter: (a, b) => a.DueDate.localeCompare(b.DueDate),
        },
        {
            title: 'Reference Name',
            dataIndex: 'TranTypeRefName',
            sorter: (a, b) => a.TranTypeRefName.localeCompare(b.TranTypeRefName),
        },
        {
            title: 'Type',
            dataIndex: 'TranType',
            sorter: (a, b) => a.TranType.localeCompare(b.TranType),
        },
        {
            title: 'Facility',
            dataIndex: 'FacilityName',
            sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
        },
        {
            title: 'Location',
            dataIndex: 'LocationName',
            sorter: (a, b) => a.LocationName.localeCompare(b.LocationName),
        },
        // {
        //     title: 'Schedule Start',
        //     dataIndex: 'ScheduleStart',
        //     sorter: (a, b) => a.ScheduleStart.localeCompare(b.ScheduleStart),
        //     render: (date) => <p>{dayjs(date).format('YYYY-MM-DD')}</p>
        // },
        // {
        //     title: 'Schedule End',
        //     dataIndex: 'ScheduleEnd',
        //     sorter: (a, b) => a.ScheduleEnd.localeCompare(b.ScheduleEnd),
        //     render: (date) => <p>{dayjs(date).format('YYYY-MM-DD')}</p>
        // },
    ];

    return (
        <StyleContent>
            <StyleGridHeader>
                <Header title={`PREVENTIVE MAINTENANCE`} subtitle={`All data a Status Preventive`} />
            </StyleGridHeader>

            <SearchData
                filterValue={searchText}
                handleFilterChange={handleSearch}
            />

            <Table
                loading={isLoading}
                columns={columns}
                dataSource={filteredData}
                pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                scroll={{ x: 'max-content' }}
            />
        </StyleContent>
    )
}

export default SummaryDemand