import React, { useEffect, useRef, useState } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { onlyNumber } from "../../global/Components";
import { ButtonForm } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { getCategory, getPart, getUnits } from "../../../Api/Master/GetData";
import { postPart } from "../../../Api/Master/PostData";
import InputModal from "../../../components/Global/InputModal";
import { columnsCategory } from "./ColumnsPart";

const FormPart = () => {

    const formRef = useRef(null);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState(false);

    const [loading, setLoading] = useState(false);
    const [PartCode, setPartCode] = useState("");

    const [dataCategory, setDataCategory] = useState([]);
    const [selectCategory, setSelectCategory] = useState(null);
    const [openModalCategory, setOpenModalCategory] = useState("");
    const CategoryName = selectCategory ? selectCategory.CatName : '';
    const CategoryCode = selectCategory ? selectCategory.CatCode : '';

    const [Unit, setUnit] = useState([]);
    const [SelectedUnit, setSelectedUnit] = useState(null);
    const [OpenUnit, setOpenUnit] = useState(false);

    // CATEGORY
    useEffect(() => {
        const fetchCategory = async () => {
            setIsLoading(true);
            try {
                const response = await getCategory('Part', 'false');
                setDataCategory(response);

            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        };

        if (openModalCategory) {
            fetchCategory();
            setOpenModalCategory(false);
        }
    }, [openModalCategory]);

    useEffect(() => {
        const fetchDataUnit = async () => {
            setIsLoading(true);
            try {
                const response = await getUnits();
                setUnit(response);
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        }

        if (OpenUnit) {
            fetchDataUnit();
            setOpenUnit(false);
        }

    }, [OpenUnit]);

    useEffect(() => {
        if (formRef.current) {
            formRef.current.setFieldValue("CategoryName", CategoryName);
        }
    }, [CategoryName]);

    const fetchPartCode = async () => {
        try {
            const response = await getPart();
            if (response.length > 0) {
                const PAData = response.filter(
                    (item) => item.Part.PartCode && item.Part.PartCode.startsWith("PA")
                );
                if (PAData.length > 0) {
                    const lastCode = PAData[PAData.length - 1].Part.PartCode;
                    const nextNumber = parseInt(lastCode.substr(2)) + 1;
                    setPartCode(`PA${nextNumber.toString().padStart(4, "0")}`);
                }
            }
        } catch (error) {
            setPartCode("PA0001");
            console.log(error.response.statusText);
        }
    };

    useEffect(() => {
        fetchPartCode();
    }, []);

    useEffect(() => {
        if (formRef.current) {
            formRef.current.setFieldValue("PartCode", PartCode);
        }
    }, [PartCode]);

    const handleUnitSelection = (event, value) => {
        if (value) {
            setSelectedUnit(value.UnitCode);
        } else {
            setSelectedUnit(null);
        }
    };

    const handleFormSubmit = async (values) => {
        setLoading(true);
        try {
            const modifiedValues = {
                ...values,
                PartCode: PartCode,
                PartCategory: CategoryCode,
                UnitCode: SelectedUnit,
            };

            const response = await postPart(modifiedValues);
            enqueueSnackbar(response.data.statusMessage, { variant: "success" });
            navigate("/master/part");
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.statusMessage
            ) {
                const errorMessage = error.response.data.statusMessage;
                enqueueSnackbar(errorMessage, { variant: "error" });
            } else {
                enqueueSnackbar("Data failed to save.", { variant: "error" });
            }
            console.error("error code", error.response);
        }
        setLoading(false);
    };

    const handleKeyPress = (e) => {
        if (!onlyNumber.test(e.key)) {
            e.preventDefault();
        }
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={{ ...initialValues, PartCode: PartCode }}
            validationSchema={checkoutSchema}
            innerRef={formRef}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
            }) => (
                <Box>
                    <StyleContent>
                        <Header title="FORM PART" subtitle="Create a Part" />
                        <StyleForm>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Part Code*"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                    setPartCode(e.target.value);
                                }}
                                value={PartCode}
                                name="PartCode"
                                error={!!touched.PartCode && !!errors.PartCode}
                                helperText={touched.PartCode && errors.PartCode}
                                sx={{ gridColumn: "span 2" }}
                                inputProps={{ maxLength: 6 }}
                            />

                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Part Name*"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.PartName}
                                name="PartName"
                                error={!!touched.PartName && !!errors.PartName}
                                helperText={touched.PartName && errors.PartName}
                                sx={{ gridColumn: "span 2" }}
                                autoFocus
                            />

                            <InputModal
                                title="CATEGORY"
                                label="Category Name"
                                name="CategoryName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.CategoryName}
                                error={!!touched.CategoryName && !!errors.CategoryName}
                                helperText={touched.CategoryName && errors.CategoryName}
                                dataSource={dataCategory}
                                columns={columnsCategory}
                                loading={isLoading}
                                propsSelecteRow={(values) => setSelectCategory(values)}
                                propsOpenModal={(values) => setOpenModalCategory(values)}
                                LinktoCreate={`/master/category/form?moduleid=Part`}
                            />


                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Minimum Stock*"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.MinStock}
                                name="MinStock"
                                error={!!touched.MinStock && !!errors.MinStock}
                                helperText={touched.MinStock && errors.MinStock}
                                sx={{ gridColumn: "span 2" }}
                                onKeyPress={handleKeyPress}
                            />

                            <Autocomplete
                                fullWidth
                                disablePortal
                                onOpen={() => setOpenUnit(true)}
                                loading={isLoading}
                                options={Unit}
                                getOptionLabel={(option) => option.UnitName}
                                sx={{ gridColumn: "span 2" }}
                                onChange={(event, value) => {
                                    handleUnitSelection(event, value);
                                    setFieldValue("UnitCode", value ? value.UnitName : "");
                                }}
                                value={
                                    Unit.find(
                                        (option) => option.UnitCode === SelectedUnit
                                    ) || null
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Unit Name*"
                                        onBlur={handleBlur}
                                        error={!!touched.UnitCode && !!errors.UnitCode}
                                        helperText={touched.UnitCode && errors.UnitCode}
                                    />
                                )}
                            />

                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Description"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.Description}
                                name="Description"
                                sx={{ gridColumn: "span 2" }}
                                multiline
                                rows={4}
                            />
                        </StyleForm>
                    </StyleContent>

                    <ButtonForm
                        handleSubmit={handleSubmit}
                        loading={loading}
                    />
                </Box>
            )}
        </Formik>
    );
};

const checkoutSchema = yup.object().shape({
    PartCode: yup
        .string()
        .max(20, "Maximum of 20 characters allowed")
        .required("Please complete this Part Code."),
    PartName: yup
        .string()
        .max(50, "Maximum of 50 characters allowed")
        .required("Please complete this Part Name."),
    CategoryName: yup
        .string()
        .required("Please complete this Category Name."),
    MinStock: yup
        .string()
        .required("Please complete this Minimum Stock."),
    UnitCode: yup
        .string()
        .max(5, "Maximum of 5 characters allowed")
        .required("Please complete this Unit Code."),
});
const initialValues = {
    PartCode: "",
    PartName: "",
    CategoryName: "",
    MinStock: "",
    UnitCode: "",
    Description: "",
};

export default FormPart;
