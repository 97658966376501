import React, { useEffect, useState } from 'react';
import { Form, Input, Popconfirm, Table, Typography, DatePicker, InputNumber, Image } from 'antd';

import { CloseOutlined, DeleteOutlined, EditFilled, SaveFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { LoadingButton } from "@mui/lab"
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { getDetailAdjustmentTransaction } from '../../Api/GetData';
import { getLocation, getPart } from '../../../../Api/Master/GetData';
import InputDetailModal from '../../../../components/Global/InputDetailModal';

const { Title } = Typography;
const dateFormat = 'YYYY-MM-DD';

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    onData,
    onDataPart,
    onDataLocation,
    FacilityCode,
    onEdit,
    ...restProps
}) => {

    useEffect(() => {
        if (onEdit) {
            setOpenPart(true);
            setOpenLocation(true);
        }
    }, [onEdit]);

    const [isLoading, setIsLoading] = useState(true);

    const [dataPart, setDataPart] = useState([]);
    const [selectPart, setSelectPart] = useState("");
    const [openPart, setOpenPart] = useState(null);

    const [dataLocation, setDataLocation] = useState([]);
    const [selectLocation, setSelectLocation] = useState("");
    const [openLocation, setOpenLocation] = useState(null);

    // PART
    useEffect(() => {
        const fetchPart = async () => {
            try {

                const PartCode = onData.map(item => item.PartCode);

                const res = await getPart('false');
                const filter = res.filter(item => !PartCode.includes(item.Part.PartCode));
                setDataPart(filter);
            } catch (error) {
                console.log(error);
            }
        }

        if (openPart) {
            fetchPart();
            setOpenPart(false);
            setIsLoading();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openPart]);

    useEffect(() => {
        if (selectPart) {
            onDataPart(selectPart);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectPart]);


    // LOCATION
    useEffect(() => {
        const fetchLocation = async () => {
            try {

                const LocationCode = onData.map(item => item.LocationCode);

                const res = await getLocation(FacilityCode, 'false');
                const filter = res.filter(item => !LocationCode.includes(item.Location.LocationCode));
                setDataLocation(filter);
            } catch (error) {
                console.log(error);
            }
        }

        if (openLocation) {
            fetchLocation();
            setOpenLocation(false);
            setIsLoading();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openLocation]);

    useEffect(() => {
        if (selectLocation) {
            onDataLocation(selectLocation);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectLocation]);

    return (
        <td {...restProps}>
            {editing ? (
                <div>
                    {dataIndex === 'QTY' || dataIndex === 'COGS' ? (
                        <Form.Item
                            name={dataIndex}
                            style={{ margin: 0 }}
                        >
                            <InputNumber placeholder={title} min={1} style={{ width: '100%' }} />
                        </Form.Item>
                    ) : dataIndex === 'PartName' ? (
                        <InputDetailModal
                            title="PART"
                            label={title}
                            name={dataIndex}
                            dataSource={dataPart}
                            loading={isLoading}
                            columns={columnsPart}
                            onData={(values) => setSelectPart(values)}
                            onOpenModal={(values) => setOpenPart(values)}
                            onDetail={true}
                        />
                    ) : dataIndex === 'LocationName' ? (
                        <InputDetailModal
                            title="LOCATION"
                            label={title}
                            name={dataIndex}
                            dataSource={dataLocation}
                            loading={isLoading}
                            columns={columnsLocation}
                            onData={(values) => setSelectLocation(values)}
                            onOpenModal={(values) => setOpenLocation(values)}
                            onDetail={true}
                        />
                    ) : (
                        <Form.Item
                            name={dataIndex}
                            style={{ margin: 0 }}
                            rules={[
                                {
                                    required: true,
                                    message: `Please Input ${title}!`,
                                },
                            ]}
                        >
                            {dataIndex === 'PurchaseDate' && editing ? (
                                <DatePicker format={dateFormat} />
                            ) : (
                                <Input placeholder={title} maxLength={50} />
                            )}
                        </Form.Item>
                    )}
                </div>
            ) : (
                children
            )}
        </td>

    );
};


const FormAdjustmentDetail = ({ onSaveData, FacilityCode, ADNumber, onEdit, onApproval, ADType }) => {

    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);

    const [editingKey, setEditingKey] = useState('');
    const [loading, setLoading] = useState(false);
    const [isDisable, setIsDisable] = useState(true);

    const [dataPart, setDataPart] = useState(null);
    const [dataLocation, setDataLocation] = useState(null);

    useEffect(() => {
        if (form && dataPart) {
            form.setFieldsValue({
                PartName: dataPart.Part.PartName
            })
        }
    }, [dataPart, form]);

    useEffect(() => {
        if (form && dataLocation) {
            form.setFieldsValue({
                LocationName: dataLocation.Location.LocationName
            })
        }
    }, [dataLocation, form]);

    useEffect(() => {
        if (onEdit || onApproval) {
            const fetchData = async () => {
                try {
                    const response = await getDetailAdjustmentTransaction(FacilityCode, ADNumber);
                    const filter = response.map((row, index) => ({ ...row, key: index + 1 })).reverse()
                    setData(filter)
                    setCount(filter.length === 0 ? 0 : filter.map((item) => item.key)[0])
                    onSaveData(filter)
                } catch (error) {
                    setData([]);
                    setCount(0);
                    onSaveData([]);
                    console.log(error);
                }
            }

            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [FacilityCode, ADNumber, onEdit]);


    const isEditing = (record) => record.key === editingKey;

    const handleEdit = (record) => {

        const PurchaseDate = record.PurchaseDate ? dayjs(record.PurchaseDate) : null;

        form.setFieldsValue({
            Description: '',
            ...record,
            PurchaseDate: PurchaseDate,
        });
        setEditingKey(record.key);
    };

    const handleDelete = (key) => {
        setIsDisable(false);
        const deletedRow = data.find((row) => row.key === key);
        const deletedNumber = deletedRow.key;
        const deletedkey = deletedRow.key;

        const newData = data.filter((row) => row.key !== key);

        const updatedData = newData.map((row) => {

            if (row.key > deletedNumber && row.key > deletedkey) {
                return { ...row, key: row.key - 1 };
            }
            return row;
        });

        setCount(updatedData.length > 0 ? updatedData[0].key : 0);

        setData(updatedData);
        onSaveData(updatedData);

        // console.log("DataFormTran", updatedData);
    };


    const handleCancel = (record) => {
        if (!record.PartCode) {
            const newData = data.filter((item) => item.key !== record.key);
            setData(newData);
        } else {
            setEditingKey('');
        }
        setEditingKey('');

        // console.log("DataFormTran", data);
    };


    const handleSave = async (record) => {
        setIsDisable(false);
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => record.key === item.key);
            const PartCode = dataPart.Part.PartCode;
            const LocationCode = dataLocation.Location.LocationCode;

            if (index > -1) {
                const item = newData[index];
                const PurchaseDate = row.PurchaseDate.format('YYYY-MM-DD');
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                    PurchaseDate: PurchaseDate,
                    PartCode: PartCode,
                    LocationCode: LocationCode,
                });
                setData(newData);
                setEditingKey('');
                console.log("DataFormTran", newData);
                onSaveData(newData)
            } else {
                newData.push({
                    ...row,
                    PartCode: PartCode,
                    LocationCode: LocationCode,
                })
                setData(newData);
                setEditingKey('');
                console.log("DataFormTran", newData);
                onSaveData(newData)
            }

            const editedRow = data.find((row) => row.key === record.key);
            const lastNumber = editedRow.key;

            setCount(lastNumber);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };



    const handleAdd = () => {

        const num = count + 1;

        if (editingKey) {
            enqueueSnackbar("Complete the input form !", { variant: "warning" });
            return; // Stop saving if duplicate found
        }

        const newData = {
            key: num,
            FacilityCode: FacilityCode,
            ADNumber: ADNumber,
            PartCode: '',
            PartName: '',
            BatchNo: ADNumber,
            LocationCode: '',
            LocationName: '',
            PurchaseDate: '',
            QTY: '',
            COGS: 0,
        };
        setData([newData, ...data]);
        handleEdit(newData);

        // console.log("DataFormTran", data);
    };

    const handleSaveAllData = async () => {
        setLoading(true);
        setIsDisable(true);
        try {
            onSaveData(data);
            console.log("PostData", data);
            enqueueSnackbar("Success add form table data!!", { variant: "success" });
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const handleCancelAllData = () => {
        setData([]);
        setCount(0);
        onSaveData([]);
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'key',
            sorter: (a, b) => a.key - b.key,
            fixed: 'left',
        },
        {
            title: 'Part',
            dataIndex: 'PartName',
            editable: true,
        },
        {
            title: 'Location',
            dataIndex: 'LocationName',
            editable: true,
        },
        {
            title: 'Purchase Date',
            dataIndex: 'PurchaseDate',
            editable: true,
        },
        {
            title: 'QTY',
            dataIndex: 'QTY',
            editable: true,
        },
        // {
        //     title: 'COGS',
        //     dataIndex: 'COGS',
        //     editable: true,
        // },
    ];

    if (!onApproval) {
        columns.push({
            title: 'Actions',
            dataIndex: 'actions',
            fixed: 'right',
            width: 100,
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                        <Typography.Link onClick={() => handleSave(record)} style={{ fontSize: '18px' }}>
                            <SaveFilled />
                        </Typography.Link>

                        <Typography.Link onClick={() => handleCancel(record)} style={{ fontSize: '18px' }}>
                            <CloseOutlined />
                        </Typography.Link>
                    </span>
                ) : (
                    <span style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                        <Typography.Link onClick={() => handleEdit(record)} style={{ fontSize: '18px' }}>
                            <EditFilled />
                        </Typography.Link>
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                            <Link>
                                <DeleteOutlined style={{ fontSize: '18px' }} />
                            </Link>
                        </Popconfirm>
                    </span>
                );
            },
        });
    }

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            onCell: (record) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                onData: data,
                onDataPart: (values) => setDataPart(values),
                onDataLocation: (values) => setDataLocation(values),
                FacilityCode: FacilityCode,
                onEdit: onEdit,
            }),
            ...col,
        };
    });

    return (
        <Form form={form} component={false}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "0 10px 10px"
                }}
            >
                <Title level={3} style={{ margin: "0" }}>
                    Detail Adjustment
                </Title>
                {!onApproval && (
                    <Button
                        onClick={handleAdd}
                        color="primary"
                        variant="contained"
                        disabled={!!editingKey || !FacilityCode || !ADNumber}
                    >
                        + Add Data
                    </Button>
                )}
            </div>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                // bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                    onChange: handleCancel,
                    pageSize: 5,
                }}
                scroll={{
                    x: 'max-content',
                }}
            />
            {!onApproval && (
                <div
                    style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "flex-end",
                        margin: "10px 10px 0"
                    }}
                >
                    <Popconfirm title="Sure to clear all data?" onConfirm={handleCancelAllData}
                    >
                        <Button
                            type="button"
                            color="error"
                            variant="contained"
                            disabled={!!editingKey || !!isDisable}
                        >
                            <span>Cancel</span>
                        </Button>
                    </Popconfirm>
                    <LoadingButton
                        color="primary"
                        onClick={handleSaveAllData}
                        loading={loading}
                        variant="contained"
                        disabled={!!editingKey || !!isDisable}
                    >
                        <span>Save</span>
                    </LoadingButton>

                </div>
            )}
        </Form>
    );
};
export default FormAdjustmentDetail;

const columnsPart = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        width: 80,
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        key: 'PartCode',
        title: 'Part Code',
        dataIndex: ['Part', 'PartCode'],
        sorter: (a, b) => a.Part.PartCode.localeCompare(b.Part.PartCode),
    },
    {
        key: 'PartName',
        title: 'Part Name',
        dataIndex: ['Part', 'PartName'],
        sorter: (a, b) => a.Part.PartName.localeCompare(b.Part.PartName),
    },
    {
        key: 'PartCategory',
        title: 'Part Category',
        dataIndex: 'CategoryName',
        sorter: (a, b) => a.CategoryName.localeCompare(b.CategoryName),
    },
    {
        key: 'MinStock',
        title: 'Minum Stock',
        dataIndex: ['Part', 'MinStock'],
        sorter: (a, b) => a.Part.MinStock.localeCompare(b.Part.MinStock),
    },
    {
        key: 'UnitName',
        title: 'Unit Name',
        dataIndex: 'UnitName',
        sorter: (a, b) => a.UnitName.localeCompare(b.UnitName),
    }
];

const columnsLocation = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        width: 80,
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        title: 'Location Code',
        dataIndex: ['Location', 'LocationCode'],
        fixed: 'left',
        sorter: (a, b) => a.Location.LocationCode.localeCompare(b.Location.LocationCode),
    },
    {
        title: 'Location Name',
        dataIndex: ['Location', 'LocationName'],
        sorter: (a, b) => a.Location.LocationName.localeCompare(b.Location.LocationName),
    },
    {
        title: 'Facility Name',
        dataIndex: 'FacilityName',
        sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
        title: 'Building Name',
        dataIndex: 'BuildingName',
        sorter: (a, b) => a.BuildingName.localeCompare(b.BuildingName),
    },
    {
        title: 'Floor Level Name',
        dataIndex: 'FloorLevelName',
        sorter: (a, b) => a.FloorLevelName.localeCompare(b.FloorLevelName),
    },
    {
        title: 'Map Name',
        dataIndex: 'MapName',
        sorter: (a, b) => a.MapName.localeCompare(b.MapName),
    },
    {
        title: 'Icon Name',
        dataIndex: 'IconName',
        sorter: (a, b) => a.IconName.localeCompare(b.IconName),
    },
    {
        title: 'Icon Image',
        dataIndex: 'Icon Image',
        render: (value, record) =>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Image
                    style={{ maxWidth: 40, maxHeight: "100%", objectFit: "contain" }}
                    src={`${record.Location.url}`}
                />
            </div>
    },
]