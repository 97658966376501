import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { Box, IconButton, TextField, Tooltip } from "@mui/material";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { ButtonUpdate } from "../../global/components/ButtonAction";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { Modal } from "antd";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import SwitchComponent from "../../../components/Global/SwitchSuspend";
import { getType } from "../../../Api/Master/GetData";
import { updateVehicle } from "../../../Api/Master/UpdateData";
import InputModal from "../../../components/Global/InputModal";
import { getEmployee } from "../../../Api/Employee/GetData";
import { columnsType, columnsVehicleOwner } from "./ColumnsVehicle";

const EditVehicle = ({ data, onEdit }) => {

  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  
  const [isSuspend, setIsSuspend] = useState(false);

  const [loading, setLoading] = useState(false);

  const [purchaseDate, setPurchaseDate] = useState(dayjs(data.PurchaseDate));
  const [warrantyExpDate, setWarrantyExpDate] = useState(dayjs(data.WarrantyExpDate));

  const [isLoading, setIsLoading] = useState(true);

  const [dataType, setDataType] = useState([]);
  const [selectType, setSelectType] = useState("");
  const TypeCode = selectType ? selectType.TypeCode : '';
  const TypeName = selectType ? selectType.TypeName : '';


  const [dataVehicleOwner, setDataVehicleOwner] = useState([]);
  const [selectVehicleOwner, setSelectVehicleOwner] = useState("");
  const VehicleOwnerCode = selectVehicleOwner ? selectVehicleOwner.Employee.EmpCode : '';
  const VehicleOwnerName = selectVehicleOwner ? selectVehicleOwner.Employee.EmpName : '';


  const handleSwitchChange = (checked) => {
    setIsSuspend(checked);
    setPurchaseDate(dayjs(data.PurchaseDate))
    setWarrantyExpDate(dayjs(data.WarrantyExpDate))
    formRef.current.resetForm();
  };

  // TYPE
  useEffect(() => {
    const fetchType = async () => {
      try {
        const res = await getType("Vehicle");
        const filterRes = res.filter((item) => item.IsSuspend !== true);
        setDataType(filterRes);

        const selected = res.filter(item => item.TypeCode === data.ModelTypeCode);
        setSelectType(selected[0])

      } catch (error) {
        console.log(error);
      }
    }

    if (open) {
      setIsLoading(false);
      fetchType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // EMPLOYEE
  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const res = await getEmployee();
        const filterRes = res.filter((item) => item.Employee.IsSuspend !== true);
        setDataVehicleOwner(filterRes);

        const selected = res.filter(item => item.Employee.EmpCode === data.VehicleOwner);
        setSelectVehicleOwner(selected[0]);

      } catch (error) {
        console.log(error);
      }
    }

    if (open) {
      fetchEmployee();
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);


  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("Type", TypeName);
      formRef.current.setFieldValue("VehicleOwner", VehicleOwnerName);
    }
  }, [TypeName, VehicleOwnerName]);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {

      const purchaseDateNow = purchaseDate.format('YYYY-MM-DD');
      const warrantyExpDateNow = warrantyExpDate.format('YYYY-MM-DD');

      const payload = {
        ...values,
        PurchaseDate: purchaseDateNow,
        WarrantyExpDate: warrantyExpDateNow,
        VehicleOwner: VehicleOwnerCode,
        ModelTypeCode: TypeCode,
        IsSuspend: isSuspend,
      };

      const response = await updateVehicle(data.VehicleCode, payload);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      onEdit(data.VehicleCode);
      setOpen(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to update.", { variant: "error" });
      }
      console.error("error code", error.response);
      formRef.current.resetForm();
      setOpen(false);
    }
    setLoading(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setIsSuspend(data.IsSuspend);
  };

  const handleClose = () => {
    formRef.current.resetForm();
    setPurchaseDate(dayjs(data.PurchaseDate))
    setWarrantyExpDate(dayjs(data.WarrantyExpDate))
    setOpen(false);
  };

  return (
    <Box>
      <Tooltip title="Edit" fontSize="large">
        <IconButton onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>


      <Box>

        <Formik
          onSubmit={handleFormSubmit}
          initialValues={data}
          validationSchema={checkoutSchema}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Modal
              title={
                <StyleEditHeader>
                  <Header
                    title="VEHICLE"
                    subtitle="Edit Data a Vehicle"
                  />
                  <SwitchComponent
                    isSuspend={isSuspend}
                    handleSwitchChange={handleSwitchChange}
                  />
                </StyleEditHeader>
              }
              centered
              open={open}
              closable={false}
              style={{
                maxWidth: "90%",
                minWidth: "80%",
              }}
              bodyStyle={{
                maxHeight: "65vh",
                overflow: "auto",
              }}
              footer={() => (
                <ButtonUpdate
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  loading={loading}
                />
              )}
            >
              <StyleForm>

                <TextField
                  fullWidth
                  variant="outlined"
                  label="Facility Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.FacilityName}
                  name="FacilityName"
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  label="Vehicle Code*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.VehicleCode}
                  name="VehicleCode"
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Vehicle Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.VehicleName}
                  name="VehicleName"
                  error={!!touched.VehicleName && !!errors.VehicleName}
                  helperText={touched.VehicleName && errors.VehicleName}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <InputModal
                  title="TYPE"
                  label="Model Type*"
                  name="Type"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Type}
                  error={!!touched.Type && !!errors.Type}
                  helperText={touched.Type && errors.Type}
                  dataSource={dataType}
                  columns={columnsType}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectType(values)}
                  // propsOpenModal={(values) => setOpenModalType(values)}
                  LinktoCreate={`/master/type/form?moduleid=Vehicle`}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="No Pol*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.NoPol}
                  name="NoPol"
                  error={!!touched.NoPol && !!errors.NoPol}
                  helperText={touched.NoPol && errors.NoPol}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="AssetNo*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.AssetNo}
                  name="AssetNo"
                  error={!!touched.AssetNo && !!errors.AssetNo}
                  helperText={touched.AssetNo && errors.AssetNo}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Purchase Date*"
                    value={purchaseDate}
                    onBlur={handleBlur}
                    onChange={(date) => setPurchaseDate(date)}
                    name="PurchaseDate"
                    error={!!touched.PurchaseDate && !!errors.PurchaseDate}
                    helperText={touched.PurchaseDate && errors.PurchaseDate}
                    sx={{ gridColumn: "span 2" }}
                    readOnly={isSuspend}
                  />
                </LocalizationProvider>


                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Warranty Exp Date*"
                    value={warrantyExpDate}
                    onBlur={handleBlur}
                    onChange={(date) => setWarrantyExpDate(date)}
                    name="WarrantyExpDate"
                    error={!!touched.WarrantyExpDate && !!errors.WarrantyExpDate}
                    helperText={touched.WarrantyExpDate && errors.WarrantyExpDate}
                    sx={{ gridColumn: "span 2" }}
                    readOnly={isSuspend}
                  />
                </LocalizationProvider>

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Meter Reading*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.MeterReading}
                  name="MeterReading"
                  error={!!touched.MeterReading && !!errors.MeterReading}
                  helperText={touched.MeterReading && errors.MeterReading}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend
                  }}
                />

                <InputModal
                  title="EMPLOYEE"
                  label="Vehicle Owner"
                  name="VehicleOwner"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.VehicleOwner}
                  error={!!touched.VehicleOwner && !!errors.VehicleOwner}
                  helperText={touched.VehicleOwner && errors.VehicleOwner}
                  dataSource={dataVehicleOwner}
                  columns={columnsVehicleOwner}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectVehicleOwner(values)}
                  // propsOpenModal={(values) => setOpenModalVehicleOwner(values)}
                  LinktoCreate={`/employee/employee/form`}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 4" }}
                  multiline
                  rows={4}
                  InputProps={{
                    readOnly: isSuspend
                  }}
                />
              </StyleForm>

            </Modal>
          )}
        </Formik>
      </Box>
    </Box>
  );
};


const checkoutSchema = yup.object().shape({
  VehicleName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Vehicle Name"),
  Type: yup
    .string()
    .required("Please complete this Model Type"),
  NoPol: yup
    .string()
    .max(20, "Maximum of 20 characters allowed")
    .required("Please complete this No Pol"),
  AssetNo: yup
    .string()
    .max(30, "Maximum of 30 characters allowed")
    .required("Please complete this Asset No"),
  MeterReading: yup
    .string()
    .required("Please complete this Meter Reading"),
  VehicleOwner: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Vehicle Owner"),
});

export default EditVehicle;
