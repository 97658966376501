import React, { useEffect, useRef, useState } from "react";
import { Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ButtonForm } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { getDivision } from "../../../Api/Employee/GetData";
import { postDivision } from "../../../Api/Employee/PostData";

const FormDivision = () => {

  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [divisionCode, setDivisionCode] = useState("");

  const FetchCode = async () => {
    try {
      const response = await getDivision();
      if (response.length > 0) {
        const DVData = response.filter(
          (item) => item.DivisionCode && item.DivisionCode.startsWith("DV")
        );
        if (DVData.length > 0) {
          const lastCode = DVData[DVData.length - 1].DivisionCode;
          const nextNumber = parseInt(lastCode.substr(2)) + 1;
          setDivisionCode(`DV${nextNumber.toString().padStart(4, "0")}`);
        } else {
          setDivisionCode("DV0001");
        }
      } else {
        setDivisionCode("DV0001");
      }
    } catch (error) {
      setDivisionCode("DV0001");
      console.log(error);
    }
  };

  useEffect(() => {
    FetchCode();
  }, []);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("DivisionCode", divisionCode);
    }
  }, [divisionCode]);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const modifiedValues = {
        ...values,
        DivisionCode: divisionCode,
      };
      const response = await postDivision(modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/employee/division");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response);
    }
    setLoading(false);
  };

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={{ ...initialValues, DivisionCode: divisionCode }}
        validationSchema={checkoutSchema}
        innerRef={formRef}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <Box>
            <StyleContent>
              <Header title="DIVISION" subtitle="Create a Division" />
              <StyleForm>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Division Code*"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setDivisionCode(e.target.value);
                  }}
                  value={divisionCode}
                  name="DivisionCode"
                  error={!!touched.DivisionCode && !!errors.DivisionCode}
                  helperText={touched.DivisionCode && errors.DivisionCode}
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{ maxLength: 10 }}                  
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Division Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.DivisionName}
                  name="DivisionName"
                  error={!!touched.DivisionName && !!errors.DivisionName}
                  helperText={touched.DivisionName && errors.DivisionName}
                  sx={{ gridColumn: "span 2" }}
                  autoFocus
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 4" }}
                  multiline
                  rows={4}
                />
              </StyleForm>
            </StyleContent>

            <ButtonForm
              handleSubmit={handleSubmit}
              loading={loading}
            />
          </Box>
        )}
      </Formik>
    </>
  );
};

const checkoutSchema = yup.object().shape({
  DivisionCode: yup
    .string()
    .max(10, "Maximum of 10 characters allowed")
    .required("Please complete this Division Code."),
  DivisionName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Division Name."),
});
const initialValues = {
  DivisionName: "",
  Description: "",
};

export default FormDivision;
