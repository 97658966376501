import React, { useRef, useState } from "react";
import * as yup from "yup";
import {
  Box,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import { ButtonUpdate } from "../../global/components/ButtonAction";
import { Modal } from "antd";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { onlyNumber } from "../../global/Components";
import SwitchComponent from "../../../components/Global/SwitchSuspend";
import { updateFacility } from "../../../Api/Master/UpdateData";

const EditFacility = ({ data, onEdit }) => {
  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [isSuspend, setIsSuspend] = useState(false);

  const handleSwitchChange = (checked) => {
    setIsSuspend(checked);
    formRef.current.resetForm();
  };

  const handleOpen = () => {
    setOpen(true);
    setIsSuspend(data.IsSuspend);
  };

  const handleClose = () => {
    formRef.current.resetForm();
    setOpen(false);
    setIsSuspend(data.IsSuspend);
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const modifivalues = {
        ...values,
        IsSuspend: isSuspend,
      };
      const response = await updateFacility(data.FacilityCode, modifivalues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      onEdit(values.FacilityCode);
      setOpen(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to update.", { variant: "error" });
      }
      console.error("error code", error.response);
      formRef.current.resetForm();
      setOpen(false);
    }
    setLoading(false);
  };

  const handleKeyPress = (e) => {
    if (!onlyNumber.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Box>
      <Tooltip title="Edit" fontSize="large">
        <IconButton onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Box>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={data}
          validationSchema={checkoutSchema}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <Modal
              title={
                <StyleEditHeader>
                  <Header title="FACILITY" subtitle="Edit data a facility" />
                  <SwitchComponent
                    isSuspend={isSuspend}
                    handleSwitchChange={handleSwitchChange}
                  />
                </StyleEditHeader>
              }
              centered
              open={open}
              closable={false}
              style={{
                maxWidth: "90%",
                minWidth: "80%",
              }}
              bodyStyle={{
                maxHeight: "65vh",
                overflow: "auto",
              }}
              footer={() => (
                <ButtonUpdate
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  loading={loading}
                />
              )}
            >
              <StyleForm>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Facility Code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.FacilityCode}
                  name="FacilityCode"
                  error={!!touched.FacilityCode && !!errors.FacilityCode}
                  helperText={touched.FacilityCode && errors.FacilityCode}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Facility Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.FacilityName}
                  name="FacilityName"
                  error={!!touched.FacilityName && !!errors.FacilityName}
                  helperText={touched.FacilityName && errors.FacilityName}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Address*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Address}
                  name="Address"
                  error={!!touched.Address && !!errors.Address}
                  helperText={touched.Address && errors.Address}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="City*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.City}
                  name="City"
                  error={!!touched.City && !!errors.City}
                  helperText={touched.City && errors.City}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Postal Code*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.PostalCode}
                  name="PostalCode"
                  error={!!touched.PostalCode && !!errors.PostalCode}
                  helperText={touched.PostalCode && errors.PostalCode}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Country*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Country}
                  name="Country"
                  error={!!touched.Country && !!errors.Country}
                  helperText={touched.Country && errors.Country}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Phone Number 1*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Phone1}
                  name="Phone1"
                  error={!!touched.Phone1 && !!errors.Phone1}
                  helperText={touched.Phone1 && errors.Phone1}
                  sx={{ gridColumn: "span 2" }}
                  onKeyPress={handleKeyPress}
                  placeholder="Example: 081234567890"
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Phone Number 2*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Phone2}
                  name="Phone2"
                  error={!!touched.Phone2 && !!errors.Phone2}
                  helperText={touched.Phone2 && errors.Phone2}
                  sx={{ gridColumn: "span 2" }}
                  onKeyPress={handleKeyPress}
                  placeholder="Example: 081234567890"
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Fax*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Fax}
                  name="Fax"
                  error={!!touched.Fax && !!errors.Fax}
                  helperText={touched.Fax && errors.Fax}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Contact Person*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.ContactPerson}
                  name="ContactPerson"
                  error={!!touched.ContactPerson && !!errors.ContactPerson}
                  helperText={touched.ContactPerson && errors.ContactPerson}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 4" }}
                  multiline
                  rows={4}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />
              </StyleForm>
            </Modal>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  FacilityName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Facility Name."),
  Address: yup
    .string()
    .max(200, "Maximum of 200 characters allowed")
    .required("Please complete this Address."),
  City: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this City."),
  PostalCode: yup
    .string()
    .max(5, "Maximum of 5 characters allowed")
    .required("Please complete this Postal Code."),
  Country: yup
    .string()
    .max(30, "Maximum of 30 characters allowed")
    .required("Please complete this Country."),
  Phone1: yup
    .string()
    .max(14, "Maximum of 14 characters allowed")
    .required("Please complete this Phone Number 1."),
  Phone2: yup
    .string()
    .max(14, "Maximum of 14 characters allowed")
    .required("Please complete this Phone Number 2.")
    .test(
      "phone-not-same",
      "Phone Number 1 and Phone Number 2 should not be the same",
      function (value) {
        return value !== this.parent.Phone1;
      }
    ),
  Fax: yup
    .string()
    .max(15, "Maximum of 15 characters allowed")
    .required("Please complete this Fax."),
  ContactPerson: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Contact Person."),
});

export default EditFacility;
