import React, { useRef, useState } from "react";
import * as yup from "yup";
import { Box, IconButton, TextField, Tooltip } from "@mui/material";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import { ButtonUpdate } from "../../global/components/ButtonAction";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { Modal } from "antd";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import SwitchComponent from "../../../components/Global/SwitchSuspend";
import { updateSection } from "../../../Api/Employee/UpdateData";

const EditSection = ({ data, onEdit }) => {
  const dataEdit = data.Section;

  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [isSuspend, setIsSuspend] = useState(false);

  const handleSwitchChange = (checked) => {
    setIsSuspend(checked);
    formRef.current.resetForm();
  };

  const handleOpen = () => {
    setOpen(true);
    setIsSuspend(dataEdit.IsSuspend);
  };

  const handleClose = () => {
    formRef.current.resetForm();
    setIsSuspend(dataEdit.IsSuspend)
    setLoading(false);
    setOpen(false);
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const modifivalues = {
        ...values,
        IsSuspend: isSuspend,
      };
      const response = await updateSection(
        dataEdit.DivisionCode,
        dataEdit.DepCode,
        dataEdit.SectionCode,
        modifivalues
      );
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      onEdit(dataEdit.DivisionCode && dataEdit.DepCode && dataEdit.SectionCode);
      setOpen(false);
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data;
        enqueueSnackbar(errorMessage.msg, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to update.", { variant: "error" });
      }
      console.error("error code", error.response);
      formRef.current.resetForm();
      setOpen(false);
    }
    setLoading(false);
  };

  return (
    <Box>
      <Tooltip title="Edit" fontSize="large">
        <IconButton onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Box>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={dataEdit}
          validationSchema={checkoutSchema}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <Modal
              title={
                <StyleEditHeader>
                  <Header
                    title="SECTION"
                    subtitle="Edit Data a Section"
                  />
                  <SwitchComponent
                    isSuspend={isSuspend}
                    handleSwitchChange={handleSwitchChange}
                  />
                </StyleEditHeader>
              }
              centered
              open={open}
              closable={false}
              style={{
                maxWidth: "90%",
                minWidth: "80%",
              }}
              bodyStyle={{
                maxHeight: "65vh",
                overflow: "auto",
              }}
              footer={() => (
                <ButtonUpdate
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  loading={loading}
                />
              )}
            >
              <StyleForm>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Division Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={data.DivisionName}
                  name="DivisionCode"
                  error={!!touched.DivisionCode && !!errors.DivisionCode}
                  helperText={touched.DivisionCode && errors.DivisionCode}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Department Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={data.DepartmentName}
                  name="DepCode"
                  error={!!touched.DepCode && !!errors.DepCode}
                  helperText={touched.DepCode && errors.DepCode}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Section Code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.SectionCode}
                  name="SectionCode"
                  error={!!touched.SectionCode && !!errors.SectionCode}
                  helperText={touched.SectionCode && errors.SectionCode}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Section Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.SectionName}
                  name="SectionName"
                  error={!!touched.SectionName && !!errors.SectionName}
                  helperText={touched.SectionName && errors.SectionName}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 4" }}
                  multiline
                  rows={4}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />
              </StyleForm>
            </Modal>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  SectionName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Section Name."),
});

export default EditSection;
