export const columnsCategory = [
    {
        title: 'Category Code',
        dataIndex: 'CatCode',
        fixed: 'left',
        sorter: (a, b) => a.CatCode.localeCompare(b.CatCode),
    },
    {
        title: 'Category Name',
        dataIndex: 'CatName',
        sorter: (a, b) => a.CatName.localeCompare(b.CatName),
    },
    {
        title: 'Module ID',
        dataIndex: 'ModuleID',
        sorter: (a, b) => a.ModuleID.localeCompare(b.ModuleID),
    }
]