import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import {
    Autocomplete,
    Box,
    IconButton,
    TextField,
    Tooltip,
} from "@mui/material";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import { ButtonUpdate } from "../../global/components/ButtonAction";
import { Modal } from "antd";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { onlyNumber } from "../../global/Components";
import SwitchComponent from "../../../components/Global/SwitchSuspend";
import { Link } from "react-router-dom";
import { getCategory, getUnits } from "../../../Api/Master/GetData";
import { updatePart } from "../../../Api/Master/UpdateData";

const EditPart = ({ data, onEdit }) => {

    const dataEdit = data.Part;

    const formRef = useRef(null);
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [isSuspend, setIsSuspend] = useState(false);

    const [Category, setCategory] = useState([]);
    const [SelectedCategory, setSelectedCategory] = useState(dataEdit.PartCategory);

    const [Unit, setUnit] = useState([]);
    const [SelectedUnit, setSelectedUnit] = useState(dataEdit.UnitCode);

    useEffect(() => {
        if (open) {
            const fetchDataCategory = async () => {
                setIsLoading(true);
                try {
                    const response = await getCategory("Part");
                    const filterRes = response.filter((item) => item.IsSuspend !== true);
                    setCategory(filterRes);
                } catch (error) {
                    console.log(error);
                }
                setIsLoading(false);
            };

            fetchDataCategory();
        }
    }, [open]);

    useEffect(() => {
        if (open) {
            const fetchDataUnit = async () => {
                setIsLoading(true);
                try {
                    const response = await getUnits();
                    setUnit(response);
                } catch (error) {
                    console.log(error);
                }
                setIsLoading(false);
            }

            fetchDataUnit();
        }
    }, [open]);

    const handleCategorySelection = (event, value) => {
        if (value) {
            setSelectedCategory(value.CatCode);
        } else {
            setSelectedCategory(null);
        }
    };

    const handleUnitSelection = (event, value) => {
        if (value) {
            setSelectedUnit(value.UnitCode);
        } else {
            setSelectedUnit(null);
        }
    };

    const handleSwitchChange = (checked) => {
        setIsSuspend(checked);
        formRef.current.resetForm();
    };

    const handleOpen = () => {
        setOpen(true);
        setIsSuspend(dataEdit.IsSuspend);
    };

    const handleClose = () => {
        formRef.current.resetForm();
        setOpen(false);
        setIsSuspend(dataEdit.IsSuspend);
    };

    const handleFormSubmit = async (values) => {
        setLoading(true);
        try {
            const modifiedValues = {
                ...values,
                IsSuspend: isSuspend,
                PartCategory: SelectedCategory,
                UnitCode: SelectedUnit,
            };
            const response = await updatePart(dataEdit.PartCode, modifiedValues);
            enqueueSnackbar(response.data.statusMessage, { variant: "success" });
            onEdit(dataEdit.PartCode);
            setOpen(false);
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.statusMessage
            ) {
                const errorMessage = error.response.data.statusMessage;
                enqueueSnackbar(errorMessage, { variant: "error" });
            } else {
                enqueueSnackbar("Data failed to update.", { variant: "error" });
            }
            console.error("error code", error.response);
            formRef.current.resetForm();
            setOpen(false);
        }
        setLoading(false);
    };

    const handleKeyPress = (e) => {
        if (!onlyNumber.test(e.key)) {
            e.preventDefault();
        }
    };

    return (
        <Box>
            <Tooltip title="Edit" fontSize="large">
                <IconButton onClick={handleOpen}>
                    <EditIcon />
                </IconButton>
            </Tooltip>

            <Box>
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={dataEdit}
                    validationSchema={checkoutSchema}
                    innerRef={formRef}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                    }) => (
                        <Modal
                            title={
                                <StyleEditHeader>
                                    <Header title="Part" subtitle="Edit data a Part" />
                                    <SwitchComponent
                                        isSuspend={isSuspend}
                                        handleSwitchChange={handleSwitchChange}
                                    />
                                </StyleEditHeader>
                            }
                            centered
                            open={open}
                            closable={false}
                            style={{
                                maxWidth: "90%",
                                minWidth: "80%",
                            }}
                            bodyStyle={{
                                maxHeight: "65vh",
                                overflow: "auto",
                            }}
                            footer={() => (
                                <ButtonUpdate
                                    handleClose={handleClose}
                                    handleSubmit={handleSubmit}
                                    loading={loading}
                                />
                            )}
                        >
                            <StyleForm>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Part Code"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.PartCode}
                                    name="PartCode"
                                    error={!!touched.PartCode && !!errors.PartCode}
                                    helperText={touched.PartCode && errors.PartCode}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Part Name*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.PartName}
                                    name="PartName"
                                    error={!!touched.PartName && !!errors.PartName}
                                    helperText={touched.PartName && errors.PartName}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: isSuspend,
                                    }}
                                />

                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    loading={isLoading}
                                    options={Category}
                                    getOptionLabel={(option) => option.CatName}
                                    sx={{ gridColumn: "span 2" }}
                                    onChange={(event, value) => {
                                        handleCategorySelection(event, value);
                                        setFieldValue("PartCategory", value ? value.CatName : "");
                                    }}
                                    value={
                                        Category.find(
                                            (option) => option.CatCode === SelectedCategory
                                        ) || null
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Part Category*"
                                            onBlur={handleBlur}
                                            error={!!touched.PartCategory && !!errors.PartCategory}
                                            helperText={touched.PartCategory && errors.PartCategory}
                                        />
                                    )}
                                    noOptionsText={
                                        Category.length === 0 ? (
                                            <Link
                                                to="/master/category/form?moduleid=Part"
                                                target="_blank"
                                                style={{ textDecoration: "none" }}
                                            >
                                                there is no option, please fill in the category part data
                                            </Link>
                                        ) : (
                                            "No options"
                                        )
                                    }
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Minimum Stock*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.MinStock}
                                    name="MinStock"
                                    error={!!touched.MinStock && !!errors.MinStock}
                                    helperText={touched.MinStock && errors.MinStock}
                                    sx={{ gridColumn: "span 2" }}
                                    onKeyPress={handleKeyPress}
                                />

                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    loading={isLoading}
                                    options={Unit}
                                    getOptionLabel={(option) => option.UnitName}
                                    sx={{ gridColumn: "span 2" }}
                                    onChange={(event, value) => {
                                        handleUnitSelection(event, value);
                                        setFieldValue("UnitCode", value ? value.UnitName : "");
                                    }}
                                    value={
                                        Unit.find(
                                            (option) => option.UnitCode === SelectedUnit
                                        ) || null
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Unit Name*"
                                            onBlur={handleBlur}
                                            error={!!touched.UnitCode && !!errors.UnitCode}
                                            helperText={touched.UnitCode && errors.UnitCode}
                                        />
                                    )}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Description"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.Description}
                                    name="Description"
                                    sx={{ gridColumn: "span 2" }}
                                    multiline
                                    rows={4}
                                    InputProps={{
                                        readOnly: isSuspend,
                                    }}
                                />
                            </StyleForm>
                        </Modal>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

const checkoutSchema = yup.object().shape({
    PartName: yup
        .string()
        .max(50, "Maximum of 50 characters allowed")
        .required("Please complete this Part Name."),
});

export default EditPart;
