import { useSidebarContext } from '../Sidebar/SidebarContext';
import { useSelector } from 'react-redux';

export const AddAuthorityIdx = () => {
    const { authIdx } = useSidebarContext();
    const { user } = useSelector((state) => state.auth);
    const AddAuthIdx = user && user.AddAuthority.filter(item => item === authIdx).join(',')
    return AddAuthIdx
}

export const EditAuthorityIdx = () => {
    const { authIdx } = useSidebarContext();
    const { user } = useSelector((state) => state.auth);
    const EditAuthIdx = user && user.EditAuthority.filter(item => item === authIdx).join(',')
    return EditAuthIdx
}

export const DeleteAuthorityIdx = () => {
    const { authIdx } = useSidebarContext();
    const { user } = useSelector((state) => state.auth);
    const DeleteAuthIdx = user && user.DelAuthority.filter(item => item === authIdx).join(',')
    return DeleteAuthIdx
}

export const PrintAuthorityIdx = () => {
    const { authIdx } = useSidebarContext();
    const { user } = useSelector((state) => state.auth);
    const PrintAuthIdx = user && user.PrnAuthority.filter(item => item === authIdx).join(',')
    return PrintAuthIdx
}

export const ProcessAuthorityIdx = () => {
    const { authIdx } = useSidebarContext();
    const { user } = useSelector((state) => state.auth);
    const ProcessAuthIdx = user && user.PrsAuthority.filter(item => item === authIdx).join(',')
    return ProcessAuthIdx
}

export const FacilityAuthority = () => {
    const { user } = useSelector((state) => state.auth);
    const FacilityAuth = user && user.FacilityAuthority.join(',')
    return FacilityAuth
}

export const BuildingAuthority = () => {
    const { user } = useSelector((state) => state.auth);
    const BuildingAuth = user && user.BuildingAuthority.join(',')
    return BuildingAuth
}