import React, { useState, useEffect, useCallback } from "react";
import Header from "../../../components/Header";
import { Table, Tooltip } from "antd";
import EditEquipment from "./edit";
import DeleteEquipment from "./delete";
import { customExpandIcon } from "../../global/customTable";
import SearchData from "../../global/components/FeatureTable/SearchData";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Typography } from "@mui/material";
import { getEquipment } from "../../../Api/Master/GetData";
import {
  AddAuthorityIdx,
  // BuildingAuthority,
  DeleteAuthorityIdx,
  EditAuthorityIdx,
  FacilityAuthority,
  PrintAuthorityIdx
} from "../../../components/Global/Authority";


const Equipment = () => {
  const myAddAuthIdx = AddAuthorityIdx();
  const myEditAuthIdx = EditAuthorityIdx();
  const myDeleteAuthIdx = DeleteAuthorityIdx();
  const myPrintAuthIdx = PrintAuthorityIdx();
  const myFacilityAuth = FacilityAuthority();
  // const myBuildingAuth = BuildingAuthority();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const response = await getEquipment(myFacilityAuth);
      setData(response);
    } catch (error) {
      //console.log(error);
      if (error.response && error.response.status === 404) {
        setData([]);
      }
    }
  }, [myFacilityAuth]);

  useEffect(() => {
    fetchData();
    setIsLoading(false);
  }, [fetchData]);

  const columns = [
    {
      key: 'key',
      title: 'No',
      dataIndex: 'key',
      width: 80,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'Equipment Code',
      dataIndex: ['Equipment', 'EquipmentCode'],
      fixed: 'left',
      sorter: (a, b) => a.Equipment.EquipmentCode.localeCompare(b.Equipment.EquipmentCode),
    },
    {
      title: 'Equipment Name',
      dataIndex: ['Equipment', 'EquipmentName'],
      sorter: (a, b) => a.Equipment.EquipmentName.localeCompare(b.Equipment.EquipmentName),
    },
    {
      title: 'Facility Name',
      dataIndex: 'FacilityName',
      sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
      title: 'Building Name',
      dataIndex: 'BuildingName',
      sorter: (a, b) => a.BuildingName.localeCompare(b.BuildingName),
    },
    {
      title: 'Floor Level Name',
      dataIndex: 'FloorLevelName',
      sorter: (a, b) => a.FloorLevelName.localeCompare(b.FloorLevelName),
    },
    {
      title: 'Model Name',
      dataIndex: 'EquipmentModelName',
      sorter: (a, b) => a.EquipmentModelName.localeCompare(b.EquipmentModelName),
    },
    {
      title: 'Location Name',
      dataIndex: 'LocationName',
      sorter: (a, b) => a.LocationName.localeCompare(b.LocationName),
    },
    {
      title: 'SN',
      dataIndex: ['Equipment', 'SN'],
      sorter: (a, b) => a.Equipment.SN.localeCompare(b.Equipment.SN),
    },
    {
      title: 'Asset No',
      dataIndex: ['Equipment', 'AssetNo'],
      sorter: (a, b) => a.Equipment.AssetNo.localeCompare(b.Equipment.AssetNo),
    },
    {
      title: 'Install Date',
      dataIndex: ['Equipment', 'InstallDate'],
      sorter: (a, b) => a.Equipment.InstallDate.localeCompare(b.Equipment.InstallDate),
    },
    {
      title: 'Purchase Date',
      dataIndex: ['Equipment', 'PurchaseDate'],
      sorter: (a, b) => a.Equipment.PurchaseDate.localeCompare(b.Equipment.PurchaseDate),
    },
    {
      title: 'Warranty Exp Date',
      dataIndex: ['Equipment', 'WarrantyExpDate'],
      sorter: (a, b) => a.Equipment.WarrantyExpDate.localeCompare(b.Equipment.WarrantyExpDate),
    },
    {
      title: 'Meter Reading',
      dataIndex: ['Equipment', 'MeterReading'],
      sorter: (a, b) => a.Equipment.MeterReading.localeCompare(b.Equipment.MeterReading),
    },
    {
      title: 'Equipment Owner',
      dataIndex: 'EmployeeName',
      sorter: (a, b) => a.EmployeeName.localeCompare(b.EmployeeName),
    },
    {
      title: 'Map Name',
      dataIndex: 'MapName',
      sorter: (a, b) => a.MapName.localeCompare(b.MapName),
    },
    {
      title: 'Active',
      dataIndex: ['Equipment', 'IsSuspend'],
      width: 120,
      render: (isSuspend) => (
        <Box display="flex" justifyContent="center" >
          {
            isSuspend ? (
              <Tooltip title="Inactive">
                <CancelIcon color="error" />
              </Tooltip>
            ) : (
              <Tooltip title="Active" >
                <CheckCircleIcon color="success" />
              </Tooltip >
            )}
        </Box>
      )
    },
  ];

  if (myEditAuthIdx || myDeleteAuthIdx) {
    columns.push({
      title: 'Action',
      key: 'Action',
      fixed: 'right',
      width: 120,
      render: (_, record) => (
        <Box display="flex" justifyContent="center" >
          {myEditAuthIdx && (
            <EditEquipment data={record} onEdit={fetchData} />
          )}

          {myDeleteAuthIdx && !record.Equipment.IsSuspend && (
            <DeleteEquipment data={record} onDelete={fetchData} />
          )}
        </Box>
      ),
    },)
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some(
      (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      // console.log(selectedRows);
    },
  };

  const columnsExport = columns
    .filter((item) => item.key !== "Action" && item.key !== "key")
    .map((item) => {

      if (item.title === "Active") {
        return {
          title: item.title,
          key: item.dataIndex,
          render: (record) => (record.Equipment.IsSuspend ? "Inactive" : "Active"),
        };
      }

      return {
        title: item.title,
        key: item.dataIndex,
      };
    });

  const processExportData = (data) =>
    data.map((item) => ({
      ...item,
      Equipment: {
        ...item.Equipment,
        IsSuspend: item.Equipment.IsSuspend ? "Inactive" : "Active",
      }
    }));

  return (
    <StyleContent>
      <StyleGridHeader>
        <Header title="EQUIPMENT" subtitle="All Equipment Data" />

        <Box display="flex" justifyContent="end" gap="8px">
          {myPrintAuthIdx && (
            <ExportData
              data={processExportData(selectedRows.length > 0 ? selectedRows : data)}
              columns={columnsExport}
              name="Equipment"
            />
          )}

          {myAddAuthIdx && (
            <ButtonAddNew
              link="form"
            />
          )}
        </Box>

      </StyleGridHeader>

      <SearchData
        filterValue={searchText}
        handleFilterChange={handleSearch}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filteredData}
        rowSelection={data.length === 0 ? null : rowSelection}
        expandable={data.length === 0 ? null : {
          expandedRowRender: (record) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Typography variant="h5" fontWeight={600}>Description : </Typography>
              <Typography>{record.Equipment.Description}</Typography>
            </div>
          ),
          rowExpandable: (record) => record.Equipment.Description !== '',
          expandIcon: customExpandIcon,
        }}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: 'max-content' }}
      />

    </StyleContent>
  );
};

export default Equipment;
