import React, { useEffect, useRef, useState } from "react";
import { Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ButtonForm } from "../../global/components/ButtonAction";
import UploadImage from "../../global/components/UploadImage";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { getCategory, getEquipmentModel, getIcon, getSubCategory, getType } from "../../../Api/Master/GetData";
import { postEquipmentModel } from "../../../Api/Master/PostData";
import InputModal from "../../../components/Global/InputModal";
import { Image } from "antd";
import { onlyNumber } from "../../global/Components";

const FormEquipmentModel = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [modelCode, setModelCode] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  // const [imageUrl, setImageUrl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const [dataType, setDataType] = useState([]);
  const [selectType, setSelectType] = useState("");
  const [openModalType, setOpenModalType] = useState("");
  const TypeCode = selectType ? selectType.TypeCode : '';
  const TypeName = selectType ? selectType.TypeName : '';

  const [dataCategory, setDataCategory] = useState([]);
  const [selectCategory, setSelectCategory] = useState(null);
  const [openModalCategory, setOpenModalCategory] = useState("");
  const CategoryName = selectCategory ? selectCategory.CatName : '';
  const CategoryCode = selectCategory ? selectCategory.CatCode : '';

  const [dataSubCategory, setDataSubCategory] = useState([]);
  const [selectSubCategory, setSelectSubCategory] = useState(null);
  const [openModalSubCategory, setOpenModalSubCategory] = useState("");
  const SubCategoryCode = selectSubCategory ? selectSubCategory.SubCategory.SubCatCode : '';
  const SubCategoryName = selectSubCategory ? selectSubCategory.SubCategory.SubCatName : '';

  const [dataIcon, setDataIcon] = useState([]);
  const [selectIcon, setSelectIcon] = useState(null);
  const [openModalIcon, setOpenModalIcon] = useState("");
  const IconId = selectIcon ? selectIcon.IconId : '';
  const IconName = selectIcon ? selectIcon.IconName : '';

  // DATA TYPE
  useEffect(() => {
    const fetchType = async () => {
      try {
        const response = await getType('Equipment', null, 'false');
        setDataType(response);
      } catch (error) {
        console.log(error);
      }
    };

    if (openModalType) {
      fetchType();
      setOpenModalType(false);
      setIsLoading(false);
    }
  }, [openModalType]);

  // DATA CATEGORY
  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await getCategory('Equipment', 'false');
        setDataCategory(response);
      } catch (error) {
        console.log(error);
      }
    };

    if (openModalCategory) {
      fetchCategory();
      setIsLoading(false);
      setOpenModalCategory(false);
      setDataSubCategory([]);
      setSelectSubCategory(null);
    }
  }, [openModalCategory]);

  // DATA SUBCATEGORY
  useEffect(() => {
    if (CategoryCode) {
      const fetchDataSubCategory = async () => {
        try {
          const response = await getSubCategory('Equipment', CategoryCode, null, 'false');
          setDataSubCategory(response);
        } catch (error) {
          console.log(error);
        }
      };

      if (openModalSubCategory) {
        setIsLoading(false);
        fetchDataSubCategory();
        setOpenModalSubCategory(false);
      }
    }
  }, [openModalSubCategory, CategoryCode]);


  // DATA ICON
  useEffect(() => {
    const fetchIcon = async () => {
      try {
        const response = await getIcon('false');
        setDataIcon(response);
      } catch (error) {
        console.log(error);
      }
    }
    if (openModalIcon) {
      fetchIcon();
      setIsLoading(false);
      setOpenModalIcon(false);
    }
  }, [openModalIcon]);

  const fetchEquipmentModelCode = async () => {
    try {
      const response = await getEquipmentModel();
      if (response.length > 0) {
        const EMData = response.filter(
          (item) => item.ModelCode && item.ModelCode.startsWith("EM")
        );
        if (EMData.length > 0) {
          const lastCode = EMData[EMData.length - 1].ModelCode;
          const nextNumber = parseInt(lastCode.substr(2)) + 1;
          setModelCode(`EM${nextNumber.toString().padStart(4, "0")}`);
        }
      }
    } catch (error) {
      setModelCode("EM0001");
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEquipmentModelCode();
  }, []);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("TypeName", TypeName);
      formRef.current.setFieldValue("CategoryName", CategoryName);
      formRef.current.setFieldValue("SubCategoryName", SubCategoryName);
      formRef.current.setFieldValue("ModelCode", modelCode);
      formRef.current.setFieldValue("IconName", IconName);
    }
  }, [SubCategoryName, IconName, TypeName, modelCode, CategoryName]);

  const handleFormSubmit = async (values) => {
    setLoading(true);

    if (!selectedFile) {
      enqueueSnackbar("Please upload an image.", { variant: "error" });
      setLoading(false);
      return;
    }

    try {
      const payload = {
        ...values,
        ModelCode: modelCode,
        imgs: selectedFile,
        IconId: IconId,
        ModelTypeCode: TypeCode,
        WorkCatCode: CategoryCode,
        SubCatCode: SubCategoryCode,
      };

      const response = await postEquipmentModel(payload);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/master/equipmentmodel");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response);
    }
    setLoading(false);
  };

  const handleKeyPress = (e) => {
    if (!onlyNumber.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={{ ...initialValues, ModelCode: modelCode }}
        validationSchema={checkoutSchema}
        innerRef={formRef}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Box>
            <StyleContent>
              <Header
                title="FORM EQUIPMENT MODEL"
                subtitle="Create a Equipment Model Code"
              />

              <StyleForm>
                {/* Upload Image */}
                <UploadImage files={(files) => setSelectedFile(files)} />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Equipment Model Code*"
                  onBlur={handleBlur}
                  onChange={(e) => setModelCode(e.target.value)}
                  value={modelCode}
                  name="ModelCode"
                  error={!!touched.ModelCode && !!errors.ModelCode}
                  helperText={touched.ModelCode && errors.ModelCode}
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{ maxLength: 10 }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Equipment Model Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.ModelName}
                  name="ModelName"
                  error={!!touched.ModelName && !!errors.ModelName}
                  helperText={touched.ModelName && errors.ModelName}
                  sx={{ gridColumn: "span 2" }}
                  autoFocus
                />

                <InputModal
                  title="TYPE"
                  label="Type Name"
                  name="TypeName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.TypeName}
                  error={!!touched.TypeName && !!errors.TypeName}
                  helperText={touched.TypeName && errors.TypeName}
                  dataSource={dataType}
                  columns={columnsType}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectType(values)}
                  propsOpenModal={(values) => setOpenModalType(values)}
                  LinktoCreate={`/master/type/form?moduleid=Equipment`}
                />

                <InputModal
                  title="CATEGORY"
                  label="Category Name"
                  name="CategoryName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.CategoryName}
                  error={!!touched.CategoryName && !!errors.CategoryName}
                  helperText={touched.CategoryName && errors.CategoryName}
                  dataSource={dataCategory}
                  columns={columnsCategory}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectCategory(values)}
                  propsOpenModal={(values) => setOpenModalCategory(values)}
                  LinktoCreate={`/master/category/form?moduleid=Equipment`}
                />

                <InputModal
                  title="SUBCATEGORY"
                  label="SubCategory Name"
                  name="SubCategoryName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.SubCategoryName}
                  error={!!touched.SubCategoryName && !!errors.SubCategoryName}
                  helperText={touched.SubCategoryName && errors.SubCategoryName}
                  dataSource={dataSubCategory}
                  columns={columnsSubCategory}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectSubCategory(values)}
                  propsOpenModal={(values) => setOpenModalSubCategory(values)}
                  LinktoCreate={`/master/subcategory/form?moduleid=Equipment&category=${CategoryCode}`}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Manufacturer*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Manufacturer}
                  name="Manufacturer"
                  error={!!touched.Manufacturer && !!errors.Manufacturer}
                  helperText={touched.Manufacturer && errors.Manufacturer}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Meter Reading Unit*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.MeterReadingUnit}
                  name="MeterReadingUnit"
                  error={
                    !!touched.MeterReadingUnit && !!errors.MeterReadingUnit
                  }
                  helperText={
                    touched.MeterReadingUnit && errors.MeterReadingUnit
                  }
                  sx={{ gridColumn: "span 2" }}
                  onKeyPress={handleKeyPress}
                />

                <InputModal
                  title="ICON"
                  label="Icon Name"
                  name="IconName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.IconName}
                  error={!!touched.IconName && !!errors.IconName}
                  helperText={touched.IconName && errors.IconName}
                  dataSource={dataIcon}
                  columns={columnsIcon}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectIcon(values)}
                  propsOpenModal={(values) => setOpenModalIcon(values)}
                  LinktoCreate={`/master/icon/form`}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 4" }}
                  multiline
                  rows={4}
                />
              </StyleForm>
            </StyleContent>

            <ButtonForm
              handleSubmit={handleSubmit}
              loading={loading}
            />
          </Box>
        )}
      </Formik>
    </>
  );
};

const checkoutSchema = yup.object().shape({
  ModelCode: yup
    .string()
    .max(10, "Maximum of 10 characters allowed")
    .required("Please complete this Model Code."),
  ModelName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Model Name."),
  TypeName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Model Type Name."),
  CategoryName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Work Category Name."),
  SubCategoryName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this SubCategory Name."),
  Manufacturer: yup
    .string()
    .max(10, "Maximum of 10 characters allowed")
    .required("Please complete this Manufacturer."),
  MeterReadingUnit: yup
    .string()
    .max(10, "Maximum of 10 characters allowed")
    .required("Please complete this MeterReading Unit."),
  IconName: yup
    .string()
    .required("Please complete this Icon Name."),
});

const initialValues = {
  ModelCode: "",
  ModelName: "",
  TypeName: "",
  CategoryName: "",
  SubCategoryName: "",
  Manufacturer: "",
  MeterReadingUnit: "",
  IconName: "",
  Description: "",
};

export default FormEquipmentModel;


const columnsIcon = [
  {
    key: 'key',
    title: 'No',
    dataIndex: 'key',
    width: 80,
    fixed: 'left',
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: 'Image',
    dataIndex: 'Image',
    render: (params) =>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Image
          style={{ maxWidth: 100, maxHeight: "100%", objectFit: "contain" }}
          src={`${process.env.REACT_APP_BASEURL_IMG}/${params}`}
        />
      </div>
  },
  {
    title: 'Icon Id',
    dataIndex: 'IconId',
    sorter: (a, b) => a.IconId.localeCompare(b.IconId),
  },
  {
    title: 'Icon Name',
    dataIndex: 'IconName',
    sorter: (a, b) => a.IconName.localeCompare(b.IconName),
  },
];


const columnsType = [
  {
    key: 'key',
    title: 'No',
    dataIndex: 'key',
    width: 80,
    fixed: 'left',
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: 'Type Code',
    dataIndex: 'TypeCode',
    width: 150,
    fixed: 'left',
    sorter: (a, b) => a.TypeCode.localeCompare(b.TypeCode),
  },
  {
    title: 'Type Name',
    dataIndex: 'TypeName',
    sorter: (a, b) => a.TypeName.localeCompare(b.TypeName),
  },
  {
    title: 'Module ID',
    dataIndex: 'ModuleID',
    sorter: (a, b) => a.ModuleID.localeCompare(b.ModuleID),
  },
];


const columnsCategory = [
  {
    key: 'key',
    title: 'No',
    dataIndex: 'key',
    width: 80,
    fixed: 'left',
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: 'Category Code',
    dataIndex: 'CatCode',
    fixed: 'left',
    sorter: (a, b) => a.CatCode.localeCompare(b.CatCode),
  },
  {
    title: 'Category Name',
    dataIndex: 'CatName',
    sorter: (a, b) => a.CatName.localeCompare(b.CatName),
  },
  {
    title: 'Module ID',
    dataIndex: 'ModuleID',
    sorter: (a, b) => a.ModuleID.localeCompare(b.ModuleID),
  },
];


const columnsSubCategory = [

  {
    key: 'key',
    title: 'No',
    dataIndex: 'key',
    width: 80,
    fixed: 'left',
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: 'Module ID',
    dataIndex: ['SubCategory', 'ModuleID'],
    width: 200,
    sorter: (a, b) => a.SubCategory.ModuleID.localeCompare(b.SubCategory.ModuleID),
  },
  {
    title: 'Category Name',
    dataIndex: 'CatName',
    width: 200,
    sorter: (a, b) => a.CatName.localeCompare(b.CatName),
  },
  {
    title: 'SubCategory Code',
    dataIndex: ['SubCategory', 'SubCatCode'],
    width: 200,
    sorter: (a, b) => a.SubCategory.SubCatCode.localeCompare(b.SubCategory.SubCatCode),
  },
  {
    title: 'SubCategory Name',
    dataIndex: ['SubCategory', 'SubCatName'],
    width: 200,
    sorter: (a, b) => a.SubCategory.SubCatName.localeCompare(b.SubCategory.SubCatName),
  },
];
