import React, { useEffect, useState } from 'react'
import StyleContent from '../../components/Global/StyleContent'
import StyleGridHeader from '../../components/Global/StyleContent/StyleGrid/StyleGridHeader'
import Header from '../../components/Header'
import SearchData from '../global/components/FeatureTable/SearchData'
import { Table } from 'antd'
import { Link, useParams } from 'react-router-dom'
import { getWorkTaskDetail } from '../../Api/Report/GetData'

const WorkTaskDetail = () => {

    const { faccode, refcode } = useParams();

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getWorkTaskDetail(faccode, refcode);
                setData(response);
                console.log("Data", response);
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    setData([]);
                }
            }
        };

        fetchData();
        setIsLoading(false);
    }, [faccode, refcode]);

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = data.filter((item) =>
        Object.values(item).some(
            (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    const columns = [
        {
            title: 'No',
            dataIndex: 'key',
            width: 100,
            fixed: 'left',
            sorter: (a, b) => a.key - b.key,
        },
        {
            title: 'Tran Number',
            dataIndex: 'TranName',
            sorter: (a, b) => a.TranName.localeCompare(b.TranName),
            render: (value, record) => {
                const tranType = record.TranName
                    ? (record.TranName.startsWith("WOR") && 'worequest') ||
                    (record.TranName.startsWith("WO") && 'workorder') ||
                    (record.TranName.startsWith("DM") && 'demand') ||
                    (record.TranName.startsWith("PM") && 'pmconfiguration') ||
                    (record.TranName.startsWith("MP") && 'maintenanceprocess')
                    : '';

                return (
                    <Link
                        to={
                            tranType
                                ? `/transaction/${tranType}/form?action=view&faccode=${faccode}&trannumber=${record.TranName}`
                                : '#'
                        }
                    >
                        <p style={{ fontWeight: 'bold' }}>{value}</p>
                    </Link>
                );
            },
        },        
        {
            title: 'Facility Name',
            dataIndex: 'FacilityName',
            sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
        },
        {
            title: 'Type',
            dataIndex: 'TranType',
            sorter: (a, b) => a.TranType.localeCompare(b.TranType),
        },
        {
            title: 'Type Ref Name',
            dataIndex: 'TranTypeRefName',
            sorter: (a, b) => a.TranTypeRefName.localeCompare(b.TranTypeRefName),
        },
        {
            title: 'Icon Name',
            dataIndex: 'IconName',
            sorter: (a, b) => a.IconName.localeCompare(b.IconName),
        },
        {
            title: 'Map Name',
            dataIndex: 'MapName',
            sorter: (a, b) => a.MapName.localeCompare(b.MapName),
        },
        {
            title: 'Status',
            dataIndex: 'ProgressStatus',
            sorter: (a, b) => a.ProgressStatus.localeCompare(b.ProgressStatus),
        },
    ];

    return (
        <StyleContent>
            <StyleGridHeader>
                <Header title={`WORK TASK DETAIL`} subtitle={`All data a Work Task Detail`} />
            </StyleGridHeader>

            <SearchData
                filterValue={searchText}
                handleFilterChange={handleSearch}
            />

            <Table
                loading={isLoading}
                columns={columns}
                dataSource={filteredData}
                pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                scroll={{ x: 1500 }}
            />
        </StyleContent>
    )
}

export default WorkTaskDetail