import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box } from "@mui/material";
import { Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import StyleContent from "../../../components/Global/StyleContent";
import { getVehicleHistory } from "../../../Api/Report/GetData";
import dayjs from "dayjs";

const VehicleHistory = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const [searchText, setSearchText] = useState('');

  const fetchData = async () => {
    try {
      const response = await getVehicleHistory();
      setData(response);
    } catch (error) {
      console.log(error.response.data);
    }
  };

  useEffect(() => {
    fetchData();
    setIsLoading(false);
  }, []);


  const columns = [
    {
      title: 'No',
      dataIndex: 'key',
      width: 100,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'Vehicle',
      dataIndex: 'AssetName',
      fixed: 'left',
      sorter: (a, b) => a.AssetName.localeCompare(b.AssetName),
    },
    {
      title: 'Asset Owner',
      dataIndex: 'AssetOwner',
      sorter: (a, b) => a.AssetOwner.localeCompare(b.AssetOwner),
    },
    {
      title: 'Tran Date',
      dataIndex: 'TranDate',
      sorter: (a, b) => a.TranDate.localeCompare(b.TranDate),
      render: (value) => <p>{dayjs(value).format("YYYY-MM-DD")}</p>
    },
    {
      title: 'Tran Number',
      dataIndex: 'TranNumber',
      sorter: (a, b) => a.TranNumber.localeCompare(b.TranNumber),
    },
    {
      title: 'Part Name',
      dataIndex: 'PartName',
      sorter: (a, b) => a.PartName.localeCompare(b.PartName),
    },
    {
      title: 'Quantity Part',
      dataIndex: 'Quantity',
      sorter: (a, b) => a.Quantity.localeCompare(b.Quantity),
    },
  ];

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some((val) =>
      val && val.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  return (
    <StyleContent>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Header
          title="VEHICLE HISTORY"
          subtitle="All Vehicle History"
        />
      </Box>

      <SearchData
        filterValue={searchText}
        handleFilterChange={handleSearch}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filteredData}
        // rowSelection={data.length === 0 ? null : rowSelection}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: 'max-content' }}
      />

    </StyleContent>
  );
};

export default VehicleHistory