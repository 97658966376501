import axios from "axios";
import dayjs from "dayjs";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_BASEURL;

const StartDate = dayjs().startOf("year").format("YYYY-MM-DD");
const EndDate = dayjs().endOf("year").format("YYYY-MM-DD");

export const getPMSchedule = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/historypmschedule`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getWorkTaskDetail = async (FacilityCode, RefCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/worktaskdetail?FacilityCode=${FacilityCode}&RefCode=${RefCode}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getEquipmentHistory = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/equipmenthist?StartDate=${StartDate}&EndDate=${EndDate}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getVehicleHistory = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/vehiclehist?StartDate=${StartDate}&EndDate=${EndDate}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getLocationHistory = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/locationhist?StartDate=${StartDate}&EndDate=${EndDate}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getWORStatus = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/statuswor`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getWOStatus = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/statuswo`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getDMStatus = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/statusdm`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getPMStatus = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/statuspm`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getMPStatus = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/statusmp`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getResourceUsage = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/useofresources`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getMaintenanceCosts = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/biayapemeliharaan`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}