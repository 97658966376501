import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_BASEURL;

export const getUser = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/user`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getUserGroup = async (Suspend) => {
    const token = Cookies.get('accessToken');
    const isSuspend = Suspend ? `?isSuspend=${Suspend}` : ''
    const response = await axios.get(`${baseUrl}/get/userGroup${isSuspend}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getTran = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/tran`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getTranAppMap = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/tranAppMap`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getTranAppStatus = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/tranAppStatus`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getTranApprover = async () => {
    try {
        const token = Cookies.get('accessToken');
        const response = await axios.get(`${baseUrl}/get/tranapprover`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}