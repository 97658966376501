import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { getWorkOrderStatus } from '../API';

const WorkOrder = () => {
    const [dataSource, setDataSource] = useState({
        News: 0,
        Process: 0,
        Finish: 0,
        Pending: 0,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getWorkOrderStatus();

                // Pastikan res memiliki data dan formatnya valid
                if (res && res.length > 0) {
                    const payload = res.map((item) => ({
                        News: item.NEW || 0,
                        Process: item.PROCESS || 0,
                        Finish: item.FINISH || 0,
                        Pending: item.PENDING || 0,
                    }));
                    setDataSource(payload[0]);
                }
            } catch (error) {
                console.error("Error fetching work order status:", error);
            }
        };

        fetchData();
    }, []);

    // Data chart
    const data = [
        ["Type", "Value", { role: "style" }],
        ["New", dataSource.News, "#2389FF"],
        ["Process", dataSource.Process, "#0CCBCC"],
        ["Finish", dataSource.Finish, "#67C638"],
        ["Pending", dataSource.Pending, "#F33433"],
    ];

    // Opsi chart
    const options = {
        legend: "none", // Menyembunyikan legend
    };

    return (
        <Chart
            chartType="ColumnChart"
            width="100%"
            height="300px"
            data={data}
            options={options}
        />
    );
};

export default WorkOrder;
