import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  styled,
  Tab,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Link } from "react-router-dom";

import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PeopleIcon from '@mui/icons-material/People';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { useEffect, useState } from "react";

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import CountUp from 'react-countup';
import MapsDashboard from "./MapsDashboard";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { getComplineRate, getDashboardSummary, getMapDashboard, getWOReqSummary } from "./API";
import ComplineRate from "./Charts/ComplineRate";
import ResponseTime from "./Charts/ResponseTime";
import TechnicianPerformance from "./Charts/TechnicianPerformance";
import SukuCabang from "./Charts/SukuCabang";
import WorkOrderChart from "./Charts/WorkOrder";

const SubTotal = ({ value }) => {
  return (
    <CountUp start={0} end={value} duration={3} />
  )
}

const WidgetTotal = ({ Icon, Title, Value, LinkTo, LinkDesc }) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <>
      <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "12px", backgroundColor: "#ffffff", padding: "16px 20px", borderRadius: "6px" }}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-around", gap: "12px" }}>
            {Icon}
            <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <Typography variant="h5" fontWeight="600">{Title}</Typography>
              <Typography variant="h2" fontWeight="600">
                <SubTotal value={Value} />
              </Typography>
              <Typography variant="body1" fontWeight="600">
                <Link to={LinkTo} style={{ textDecoration: "none", color: `${colors.primary[400]}` }}>{LinkDesc}</Link> available
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  )
}


const Dashboard = () => {

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("md"));
  const colors = tokens(theme.palette.mode);

  const StyledLink = {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 0 0 15px",
    marginTop: "10px",
    color: colors.grey[900],
    '&:hover': {
      color: colors.primary[300]
    }
  }

  // console.log("Summary", StatusSummary);


  const [summary, setSummary] = useState([]);
  const [WOReqSum, setWOReqSum] = useState([]);
  // const [building, setBuilding] = useState([]);  

  // CHARTS
  const [complineRate, setComplineRate] = useState([]);

  const [maps, setMaps] = useState([]);

  const [value, setValue] = useState('0');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        const response = await getDashboardSummary();
        setSummary(response);
      } catch (error) {
        console.log(error);
      }
    }

    fetchSummary();
  }, []);


  useEffect(() => {
    const fetchWOReqSum = async () => {
      try {
        const response = await getWOReqSummary();
        setWOReqSum(response);
      } catch (error) {
        console.log(error);
      }
    }

    fetchWOReqSum();
  }, []);

  // useEffect(() => {
  //   const fetchBuilding = async () => {
  //     try {
  //       const response = await getBuilding();
  //       setBuilding(response);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }

  //   fetchBuilding();
  // }, []);

  useEffect(() => {
    const fetchMaps = async () => {
      try {
        const response = await getMapDashboard();

        const groupedMaps = response.reduce((acc, item) => {
          // Cek apakah `FacilityCode` sudah ada dalam hasil
          let facility = acc.find(f => f.FacilityCode === item.FacilityCode);
          if (!facility) {
            // Jika belum, tambahkan fasilitas baru
            facility = {
              FacilityCode: item.FacilityCode,
              FacilityName: item.FacilityName,
              Maps: []
            };
            acc.push(facility);
          }

          // Cek apakah `MapCode` sudah ada di dalam fasilitas tersebut
          let map = facility.Maps.find(m => m.MapCode === item.MapCode);
          if (!map) {
            // Jika belum, tambahkan peta baru
            map = {
              MapCode: item.MapCode,
              MapName: item.MapName,
              Image: item.Image,
              Icons: []
            };
            facility.Maps.push(map);
          }

          // Tambahkan ikon ke dalam peta
          map.Icons.push({
            IconId: item.IconId,
            RefCode: item.RefCode,
            RefName: item.RefName,
            IconLocationX: item.IconLocationX,
            IconLocationY: item.IconLocationY,
            RefUrl: item.RefUrl,
            IsStatus: item.IsStatus
          });

          return acc;
        }, []);

        // console.log("Grouped Maps", groupedMaps);
        setMaps(groupedMaps);

      } catch (error) {
        console.log(error);
      }
    };

    fetchMaps();
  }, []);
  

  useEffect(() => {
    const fetchComplineRate = async () => {
      try {
        const res = await getComplineRate();
        setComplineRate(res[0]);
      } catch (error) {
        console.log(error);
      }
    }
    fetchComplineRate();
  }, []);


  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));


  return (
    <Box m="20px">
      {/* HEADER */}

      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
      // m="10px 0"
      >
        <Header
          title="DASHBOARD"
          subtitle="Welcome to your dashboard"
        />
      </Box>

      {/* GRID & CHARTS */}
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 2, xl: 2 }}>

        <WidgetTotal
          Icon={<HomeWorkOutlinedIcon sx={{ width: "40px", height: "40px", color: `${colors.primary[500]}` }} />}
          Title="Total Facility"
          Value={summary.TotalFacility}
          LinkTo="/master/facility"
          LinkDesc="All the facilities"
        />

        <WidgetTotal
          Icon={<ApartmentIcon sx={{ width: "40px", height: "40px", color: `${colors.primary[500]}` }} />}
          Title="Total Building"
          Value={summary.TotalBuilding}
          LinkTo="/master/building"
          LinkDesc="All the buildings"
        />

        <WidgetTotal
          Icon={<PeopleIcon sx={{ width: "40px", height: "40px", color: `${colors.primary[500]}` }} />}
          Title="Total Employee"
          Value={summary.TotalEmployee}
          LinkTo="/master/employee"
          LinkDesc="All the employee"
        />

        <WidgetTotal
          Icon={<DirectionsCarIcon sx={{ width: "40px", height: "40px", color: `${colors.primary[500]}` }} />}
          Title="Total Vehicle"
          Value={summary.TotalVehicle}
          LinkTo="/master/vehicle"
          LinkDesc="All the vehicle"
        />

        {/* MAPS */}
        <Grid
          xs={12}
          sm={12}
          md={9}
          lg={9}
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {maps.length > 0 && (
            <Grid xs={12}>
              <Box
                sx={{
                  backgroundColor: "#ffffff",
                  // marginTop: "25px",
                  // padding: "20px",
                  borderRadius: "6px",
                }}>
                <Typography
                  variant="h3"
                  fontWeight="600"
                  sx={{
                    marginBottom: "15px",
                    paddingTop: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  Maps
                </Typography>

                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                      {maps.map((item, index) => (
                        <Tab label={item.FacilityName} value={index.toString()} key={index} />
                      ))}
                    </TabList>
                  </Box>
                  {maps.map((item, index) => (
                    <TabPanel value={index.toString()} key={index}>
                      <MapsDashboard setData={item} />
                    </TabPanel>
                  ))}
                </TabContext>
              </Box>
            </Grid>
          )}
        </Grid>


        {/* STATUS */}
        <Grid xs={12} sm={12} md={3} lg={3} xl={3}>
          <Box
            sx={{
              backgroundColor: "#ffffff",
              padding: "20px",
              borderRadius: "6px",
              // height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h4" fontWeight="600"
              style={{
                paddingBottom: "16px",
              }}>
              Facility
            </Typography>

            <Box
              sx={{
                backgroundColor: "#F7F7F7",
                borderRadius: "6px",
                overflow: "auto",
                maxHeight: "380px",
              }}
            >
              {WOReqSum.length > 0 ? WOReqSum.map((item, index) => (
                <Accordion key={index} defaultExpanded>
                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography variant="h5" fontWeight="600">{item.FacilityName}</Typography>
                  </AccordionSummary>
                  {item.BuildingSummaries.map((items, index) => (
                    <AccordionDetails key={index}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="h5" fontWeight="600">
                          {items.BuildingName}
                        </Typography>
                        <Typography variant="h5" fontWeight="600">
                          {items.TotalPM + items.TotalDM + items.TotalOverdue}
                        </Typography>
                      </Box>
                      <Link to={`summary/preventive/${items.FacilityCode}/${items.BuildingCode}`} style={{ textDecoration: "none" }}>
                        <Box sx={StyledLink}>
                          <Typography variant="h5">Preventive</Typography>
                          <Typography variant="h5">{items.TotalPM}</Typography>
                        </Box>
                      </Link>
                      <Link to={`summary/demand/${items.FacilityCode}/${items.BuildingCode}`} style={{ textDecoration: "none" }}>
                        <Box sx={StyledLink}>
                          <Typography variant="h5">Demand</Typography>
                          <Typography variant="h5">{items.TotalDM}</Typography>
                        </Box>
                      </Link>
                      <Link to={`summary/overdue/${items.FacilityCode}/${items.BuildingCode}`} style={{ textDecoration: "none" }}>
                        <Box sx={StyledLink}>
                          <Typography variant="h5">Overdue</Typography>
                          <Typography variant="h5">{items.TotalOverdue}</Typography>
                        </Box>
                      </Link>
                    </AccordionDetails>
                  ))}
                </Accordion>
              )) : (
                <Accordion defaultExpanded>
                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography variant="h5" fontWeight="600">Facility</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <Typography variant="h5" fontWeight="600">
                        Building
                      </Typography>
                      <Typography variant="h5" fontWeight="600">
                        0
                      </Typography>
                    </Box>
                    <Link to={`/`} style={{ textDecoration: "none" }}>
                      <Box sx={StyledLink}>
                        <Typography variant="h5">Preventive</Typography>
                        <Typography variant="h5">0</Typography>
                      </Box>
                    </Link>
                    <Link to={`/`} style={{ textDecoration: "none" }}>
                      <Box sx={StyledLink}>
                        <Typography variant="h5">Demand</Typography>
                        <Typography variant="h5">0</Typography>
                      </Box>
                    </Link>
                    <Link to={`/`} style={{ textDecoration: "none" }}>
                      <Box sx={StyledLink}>
                        <Typography variant="h5">Overdue</Typography>
                        <Typography variant="h5">0</Typography>
                      </Box>
                    </Link>
                  </AccordionDetails>
                </Accordion>
              )}
            </Box>
          </Box>
        </Grid>

        {/* CHARTS */}
        <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
          <Box sx={{ bgcolor: "#ffffff", padding: "20px", borderRadius: "6px" }}>
            <Typography
              variant="h3"
              fontWeight="600"
              sx={{
                marginBottom: "15px"
              }}>
              Compliance Rate
            </Typography>
            <ComplineRate />
          </Box>
        </Grid>

        <Grid xs={12} sm={12} md={8} lg={8} xl={8}>
          <Box sx={{ bgcolor: "#ffffff", padding: "20px", borderRadius: "6px" }}>
            <Typography
              variant="h4"
              fontWeight="600"
              sx={{
                marginBottom: "15px"
              }}>
              Technician Performance
            </Typography>
            <TechnicianPerformance onData={complineRate} />
          </Box>
        </Grid>

        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box sx={{ bgcolor: "#ffffff", padding: "20px", borderRadius: "6px" }}>
            <Typography
              variant="h3"
              fontWeight="600"
              sx={{
                marginBottom: "15px"
              }}>
              Response Time
            </Typography>
            <ResponseTime />
          </Box>
        </Grid>


        {/* {building.length > 0 && (
          <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box
              sx={{
                backgroundColor: "#ffffff",
                // marginTop: "25px",
                padding: "20px",
                borderRadius: "6px",
              }}>
              <Typography variant="h3" fontWeight="600">
                BUILDING
              </Typography>

              <Box
                sx={{
                  backgroundColor: "#F7F7F7",
                  borderRadius: "6px",
                  marginTop: "20px"
                }}
              >
                {building.map((building, index) => (
                  <Box sx={{ p: 2 }} key={index}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography gutterBottom variant="h5" fontWeight={600} component="div">
                        {building.Building.BuildingName}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="div">
                        <Link to="https://iot.sinergiteknologi.co.id" target="_blank" style={{ textDecoration: "none" }}>view</Link>
                      </Typography>
                    </Stack>
                    <Typography color="text.secondary" variant="body2">
                      {building.Building.Description}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
        )} */}

        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box sx={{ bgcolor: "#ffffff", padding: "20px", borderRadius: "6px" }}>
            <Typography
              variant="h3"
              fontWeight="600"
              sx={{
                marginBottom: "15px"
              }}>
              Inventory
            </Typography>
            <SukuCabang />
          </Box>
        </Grid>

        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box sx={{ bgcolor: "#ffffff", padding: "20px", borderRadius: "6px" }}>
            <Typography
              variant="h3"
              fontWeight="600"
              sx={{
                marginBottom: "15px"
              }}>
              Work Order Status
            </Typography>
            <WorkOrderChart />
          </Box>
        </Grid>




      </Grid>
    </Box >
  );
};

export default Dashboard;
