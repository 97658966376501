import React, { useEffect, useState, useCallback, useRef } from "react";
import { Box, InputAdornment, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { onlyNumber } from "../../global/Components";
import { ButtonForm } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { postBuilding } from "../../../Api/Master/PostData";
import { getBuilding, getFacility } from "../../../Api/Master/GetData";
import InputModal from "../../../components/Global/InputModal";
import { FacilityAuthority } from "../../../components/Global/Authority";

const FormBuildings = () => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramsFacCode = searchParams.get('faccode');

  useEffect(() => {
    if (paramsFacCode) {
      const fetchfacility = async () => {
        try {
          const res = await getFacility(paramsFacCode);
          setSelectFacility(res[0])
        } catch (error) {
          console.log(error);
        }
      }
      fetchfacility();
    }
  }, [paramsFacCode]);

  const myFacililtyAuth = FacilityAuthority();

  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [buildingCode, setBuildingCode] = useState("");
  
  const [isLoading, setIsLoading] = useState(true);
  
  const [dataFacility, setDataFacility] = useState([]);
  const [selectFacility, setSelectFacility] = useState("");
  const [openModal, setOpenModal] = useState("");
  const FacilityCode = selectFacility ? selectFacility.FacilityCode : '';
  const FacilityName = selectFacility ? selectFacility.FacilityName : '';
  const Address = selectFacility ? selectFacility.Address : '';
  const Phone1 = selectFacility ? selectFacility.Phone1.slice(1) : '';
  const Phone2 = selectFacility ? selectFacility.Phone2.slice(1) : '';
  const ContactPerson = selectFacility ? selectFacility.ContactPerson : '';


  const fetchfacility = async () => {
    try {
      const res = await getFacility(myFacililtyAuth, 'false');
      setDataFacility(res);
    } catch (error) {
      console.log(error);
    }
  }
  
  useEffect(() => {
    if (openModal) {
      fetchfacility();
      setIsLoading(false);
      setOpenModal(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  const FetchBuildingCode = useCallback(async () => {
    try {
      const response = await getBuilding(FacilityCode);
      if (response.length > 0) {
        const bcData = response.filter(
          (item) =>
            item.Building.BuildingCode &&
            item.Building.BuildingCode.startsWith("BD")
        );
        if (bcData.length > 0) {
          const lastCode = bcData[bcData.length - 1].Building.BuildingCode;
          const nextNumber = parseInt(lastCode.substr(2)) + 1;
          setBuildingCode(`BD${nextNumber.toString().padStart(4, "0")}`);
        }
      }
    } catch (error) {
      setBuildingCode("BD0001")
      console.log(error);
    }
  }, [FacilityCode]);

  useEffect(() => {
    if (FacilityCode) {
      FetchBuildingCode();
    }
  }, [FacilityCode, FetchBuildingCode]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("BuildingCode", buildingCode);
      formRef.current.setFieldValue("FacilityName", FacilityName);
      formRef.current.setFieldValue("Address", Address);
      formRef.current.setFieldValue("Phone1", Phone1);
      formRef.current.setFieldValue("Phone2", Phone2);
      formRef.current.setFieldValue("ContactPerson", ContactPerson);
    }
  }, [Address, ContactPerson, FacilityName, Phone1, Phone2, buildingCode]);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const payload = {
        ...values,
        FacilityCode: FacilityCode,
        BuildingCode: buildingCode,
        Phone1: "0" + values.Phone1,
        Phone2: "0" + values.Phone2,
      };

      const response = await postBuilding(payload);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/master/building");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response.data);
    }
    setLoading(false);
  };

  const handleKeyPress = (e) => {
    if (!onlyNumber.test(e.key) || (e.target.value === "" && e.key === "0")) {
      e.preventDefault();
    }
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <Box>
          <StyleContent>
            <Header title="BUILDING" subtitle="Create a Building" />
            <StyleForm>

              <InputModal
                title="FACILITY"
                label="Facility Name"
                name="FacilityName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.FacilityName}
                error={!!touched.FacilityName && !!errors.FacilityName}
                helperText={touched.FacilityName && errors.FacilityName}
                dataSource={dataFacility}
                columns={columns}
                loading={isLoading}
                propsSelecteRow={(values) => setSelectFacility(values)}
                propsOpenModal={(values) => setOpenModal(values)}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Building Code*"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  setBuildingCode(e.target.value);
                }}
                value={values.BuildingCode}
                name="BuildingCode"
                error={!!touched.BuildingCode && !!errors.BuildingCode}
                helperText={touched.BuildingCode && errors.BuildingCode}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 6 }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Building Name*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.BuildingName}
                name="BuildingName"
                error={!!touched.BuildingName && !!errors.BuildingName}
                helperText={touched.BuildingName && errors.BuildingName}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Address*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Address}
                name="Address"
                error={!!touched.Address && !!errors.Address}
                helperText={touched.Address && errors.Address}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Phone Number 1*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Phone1}
                name="Phone1"
                error={!!touched.Phone1 && !!errors.Phone1}
                helperText={touched.Phone1 && errors.Phone1}
                sx={{ gridColumn: "span 2" }}
                placeholder="Example: 81234567890"
                onKeyPress={handleKeyPress}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+62</InputAdornment>
                  ),
                }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Phone Number 2*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Phone2}
                name="Phone2"
                error={!!touched.Phone2 && !!errors.Phone2}
                helperText={touched.Phone2 && errors.Phone2}
                sx={{ gridColumn: "span 2" }}
                placeholder="Example: 81234567890"
                onKeyPress={handleKeyPress}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+62</InputAdornment>
                  ),
                }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Contact Person*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.ContactPerson}
                name="ContactPerson"
                error={!!touched.ContactPerson && !!errors.ContactPerson}
                helperText={touched.ContactPerson && errors.ContactPerson}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Description}
                name="Description"
                sx={{ gridColumn: "span 2" }}
                multiline
                rows={4}
              />
            </StyleForm>
          </StyleContent>

          <ButtonForm
            handleSubmit={handleSubmit}
            loading={loading}
          />
        </Box>
      )}
    </Formik>
  );
};

const checkoutSchema = yup.object().shape({
  FacilityName: yup.string().required("Please complete this Facility Name."),
  BuildingCode: yup
    .string()
    .max(10, "Maximum of 10 characters allowed")
    .required("Please complete this Building Code."),
  BuildingName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Building Name."),
  Address: yup
    .string()
    .max(200, "Maximum of 200 characters allowed")
    .required("Please complete this Address."),
  Phone1: yup
    .string()
    .max(15, "Maximum of 15 characters allowed")
    .required("Please complete this Phone Number 1."),
  Phone2: yup
    .string()
    .max(15, "Maximum of 15 characters allowed")
    .required("Please complete this Phone Number 2.")
    .test(
      "phone-not-same",
      "Phone Number 1 and Phone Number 2 should not be the same",
      function (value) {
        return value !== this.parent.Phone1;
      }
    ),
  ContactPerson: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Contact Person."),
});
const initialValues = {
  FacilityName: "",
  BuildingCode: "",
  BuildingName: "",
  Address: "",
  Phone1: "",
  Phone2: "",
  ContactPerson: "",
  Description: "",
};

export default FormBuildings;


const columns = [
  {
    key: 'key',
    title: 'No',
    dataIndex: 'key',
    width: 80,
    fixed: 'left',
    sorter: (a, b) => a.key - b.key,
  },
  {
    key: 'FacilityCode',
    title: 'Facility Code',
    dataIndex: 'FacilityCode',
    width: 150,
    fixed: 'left',
    sorter: (a, b) => a.FacilityCode.localeCompare(b.FacilityCode),
  },
  {
    key: 'FacilityName',
    title: 'Facility Name',
    dataIndex: 'FacilityName',
    sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
  },
  {
    key: 'Address',
    title: 'Address',
    dataIndex: 'Address',
    width: 400,
    sorter: (a, b) => a.Address.localeCompare(b.Address),
  },
  {
    key: 'City',
    title: 'City',
    dataIndex: 'City',
    sorter: (a, b) => a.City.localeCompare(b.City),
  },
  {
    key: 'Country',
    title: 'Country',
    dataIndex: 'Country',
    sorter: (a, b) => a.Country.localeCompare(b.Country),
  },
  {
    key: 'Phone1',
    title: 'Phone Number 1',
    dataIndex: 'Phone1',
    sorter: (a, b) => a.Phone1.localeCompare(b.Phone1),
  },
  {
    key: 'Phone2',
    title: 'Phone Number 2',
    dataIndex: 'Phone2',
    sorter: (a, b) => a.Phone2.localeCompare(b.Phone2),
  },
  {
    key: 'Fax',
    title: 'Fax',
    dataIndex: 'Fax',
    sorter: (a, b) => a.Fax.localeCompare(b.Fax),
  },
  {
    key: 'ContactPerson',
    title: 'Contact Person',
    dataIndex: 'ContactPerson',
    sorter: (a, b) => a.ContactPerson.localeCompare(b.ContactPerson),
  },
];