import React, { useState, useRef, useEffect } from "react";
import * as yup from "yup";
import {
  Box,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import { ButtonUpdate } from "../../global/components/ButtonAction";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { Image, Modal } from "antd";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import SwitchComponent from "../../../components/Global/SwitchSuspend";
import { updateFloorLevel } from "../../../Api/Master/UpdateData";
import InputModal from "../../../components/Global/InputModal";
import { getMap } from "../../../Api/Master/GetData";

const EditFloor = ({ data, onEdit }) => {
  const dataEdit = data.FloorLevel;

  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [isSuspend, setIsSuspend] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [dataMap, setDataMap] = useState([]);
  const [selectMap, setSelectMap] = useState("");
  // const [openModalMap, setOpenModalMap] = useState("");
  const MapName = selectMap ? selectMap.Map.MapName : '';
  const MapCode = selectMap ? selectMap.Map.MapCode : '';

  // DATA MAP
  useEffect(() => {
    const fetchMap = async () => {
      try {
        setIsLoading(true);
        const res = await getMap(dataEdit.FacilityCode);
        const filterRes = res.filter((item) => item.Map.IsSuspend !== true);
        setDataMap(filterRes);

        const selectData = res.filter((item) => item.Map.MapCode === dataEdit.MapCode);
        setSelectMap(selectData[0]);

      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    if (open) {
      fetchMap();
    }

  }, [dataEdit.FacilityCode, dataEdit.MapCode, open]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("MapName", MapName);
    }
  }, [MapName]);


  const handleSwitchChange = (checked) => {
    setIsSuspend(checked);
    formRef.current.resetForm();
  };

  const handleOpen = () => {
    setOpen(true);
    setIsSuspend(data.IsSuspend);
  };

  const handleClose = () => {
    formRef.current.resetForm();
    setOpen(false);
    setIsSuspend(dataEdit.isSuspend)
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const modifiedValues = {
        ...values,
        MapCode: MapCode,
        IsSuspend: isSuspend,
      };
      const response = await updateFloorLevel(
        dataEdit.FacilityCode,
        dataEdit.FloorLevelCode,
        modifiedValues
      );
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      onEdit(dataEdit.FacilityCode && dataEdit.FloorLevelCode);
      setOpen(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to update.", { variant: "error" });
      }
      console.error("error code", error.response);
      formRef.current.resetForm();
      setOpen(false);
    }
    setLoading(false);
  };

  return (
    <Box>
      <Tooltip title="Edit" fontSize="large">
        <IconButton onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Box>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={dataEdit}
          validationSchema={checkoutSchema}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Modal
              title={
                <StyleEditHeader>
                  <Header
                    title="FLOOR LEVEL"
                    subtitle="Edit Data a Floor Level"
                  />
                  <SwitchComponent
                    isSuspend={isSuspend}
                    handleSwitchChange={handleSwitchChange}
                  />
                </StyleEditHeader>
              }
              centered
              open={open}
              closable={false}
              style={{
                maxWidth: "90%",
                minWidth: "80%",
              }}
              bodyStyle={{
                maxHeight: "65vh",
                overflow: "auto",
              }}
              footer={() => (
                <ButtonUpdate
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  loading={loading}
                />
              )}
            >
              <StyleForm>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Facility Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={data.FacilityName}
                  name="FacilityCode"
                  error={!!touched.FacilityName && !!errors.FacilityName}
                  helperText={touched.FacilityName && errors.FacilityName}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Building Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={data.BuildingName}
                  name="BuildingCode"
                  error={!!touched.BuildingName && !!errors.BuildingName}
                  helperText={touched.BuildingName && errors.BuildingName}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  readOnly
                  label="Floor Level Code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.FloorLevelCode}
                  name="FloorLevelCode"
                  error={!!touched.FloorLevelCode && !!errors.FloorLevelCode}
                  helperText={touched.FloorLevelCode && errors.FloorLevelCode}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <InputModal
                  title="MAP"
                  label="Map Name"
                  name="MapName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.MapName}
                  error={!!touched.MapName && !!errors.MapName}
                  helperText={touched.MapName && errors.MapName}
                  dataSource={dataMap}
                  columns={columnsMap}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectMap(values)}
                  // propsOpenModal={(values) => setOpenModalMap(values)}
                  LinktoCreate={`/master/map/form?faccode=${dataEdit.FacilityCode}`}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Floor Level Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.FloorLevelName}
                  name="FloorLevelName"
                  error={!!touched.FloorLevelName && !!errors.FloorLevelName}
                  helperText={touched.FloorLevelName && errors.FloorLevelName}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 2" }}
                  multiline
                  rows={4}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />
              </StyleForm>
            </Modal>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  FloorLevelCode: yup
    .string()
    .required("Please complete this  Floor Level Code."),
  FloorLevelName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this  Floor Level Name."),
});

export default EditFloor;


const columnsMap = [
  {
    key: 'key',
    title: 'No',
    dataIndex: 'key',
    width: 80,
    fixed: 'left',
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: 'Image',
    dataIndex: ['Map', 'Image'],
    render: (params) =>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Image
          style={{ maxWidth: 100, maxHeight: "100%", objectFit: "contain" }}
          src={`${process.env.REACT_APP_BASEURL_IMG}/${params}`}
        />
      </div>
  },
  {
    title: 'Map Code',
    dataIndex: ['Map', 'MapCode'],
    sorter: (a, b) => a.Map.MapCode.localeCompare(b.Map.MapCode),
  },
  {
    title: 'Map Name',
    dataIndex: ['Map', 'MapName'],
    sorter: (a, b) => a.Map.MapName.localeCompare(b.Map.MapName),
  },
  {
    title: 'Facility Name',
    dataIndex: 'FacilityName',
    sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
  },
];